import React, { Component } from 'react';
import { Button, message, Pagination, Table } from "antd";
import { Image as Images } from "../Images";
import { ProjectDetailHead } from './ProjectDetailHead';
import { getProposals } from "../../Controller/API/ProjectAPI";
import moment from "moment";

const pagination = Pagination;
pagination.pageSize = 10;

class ProjectPropsal extends Component {
    state = {
        proposals: [],
        pagination: pagination,
        loading: true,
        count: 0
    };
    columns = [
        {
            title: <div>Name (22)</div>,
            dataIndex: 'name',
            render: (name) => <div className="d-flex align-items-center">
                <span className="icon-img">
                    <img alt="folder web icon" src={Images.proposal_invoice_icon} />
                </span>
                <div className="text-div-inn">
                    {name}
                </div>
            </div>
        },
        {
            title: <div className="d-flex align-items-center align-items-center">Date Created <img
                src={Images.arrow_down} alt="arrow down" className="img-fluid ml-1" /></div>,
            dataIndex: 'created',
            render: (data) => moment(data).format("ll")
        },
    ];


    componentDidMount() {
        this.fetch()
    }

    fetch = (params = {}) => {
        getProposals({ project: this.props.serviceDetail.id, ...params }).then(response => {
            let { pagination } = this.state;
            pagination.total = response.data.count;
            this.props.count(response.data.count)
            this.setState({ count: response.data.count, proposals: response.data.results, pagination, loading: false })
        }).catch(err => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    };
    handleChange = (pagination, filters, sorter) => {
        let { params } = this.state;
        let symbol = sorter.order === 'descend' ? '-' : '';
        params = { ...params, page: pagination.current, ordering: `${symbol}${sorter.columnKey}` };
        this.setState({ params });
        this.fetch(params)
    };

    render() {
        const { proposals, loading, pagination, count } = this.state;
        const { serviceDetail } = this.props;
        return (
            <React.Fragment>
                <div className="col-12">
                    <div className="row em-service-table-card bg-transparent shadow-none em-table-invoices-card">
                        <div className="col-12 p-0">
                            <div className="row billing-info-row-heading justify-content-between">
                                <ProjectDetailHead serviceDetail={serviceDetail} content={count > 0 && `${count} Proposals`} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row em-service-table-card em-table-invoices-card">
                                <div className="col-12 p-0">
                                    <Table
                                        className="table-responsive em-service-table"
                                        columns={this.columns}
                                        dataSource={proposals}
                                        onChange={this.handleChange}
                                        pagination={{ ...pagination }}
                                        loading={loading}
                                        size="middle"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ProjectPropsal;