import { getEnvValue } from "./Environment";
import React from "react";
import { reverse } from "named-urls";
import { include } from "named-urls/src";

export const Config = {
    domain: getEnvValue('API_URL')
};

export const endpoint = {
    user: include('user', {
        login: 'login',
        refresh: 'refresh',
        register: 'register',
        source: 'source',
        industry: 'industry',
        profile: 'profile',
        password: 'change-password',
        forgetPassword: "forgot-password",
        verifyCode: 'verify-code',
        resetPassword: 'reset-password'
    }),
    project: include('project', {
        subscriber: 'subscriber/',
        info: include('project-info/', {
            self: '',
            id: ':id?/'
        }),
        budget: include('budget/', {
            self: '',
            id: ':id?/'
        }),
        service: 'service/',
        proposal: 'proposal/',
        document: include('upload-document/', {
            self: '',
            project: ':project?/',
            delete: ':project?/:id?/'
        }),
        uploadDocument: 'upload-document-file/',
        invoice: 'invoice/',
        deleteDocument: include('delete-document', {
            self: '',
            id: ':id?/'
        })
    }),
    service: include('service', {
        project: include('project/', {
            self: '',
            id: ':id?/'
        }),
        websiteDesign: include('website-design/', {
            self: '',
            id: ':id?/'
        }),
        searchEngineOpt: include('search-engine-optimization/', {
            self: "",
            id: ':id?/'
        }),
        contentGeneration: include('content-generation/', {
            self: "",
            id: ':id?/'
        }),
        searchEngineMarketing: include('search-engine-marketing/', {
            self: "",
            id: ':id?/'
        }),
        emailMarketing: include('email-marketing/', {
            self: "",
            id: ':id?/'
        }),
        retargeting: include('retargeting/', {
            self: "",
            id: ':id?/'
        }),
        integratedMarketing: include('integrated-marketing/', {
            self: "",
            id: ':id?/'
        }),
        socialMediaMarketing: include('social-media-marketing/', {
            self: "",
            id: ':id?/'
        })
    })
};

export function getAPIUrl(url, params = null) {
    const path = reverse(url.split('.').reduce((o, i) => o[i], endpoint), params);
    return getEnvValue('API_URL') + path;
}

export const step = {
    ACCOUNT_INFO: 'accountInfo',
    PROJECT_INFO: 'projectInfo',
    YOUR_SERVICE: 'yourServices',
    TIME_BUDGET: 'timeBudget'
};
export const status = {
    PENDING: 'Pending',
    PAID: 'Paid',
    PARTIAL: 'Partial',
    UNPAID: 'Unpaid',
    INPROGRESS: "Progress",
    COMPLETED: 'Completed',
    CANCELLED: 'CAncelled'
}
export const services = {
    WEBSITE_DESIGN_DEVELOPMENT: 'WebsiteDesign',
    SEARCH_ENGINE_OPTIMIZATION: 'SearchEngineOptimization',
    SEARCH_ENGINE_MARKETING: 'SearchEngineMarketing',
    SOCIAL_MEDIA_MARKETING: 'SocialMediaMarketing',
    EMAIL_MARKETING: 'EmailMarketing',
    RETARGETING: 'Retargeting',
    CONTENT_GENERATION: 'ContentGeneration',
    INTEGRATING_ONLINE_MARKETING_STRATEGIES: 'IntegratedMarketing'
}
export const myServices = {
    EmailMarketing: "email_marketing",
    WebsiteDesign: "website_design",
    SearchEngineOptimization: "search_engine_optimization",
    SearchEngineMarketing: "search_engine_marketing",
    SocialMediaMarketing: "social_media_marketing",
    Retargeting: "retargeting",
    ContentGeneration: "content_generation",
    IntegratedMarketing: "integrated_marketing"
}