import React, { Component } from 'react';
import { Button, Collapse, Form, message } from 'antd';
import { Image as Images } from '../Images'

import WebsiteDesign from './Services/WebsiteDesign';
import SearchEngineOptimization from './Services/SearchEngineOptimize';
import EmailMarketing from './Services/EmailMarketing';
import ContentGeneration from './Services/ContentGeneration';
import IntegratingMarketing from './Services/IntegratingMarketingStrategies';
import Retargeting from './Services/Retargeting';
import SocialMediaMarketing from './Services/SocialMediaMarketing';
import SearchEngineMarketing from './Services/SearchEngineMarketing';
import { step, services, myServices } from '../../Controller/Global';
import { removeWebsiteDesign, removeSearchEngineOpt, removeEmailMarketing, removeContentGeneration, removeIntegratedMarketing, removeRetargeting, removeSocialMediaMarketing, removeSearchEngineMarketing } from '../../Controller/API/ServiceAPI';
import { isEmpty } from 'lodash';

const { Panel } = Collapse;


export default class YourServices extends Component {
    state = {
        pendingService: false
    }
    onFinish = values => {
        this.props.handleProcess(step.TIME_BUDGET)
    };
    removeService = (e) => {
        e.stopPropagation()
        let data = "", id = e.target.dataset.id
        switch (e.target.id) {
            case 'WebsiteDesign': removeWebsiteDesign(id, data).then((response) => {
                message.success("Service Deleted Successfully")
                this.props.projectDetails()
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'ContentGeneration': removeContentGeneration(id, data).then((response) => {
                this.props.projectDetails()
                message.success("Service Deleted Successfully")
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'SearchEngineOptimization': removeSearchEngineOpt(id, data).then((response) => {
                this.props.projectDetails()
                message.success("Service Deleted Successfully")
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'EmailMarketing': removeEmailMarketing(id, data).then((response) => {
                this.props.projectDetails()
                message.success("Service Deleted Successfully")
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;

            case 'IntegratedMarketing': removeIntegratedMarketing(id, data).then((response) => {
                this.props.projectDetails()
                message.success("Service Deleted Successfully")
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'Retargeting': removeRetargeting(id, data).then((response) => {
                this.props.projectDetails()
                message.success("Service Deleted Successfully")
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'SocialMediaMarketing': removeSocialMediaMarketing(id, data).then((response) => {
                this.props.projectDetails()
                message.success("Service Deleted Successfully")
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'SearchEngineMarketing': removeSearchEngineMarketing(id, data).then((response) => {
                this.props.projectDetails()
                message.success("Service Deleted Successfully")
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            default: return null
        }
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        console.log(nextProps, prevState)
        if (nextProps.serviceDetail.services.some(_ => isEmpty(nextProps.serviceDetail[myServices[_.model]]))) {
            return {
                pendingService: true
            }
        } else {
            return {
                pendingService: false
            }
        }
        return null
    }
    onSubmit = () => {
        var services = this.props.serviceDetail.services
        console.log(services, this.props.serviceDetail)
        // services.every
        this.props.projectDetails()
        this.closeAllCollapse()
    }
    closeAllCollapse = () => {
        var x = document.querySelectorAll(".ant-collapse-item-active");
        if (x.length) {
            for (var i = 0; i < x.length; i++) {
                let element = document.getElementById("all_services");
                element.scrollIntoView();
                setTimeout(function () {
                    var el = document.querySelector(".ant-collapse-item-active");
                    let allPanel = document.querySelectorAll(".ant-collapse-item")
                    let index = Array.prototype.indexOf.call(allPanel, el)
                    if (el.children) el.children[0].click();
                    if (allPanel[index + 1]) allPanel[index + 1].children[0].click()
                }, 100);
            }
        }
    }
    handleServiceClick = (index) => {
        let allPanel = document.querySelectorAll(".ant-collapse-item")
        if (allPanel[index]) allPanel[index].children[0].click()

    }
    renderService(id) {
        switch (id) {
            case "WebsiteDesign": return <WebsiteDesign projectId={this.props.projectId} serviceDetail={this.props.serviceDetail.website_design} onSubmit={this.onSubmit} />
                break;
            case "EmailMarketing": return <EmailMarketing projectId={this.props.projectId} serviceDetail={this.props.serviceDetail.email_marketing} onSubmit={this.onSubmit} />
                break;
            case "SearchEngineOptimization": return <SearchEngineOptimization projectId={this.props.projectId} serviceDetail={this.props.serviceDetail.search_engine_optimization} onSubmit={this.onSubmit} />
                break;
            case "SocialMediaMarketing": return <SocialMediaMarketing projectId={this.props.projectId} serviceDetail={this.props.serviceDetail.social_media_marketing} onSubmit={this.onSubmit} />
                break;
            case "SearchEngineMarketing": return <SearchEngineMarketing projectId={this.props.projectId} serviceDetail={this.props.serviceDetail.search_engine_marketing} onSubmit={this.onSubmit} />
                break;
            case "Retargeting": return <Retargeting projectId={this.props.projectId} serviceDetail={this.props.serviceDetail.retargeting} onSubmit={this.onSubmit} />
                break;
            case "ContentGeneration": return <ContentGeneration projectId={this.props.projectId} serviceDetail={this.props.serviceDetail.content_generation} onSubmit={this.onSubmit} />
                break;
            case "IntegratedMarketing": return <IntegratingMarketing projectId={this.props.projectId} serviceDetail={this.props.serviceDetail.integrated_marketing} onSubmit={this.onSubmit} />
                break;
            default: return null
        }
    }

    render() {
        const { projectId, serviceDetail } = this.props
        console.log(serviceDetail, this.state.pendingService)
        return (
            <div className="project-info-container" id="all_services">
                <h2 className="aic-heading">Your Services</h2>
                <Form className="mt-4" onFinish={this.onFinish}>
                    <Collapse accordion defaultActiveKey={[serviceDetail.services.length && serviceDetail.services[0].id]} className="form-collapse border-0 bg-transparent">
                        {!isEmpty(serviceDetail) && serviceDetail.services.map(_ => <Panel className={!isEmpty(serviceDetail[myServices[_.model]]) ? "service-saved mt-4" : "mt-4"} header={_.title} key={_.id}
                            extra={!isEmpty(serviceDetail[myServices[_.model]]) && <React.Fragment><img className="success" src={Images.success_icon} /><label className="m-0 cursor-pointer" data-id={serviceDetail[myServices[_.model]] && serviceDetail[myServices[_.model]].id} id={_.model} onClick={(e) => this.removeService(e)}>Remove<img alt="" src={Images.remove_icon_main}
                                className="ml-2 img-fluid" /></label></React.Fragment>}>
                            {this.renderService(_.model)}
                        </Panel>)}
                    </Collapse>
                    <div className="form-actions">
                        {/* <Button type="primary" size="large" onClick={() => this.props.handleProcess(step.PROJECT_INFO)} className="d-inline mr-4 mt-4 back-button">BACK</Button> */}
                        {serviceDetail.services.length && !this.state.pendingService ? <Button type="primary" htmlType="submit" size="large" className="d-inline mt-4 continue-button">CONTINUE</Button> :
                            <Button type="primary" disabled size="large" className="d-inline mt-4 continue-button">CONTINUE</Button>}
                    </div>
                </Form>
            </div>
        )
    }
}