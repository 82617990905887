import { TOGGLE_LOGIN, USER_LOGIN, USER_UPDATE } from "../Actions/actionType";

const initialState = {
    loginVisible: false,
    user: null
};
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_LOGIN: {
            return { ...state, loginVisible: action.data }
        }
        case USER_LOGIN: {
            return { ...state, user: action.data }
        }
        case USER_UPDATE: {
            return { ...state, user: action.data }
        }
        default:
            return state
    }
}