import React, { Component } from 'react';
import { Input, Button, Drawer, Form, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './style.scss';
import { connect } from "react-redux";
import { toggleLoginAction, userLoginAction } from "../../redux/Actions/userActions";
import { userLogin } from "../../Controller/API/UserAPI";
import { setRefreshToken, setUserToken } from "../../Controller/AuthService";
import { history } from "../../Controller/History";
import routes from "../../Controller/Routes";
import { withRouter } from "react-router-dom";
import ForgetPassword from '../ForgetPassword';

class Login extends Component {
    state = {
        forgetPasswordShow: false,
        resetPassword: false,
        loading: false
    }
    handleLogin = async (values) => {
        try {
            this.setState({ loading: true })
            await this.props.userLoginAction(values);
            message.success('Login Successfully');
            this.setState({ loading: false })
            this.props.toggleLoginAction(false);
            this.props.history.push(routes.dashboard.self)
        } catch (e) {
            message.error('Invalid Credentials')
            this.setState({ resetPassword: true, loading: false })
        }
    };
    handleForgetPassword = () => {
        this.setState({ forgetPasswordShow: !this.state.forgetPasswordShow, resetPassword: false })
    }
    render() {
        return (
            <div className="login-container" style={{ height: "100%" }}>
                <Drawer
                    className="em-main-drawer lc-modal"
                    placement="right"
                    closable={false}
                    onClose={() => this.props.toggleLoginAction(false)}
                    visible={this.props.user.loginVisible}
                    getContainer={false}
                    width={"37%"}
                    title={<Button onClick={() => this.props.toggleLoginAction(false)}
                        className="close-btn-drawer bg-transparent border-0 shadow-none p-0 d-flex align-items-center"><CloseOutlined style={{ color: "#000000" }} /></Button>}
                    style={{ position: 'absolute' }}>
                    <h2>Login</h2>
                    <Form onFinish={this.handleLogin}>
                        <label className="mt-3 mb-1">Email</label>
                        <Form.Item rules={[{ required: true, message: 'This field is required' }, {
                            type: 'email',
                            message: 'Please input valid email'
                        }]} name={'email'}>
                            <Input placeholder="Example@email.com" />
                        </Form.Item>
                        <label className="mt-2 mb-1">Password</label>
                        <Form.Item rules={[{ required: true, message: 'This field is required' }]} name={'password'}>
                            <Input.Password placeholder="••••••••••" />
                        </Form.Item>
                        <span className="text-right forget-link mt-1 cursor-pointer float-right" onClick={this.handleForgetPassword}>{this.state.resetPassword ? "Reset Password" : "Forgot Password"}</span>
                        <Form.Item>
                            <Button htmlType="submit" loading={this.state.loading} type="primary" className="mt-4 login-button">LOG IN</Button>
                        </Form.Item>
                    </Form>
                </Drawer>
                <ForgetPassword visible={this.state.forgetPasswordShow} onClose={this.handleForgetPassword} />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { user: state.user }
};
export default connect(mapStateToProps, { toggleLoginAction, userLoginAction })(withRouter(Login))