import React, { Component } from 'react';
import { Input, Form, Button, message, Select } from 'antd';
import { postSearchEngineOpt, updateSearchEngineOpt } from '../../../Controller/API/ServiceAPI';

const { TextArea } = Input;
const { Option } = Select;
export default class SearchEngineOptimization extends Component {
    state = {
        userDetails: {},
        buttonLoading: false
    };
    formRef = React.createRef();

    componentDidMount() {
        const { serviceDetail } = this.props;
        if (serviceDetail) {
            this.formRef.current.setFieldsValue({
                name: serviceDetail.name,
                description: serviceDetail.description,
                web_url: serviceDetail.web_url,
                additional_web_url: serviceDetail.additional_web_url,
                seo_work_type: serviceDetail.seo_work_type,
                google_page_verified: serviceDetail.google_page_verified,
                visible_location: serviceDetail.visible_location,
                top_keywords: serviceDetail.top_keywords,
                keyword_exists: serviceDetail.keyword_exists,
                review: serviceDetail.review,
                content_page: serviceDetail.content_page,
                business_listings: serviceDetail.business_listings,
                pointing_links: serviceDetail.pointing_links,
                advertises: serviceDetail.advertise,
                experience: serviceDetail.experience,
            })
        }
    }

    onFinish = values => {
        this.setState({ buttonLoading: true });
        let userDetails = { ...this.state.userDetails };
        values.project = this.props.projectId;
        values.advertise = values.advertises;
        delete values.advertises;
        if (userDetails.id || this.props.serviceDetail) {
            let id = userDetails.id ? userDetails.id : this.props.serviceDetail.id;
            updateSearchEngineOpt(id, values).then((response) => {
                this.props.onSubmit();
                if (!this.props.serviceType) message.success("Details Updated Successfully");
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    });
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong");
                    this.setState({ buttonLoading: false })
                }
            })
        } else {
            postSearchEngineOpt(values).then((response) => {
                this.props.onSubmit();
                if (!this.props.serviceType) message.success("Form Submitted");
                this.setState({ userDetails: response.data });
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    });
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong");
                    this.setState({ buttonLoading: false })
                }
            })
        }
    };

    render() {
        return (
            <React.Fragment>
                <Form onFinish={this.onFinish} ref={this.formRef}>
                    <div className="f-row">
                        <h6>Name your SEO project (optional)</h6>
                        <Form.Item name="name">
                            <Input className="fr-input" placeholder="Eg. Landing page for my food truck’s website" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Describe your SEO Project *</h6>
                        <Form.Item name="description" rules={[{ required: true, message: 'This field is required' }]}>
                            <TextArea rows={7} className="fr-input fr-inputTextArea"
                                placeholder="E.g. I want to rank higher for searches in my local area." />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What is your website URL?</h6>
                        <Form.Item name="web_url" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>List additional website URLs if you have more than
                            one. <span>(separate websites with comma)</span></h6>
                        <Form.Item name="additional_web_url"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input"
                                placeholder="Eg. www.yourdomainname.com, www.yourwebsitedomain2.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What type of SEO work have you done with your current website? Please be as specific as
                            possible.</h6>
                        <Form.Item name="seo_work_type" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg." />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Do you have a Google My Business page verified?</h6>
                        <Form.Item name="google_page_verified"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            {/* <Input className="fr-input" placeholder="Yes or No?" /> */}
                            <Select placeholder="Select" style={{ width: "100%" }}>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Which city location would you like to be visible the most in?</h6>
                        <Form.Item name="visible_location"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Yes or No?" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What are the Top 10 keywords you would like your business to rank for? These are words you
                        customers would use to find your business
                            online. <span>(separate keywords with comma)</span></h6>
                        <Form.Item name="top_keywords" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Pets supplies, Cats and Dogs, Pets treads" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Do you have any keywords that you rank well in Google or Bing?</h6>
                        <Form.Item name="keyword_exists" rules={[{ required: true, message: 'This field is required' }]}>
                            <Select placeholder="Select" style={{ width: "100%" }}>
                                <Option value={true}>Yes</Option>
                                <Option value={false}>No</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Do you have any reviews online (Yelp, YellowPages, Facebook, Etc.)? If so, please list them
                            here.</h6>
                        <Form.Item name="review" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Yes or No?" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Are you willing to add new content pages to your website that specifically describe your
                            services? If so, what type of pages come to mind?</h6>
                        <Form.Item name="content_page" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Yes or No?" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Do you have any online business listings (Yelp, YellowPages, BBB, Etc)</h6>
                        <Form.Item name="business_listings"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="E.g. I like a minimalistic but retro feel." />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Do you have any links pointing to your website? If so, which websites have a link to your
                            site?</h6>
                        <Form.Item name="pointing_links" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="E.g. I like a minimalistic but retro feel." />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How do you currently advertise online to your clients?</h6>
                        <Form.Item name="advertises" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="E.g. I like a minimalistic but retro feel." />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Have you worked with an SEO company before? What was your experience.</h6>
                        <Form.Item name="experience" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="E.g. I like a minimalistic but retro feel." />
                        </Form.Item>
                    </div>
                    <div className="row em-form-inner-btn em-btn-all position-sticky edit-footer">
                        {this.props.clearForm && <Button className="bg-transparent border-0 shadow-none w-auto" onClick={() => this.formRef.current.resetFields()}>Clear</Button>}
                        {this.props.serviceType == "inner" &&
                            <Button className="mt-4" onClick={this.props.onClose}>Cancel</Button>}
                        <Button loading={this.state.buttonLoading} type="primary" htmlType="submit" size="large"
                            className="d-inline mt-4 continue-button">SUBMIT</Button>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}