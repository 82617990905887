import React from 'react';
import { Button } from 'antd';
export const ProjectDetailHead = (props) => {
    return (
        <div className="d-flex align-items-center top-details">
            <h5 className="heading-info-div mb-0 t-capitalize">{props.serviceDetail.name}</h5>
            <Button className="text-uppercase progress-btn status-btn" >{props.serviceDetail.status}</Button>
            {props.content && <span className="service-text-count ml-3">{props.content}</span>}
        </div>
    )
}
