import React from 'react';
import './assets/scss/responsive.scss';
import './Helper.scss';
import './assets/scss/custom.scss';
import ProjectCreation from './Components/ProjectCreation';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import combineReducer from './redux/reducer';
import Home from "./Components/HomePage/Index";
import Proposals from "./Components/Proposals/Index";
import Invoices from "./Components/Invoices/Index";
import ViewInvoice from "./Components/Invoices/ViewInvoice";
import PayInvoice from "./Components/Invoices/PayInvoice";
import ViewAccount from "./Components/ViewAccount/Index";
import ProjectAll from "./Components/ProjectsMain/Index";
import ProjectDetailsInnerAll from "./Components/ProjectsMain/ProjectDetailsInnerAll";
import routes from './Controller/Routes.jsx';
import { history } from './Controller/History';
import CustomHeader from './Components/Layout/CustomHeader';
import { PublicRoute } from "./Controller/PublicPath";
import PrivateRoute from "./Controller/PrivatePath";
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import Logout from "./Controller/Logout";


function App() {
    const persistConfig = {
        key: 'root',
        storage,
        whitelist: ['user']
    };
    const persistedReducer = persistReducer(persistConfig, combineReducer);
    const store = createStore(persistedReducer, applyMiddleware(thunk));
    const persistor = persistStore(store);
    return (
        <div className="App">
            <Provider store={store} history={history}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <CustomHeader />
                        <Switch>
                            <React.Fragment>
                                <PublicRoute exact path={routes.login} component={ProjectCreation} />
                                <PrivateRoute exact path={routes.dashboard.self} component={Home} />
                                <PrivateRoute exact path={routes.dashboard.projects.self} component={ProjectAll} />
                                <PrivateRoute exact path={routes.dashboard.projects.details}
                                    component={ProjectDetailsInnerAll} />
                                <PrivateRoute exact path={routes.dashboard.proposals} component={Proposals} />
                                <PrivateRoute exact path={routes.dashboard.invoices} component={Invoices} />
                                <PrivateRoute exact path={routes.dashboard.viewInvoice} component={ViewInvoice} />
                                <PrivateRoute exact path={routes.dashboard.payInvoice} component={PayInvoice} />
                                <PrivateRoute exact path={routes.dashboard.viewAccount} component={ViewAccount} />
                                <PrivateRoute exact path={routes.dashboard.projectCreation} component={ProjectCreation} />
                                <PrivateRoute exact path={routes.logout} component={Logout} />
                            </React.Fragment>
                        </Switch>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </div>
    );
}

export default App;
