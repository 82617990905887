import React, { Component } from 'react';
import { Input, Upload, Form, Button, message, Tooltip } from 'antd';
import { uploadFiles, removeDocument } from '../../../Controller/API/ProjectAPI';
import { postServiceWebsite, updateServiceWebsiteDesign } from '../../../Controller/API/ServiceAPI';
import { Image as Images } from "../../Images";
import moment from 'moment';
import { bytesToSize } from '../../../Controller/helper';

const { Dragger } = Upload;
const { TextArea } = Input;

export default class WebsiteDesign extends Component {
    state = {
        reference_file: [],
        inspirational_file: [],
        userDetails: {},
        buttonLoading: false,
        refStatus: false,
        insStatus: false,
        insFileErr: false,
        resFileErr: false
    };
    formRef = React.createRef();

    componentWillMount() {
        const { serviceDetail } = this.props;
        if (serviceDetail) {
            var ref = serviceDetail.reference_file.map((_) => ({ id: _.id, name: _.file_name, url: _.file, size: _.file_size, created: _.created }))
            var ins = serviceDetail.inspirational_file.map((_) => ({ id: _.id, name: _.file_name, url: _.file, size: _.file_size, created: _.created }))
            this.setState({
                reference_file: ref,
                inspirational_file: ins
            })
        }
    }
    componentDidMount() {
        const { serviceDetail } = this.props;
        if (serviceDetail) {
            this.formRef.current.setFieldsValue({
                name: serviceDetail.name,
                description: serviceDetail.description,
                inspirational_file: serviceDetail.inspirational_file,
                reference_file: serviceDetail.reference_file,
                additional_look: serviceDetail.additional_look,
                extras: serviceDetail.extras,
            });
        }
    }

    onFinish = values => {
        if (this.state.reference_file.length && this.state.inspirational_file.length) {
            this.setState({ buttonLoading: true, insFileErr: false, resFileErr: false })
            let userDetails = { ...this.state.userDetails };
            values.project = this.props.projectId;
            values.reference_file = this.state.reference_file.map((_) => _.id);
            values.inspirational_file = this.state.inspirational_file.map((_) => _.id);
            if (userDetails.id || this.props.serviceDetail) {
                let id = userDetails.id ? userDetails.id : this.props.serviceDetail.id;
                updateServiceWebsiteDesign(id, values).then((response) => {
                    //for checking after login form 
                    this.props.onSubmit();
                    if (!this.props.serviceType) message.success("Details Updated Successfully")
                    this.setState({ buttonLoading: false })
                }).catch((err) => {
                    if (err.response) {
                        Object.keys(err.response.data).map((i) => {
                            message.error(err.response.data[i])
                        })
                        this.setState({ buttonLoading: false })
                    } else {
                        message.error("something went wrong")
                        this.setState({ buttonLoading: false })
                    }
                })
            } else {
                postServiceWebsite(values).then((response) => {
                    //for checking after login form 
                    this.props.onSubmit();
                    this.setState({ userDetails: response.data });
                    if (!this.props.serviceType) message.success('Form Submitted')
                    this.setState({ buttonLoading: false })
                }).catch((err) => {
                    if (err.response) {
                        Object.keys(err.response.data).map((i) => {
                            message.error(err.response.data[i])
                        })
                        this.setState({ buttonLoading: false })
                    } else {
                        message.error("something went wrong")
                        this.setState({ buttonLoading: false })
                    }
                })
            }
        } else {
            this.setState({ insFileErr: this.state.inspirational_file.length ? false : true, resFileErr: this.state.reference_file.length ? false : true })
        }
    };
    uploadFile = (info) => {
        const formData = new FormData();
        formData.append('files', info.file);
        this.setState({ refStatus: true })
        uploadFiles(formData).then((response) => {
            message.success("File Uploaded Successfully!");
            let data = { id: response.data[0].id, url: response.data[0].file, created: new Date(), size: info.file.size, name: info.file.name };
            this.setState(prevState => ({ resFileErr: false, reference_file: [data, ...prevState.reference_file], refStatus: false }))
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else { message.error("something went wrong") }
        })
    };
    uploadInspirationFile = (info) => {
        const formData = new FormData();
        formData.append('files', info.file);
        this.setState({ insStatus: true })
        uploadFiles(formData).then((response) => {
            message.success("File Uploaded Successfully!");
            let data = { id: response.data[0].id, url: response.data[0].file, created: new Date(), size: info.file.size, name: info.file.name };
            this.setState(prevState => ({ insFileErr: false, inspirational_file: [data, ...prevState.inspirational_file], insStatus: false }))
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else { message.error("something went wrong") }
        })
    };
    clearForm = () => {
        let id = [...this.state.reference_file.map((_) => _.uid || _.id), ...this.state.inspirational_file.map((_) => _.uid || _.id)]
        for (let i = 0; i < id.length; i++) {
            removeDocument(id[i], "").then((response) => {
                message.success("File Deleted Successfully")
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else { message.error("something went wrong") }
            });
        }
        this.setState({ reference_file: [], inspirational_file: [] })
        this.formRef.current.resetFields()
    }
    removeFile = (id, type) => {
        removeDocument(id, "").then((response) => {
            message.success("File Deleted Successfully")
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else { message.error("something went wrong") }
        });
        this.setState({ [type]: this.state[type].filter((_) => _.id !== id) })

    }
    render() {
        const { insFileErr, resFileErr } = this.state
        return (
            <React.Fragment>
                <Form onFinish={this.onFinish} ref={this.formRef}>
                    <div className="f-row">
                        <h6>Website name (optional)</h6>
                        <Form.Item name="name">
                            <Input className="fr-input" placeholder="Eg. Landing page for my food truck’s website" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Describe your website *</h6>
                        <Form.Item name="description" rules={[{ required: true, message: 'This field is required' }]}>
                            <TextArea rows={7} className="fr-input fr-inputTextArea"
                                placeholder="Eg. I have purchased the domain name Theloadedarepa.com. As described above, I want the website to display my colombian heritage and the authentic food the we serve. I just want to start with a simple landing page with images/videos of the food, the contact information and a picture of the team.
                                " />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Add reference files</h6>
                        <Form.Item>
                            <div className="file-list">
                                {this.state.reference_file.length ? this.state.reference_file.map((_) => (
                                    <div className="each-file">
                                        <Tooltip placement="top" title={_.name} trigger="hover">
                                            <a href={_.url} target="_blank">{_.name}</a>
                                        </Tooltip>
                                        <span>{bytesToSize(_.size)}</span>
                                        <span>{moment(_.created).format('lll')}</span>
                                        <img src={Images.remove_icon_main} className="cursor-pointer" onClick={() => this.removeFile(_.id, "reference_file")} />
                                    </div>
                                )) : ""}
                            </div>
                            <Dragger id="reference_file" showUploadList={false} beforeUpload={() => false} multiple={false}
                                onChange={this.uploadFile}>
                                <img alt={""} src={require('../../../assets/upload-icon.svg')} />
                                <label className="ant-upload-text mb-0 ml-3"> Drag & Drop or <span>Browse</span></label>
                            </Dragger>
                            {resFileErr && <span className="form-error">This field is required</span>}
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Add inspirational files</h6>
                        <Form.Item >
                            <div className="file-list">
                                {this.state.inspirational_file.length ? this.state.inspirational_file.map((_) => (
                                    <div className="each-file">
                                        <Tooltip placement="top" title={_.name} trigger="hover">
                                            <a href={_.url} target="_blank">{_.name}</a>
                                        </Tooltip>
                                        <span>{bytesToSize(_.size)}</span>
                                        <span>{moment(_.created).format('lll')}</span>
                                        <img src={Images.remove_icon_main} className="cursor-pointer" onClick={() => this.removeFile(_.id, "inspirational_file")} />
                                    </div>
                                )) : ""}
                            </div>
                            <Dragger id="inspirational_file" showUploadList={false} beforeUpload={() => false}
                                onChange={this.uploadInspirationFile}>
                                <img alt="" src={require('../../../assets/upload-icon.svg')} />
                                <label className="ant-upload-text mb-0 ml-3"> Drag & Drop or <span>Browse</span></label>
                            </Dragger>
                            {insFileErr && <span className="form-error">This field is required</span>}
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Look to any inspiration from the web <span>(separate websites with comma)</span></h6>
                        <Form.Item name="additional_look" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="E.g. facebook.com, youtube.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Anything else you’d like to share with your team?</h6>
                        <Form.Item name="extras" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="E.g. I like a minimalistic but retro feel." />
                        </Form.Item>
                    </div>
                    <div className="row em-form-inner-btn em-btn-all position-sticky edit-footer">
                        {this.props.clearForm && <Button className="bg-transparent border-0 shadow-none w-auto" onClick={this.clearForm}>Clear</Button>}
                        {this.props.serviceType == "inner" &&
                            <Button className="mt-4" onClick={this.props.onClose}>Cancel</Button>}
                        {!this.state.refStatus && !this.state.insStatus ? <Button loading={this.state.buttonLoading} type="primary" htmlType="submit" size="large"
                            className="d-inline mt-4 continue-button">SUBMIT</Button> : <Button type="primary" disabled size="large"
                                className="d-inline mt-4 continue-button">SUBMIT</Button>}
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}