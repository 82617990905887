import React, {Component} from 'react';
import {Button, Drawer, Form, Input, Select} from 'antd';
import {Image as Images} from "../Images";
const Option = Select;
class AddNewAddress extends Component {
    render() {
        return (
            <React.Fragment>
                    <Drawer
                        className="em-main-drawer em-address-drawer"
                        title={
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div>Add New Address</div>
                            <Button onClick={this.props.onClose} className="close-btn-drawer bg-transparent border-0 shadow-none p-0 d-flex align-items-center">Close <img alt="close icon" className="img-fluid ml-1" src={Images.close_icon_white}/></Button>
                        </div>
                    }
                        placement="right"
                        closable={false}
                        onClose={this.props.onClose}
                        visible={this.props.visible}
                        getContainer={false}
                        width={"40%"}
                        style={{position: 'absolute'}}
                    >
                        <div className="row em-drawer-main-row">
                            <div className="col-12">
                                <Form>
                                    <div className="row em-form-inner-row">
                                        <div className="col-12">
                                            <Form.Item name="full_name" label={"Full Name"}
                                                       className={"group-floating-label"}>
                                                <Input placeholder="Full Name"/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-12">
                                            <Form.Item name="address" label={"Address"}
                                                       className={"group-floating-label"}>
                                                <Input placeholder="Street Address"/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-12">
                                            <Form.Item name="floor" label={"Floor,Suite,etc"}
                                                       className={"group-floating-label"}>
                                                <Input placeholder="Street Address"/>
                                            </Form.Item>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <Form.Item name="city" label={"City"}
                                                               className={"group-floating-label"}>
                                                        <Input placeholder="City"/>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <Form.Item name="floor" label={"State"}
                                                               className={"group-floating-label"}>
                                                        <Select placeholder="Select State">
                                                            <Option key={1}>Select State</Option>
                                                            <Option key={2}>Select State</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-12 col-sm-6">
                                                    <Form.Item name="zip" label={"ZIP/Postal Code"}
                                                               className={"group-floating-label"}>
                                                        <Input placeholder="1234"/>
                                                    </Form.Item>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <Form.Item name="floor" label={"Country"}
                                                               className={"group-floating-label"}>
                                                        <Select placeholder="Select Country">
                                                            <Option key={1}>Select Country</Option>
                                                            <Option key={2}>Select Country</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="row em-form-inner-btn em-btn-all">
                            <div className="col-12 text-right">
                                <Button onClick={this.props.onClose}>Cancel</Button>
                                <Button type="primary">Save</Button>
                            </div>
                        </div>
                    </Drawer>
            </React.Fragment>
        );
    }
}

export default AddNewAddress;