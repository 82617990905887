import React, { Component } from 'react';
import { Button, Drawer, Form, Input, message } from 'antd';
import { Image as Images } from "../Images";
import { updatePassword } from '../../Controller/API/UserAPI';


class ChangePassword extends Component {
    state = {
        loading: false
    }
    formRef = React.createRef();

    onFinish = (values) => {
        if (values.new_password !== values.confirm_password) {
            message.error("Password Does Not Match")
        } else {
            this.setState({ loading: true })
            updatePassword(values).then((response) => {
                this.setState({ loading: false })
                this.props.onClose()
                this.props.onSuccess()
                this.formRef.current.resetFields()
            }).catch((err) => {
                this.setState({ loading: false })
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
        }
    }
    render() {
        return (
            <React.Fragment>
                <Drawer
                    className="em-main-drawer em-address-drawer password-security"
                    title={
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div>Change Password</div>
                            <Button onClick={this.props.onClose} className="close-btn-drawer bg-transparent border-0 shadow-none p-0 d-flex align-items-center">Close <img alt="close icon" className="img-fluid ml-1" src={Images.close_icon_white} /></Button>
                        </div>
                    }
                    placement="right"
                    closable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    getContainer={false}
                    width={"40%"}
                    style={{ position: 'absolute' }}
                >
                    <div className="row em-drawer-main-row ">
                        <div className="col-12">
                            <Form onFinish={this.onFinish} ref={this.formRef}>
                                <div className="row em-form-inner-row">
                                    <div className="col-12">
                                        <Form.Item name="password" name="old_password" label={"Old Password"}
                                            className={"group-floating-label input-Password"} rules={[{ required: true, message: 'This field is required!' },
                                            { min: 8, message: 'Password must be 8 characters long.' }]}>
                                            <Input.Password placeholder="" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="password" name="new_password" label={"New Password"}
                                            className={"group-floating-label input-Password"} rules={[{ required: true, message: 'This field is required!' },
                                            { min: 8, message: 'Password must be 8 characters long.' }]}>
                                            <Input.Password placeholder="" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="confirm_password" label={"Confirm New Password"}
                                            className={"group-floating-label input-Password"} rules={[{ required: true, message: 'This field is required!' },
                                            { min: 8, message: 'Password must be 8 characters long.' }]}>
                                            <Input.Password placeholder="" />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="row em-form-inner-btn em-btn-all">
                                    <div className="col-12 text-right">
                                        <Button onClick={this.props.onClose}>Cancel</Button>
                                        <Button type="primary" loading={this.state.loading} htmlType="submit">Save</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default ChangePassword;