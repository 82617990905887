import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isLoggedIn} from "../Controller/AuthService";
import routes from "./Routes";

export const PublicRoute = ({component: Component, restricted, ...rest}) => (
    <Route {...rest} render={props => (
        isLoggedIn() && restricted ?
            <Redirect
                to={{pathname: routes.dashboard.self, state: {from: props.location}}}/> :
            <Component {...props} {...rest} />
    )}/>
);
