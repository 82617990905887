import React from 'react';
import './style.scss';
import { Menu, Layout, Input } from 'antd';
import { Link, NavLink, withRouter } from "react-router-dom";
import routes from "../../Controller/Routes";
import { Image as Images } from '../Images'
import Login from "../Login";
import { isLoggedIn } from '../../Controller/AuthService';
import { toggleLoginAction } from '../../redux/Actions/userActions';
import { connect } from 'react-redux';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

const { Header } = Layout;
const { Search } = Input;

class CustomHeader extends React.Component {
    handleChange = (route) => {
        if (isLoggedIn()) {
            this.props.history.push(route)
            if (route !== "/dashboard/view-account/" && route !== "/dashboard/") {
                this.showMenu()
            } else {
                this.hideMenu()
            }
        } else {
            this.props.toggleLoginAction(true)
        }
    }
    hideMenu = () => {
        document.getElementById('slide-menu').classList.remove('active')
    }
    showMenu = () => {
        document.getElementById('slide-menu').classList.toggle('active')
    }
    render() {
        return (
            <>
                <Header theme="light" className="header-container header-responsive toggle-menu-container">
                    <div className="row mx-0 align-items-center">
                        <div className=" col-md-4 col-sm-3 col-12 space-between">
                            <label className="m-0 cursor-pointer header-logo user-select" onClick={() => this.handleChange(routes.dashboard.self)}>
                                <img className="img-fluid w-50" alt="" src={Images.logo_main} />
                            </label>
                            <MenuOutlined className="hamburger_menu_btn" onClick={this.showMenu} />
                        </div>
                        <div className="col-md-5 p-0 col-sm-5">
                            <Menu mode="horizontal">
                                <Menu.Item className="text-uppercase" key="home" >
                                    <label className="m-0 cursor-pointer user-select" onClick={() => this.handleChange(routes.dashboard.self)}>Home</label>
                                </Menu.Item>
                                <Menu.Item className="text-uppercase" key="projects">
                                    <label className="m-0 cursor-pointer user-select" onClick={() => this.handleChange(routes.dashboard.projects.self)}>PROJECTS</label></Menu.Item>
                                <Menu.Item className="text-uppercase" key="proposals">
                                    <label className="m-0 cursor-pointer user-select" onClick={() => this.handleChange(routes.dashboard.proposals)}>PROPOSALS</label>
                                </Menu.Item>
                                <Menu.Item className="text-uppercase" key="invoices">
                                    <label className="m-0 cursor-pointer user-select" onClick={() => this.handleChange(routes.dashboard.invoices)}>Invoices</label>
                                </Menu.Item>
                                <Menu.Item className="text-uppercase" key="contactUs">CONTACT US</Menu.Item>
                            </Menu>

                        </div>
                        <div className="col-md-3 col-sm-3 r-padr-0 r-mt-10">
                            <div className="row mx-0 align-items-center justify-content-between">
                                <Search placeholder="Search" className="search-head" />
                                <label onClick={() => this.handleChange(routes.dashboard.viewAccount)}
                                    className="m-0 cursor-pointer user-select user-info-btn bg-transparent shadow-none border-0 p-0 d-flex align-items-center justify-content-center">
                                    <img className="img-fluid" alt="" src={Images.user_icon} />
                                </label>
                            </div>
                        </div>
                        <div className="mobile-menu" id="slide-menu">
                            <div className="menu-animation">
                                <CloseOutlined className="float-right" onClick={this.showMenu} />
                                <Menu mode="horizontal ">
                                    <Menu.Item className="text-uppercase" key="home">
                                        <label className="m-0 cursor-pointer user-select" onClick={() => this.handleChange(routes.dashboard.self)}>Home</label>
                                    </Menu.Item>
                                    <Menu.Item className="text-uppercase" key="projects">
                                        <label className="m-0 cursor-pointer user-select" onClick={() => this.handleChange(routes.dashboard.projects.self)}>PROJECTS</label></Menu.Item>
                                    <Menu.Item className="text-uppercase" key="proposals">
                                        <label className="m-0 cursor-pointer user-select" onClick={() => this.handleChange(routes.dashboard.proposals)}>PROPOSALS</label>
                                    </Menu.Item>
                                    <Menu.Item className="text-uppercase" key="invoices">
                                        <label className="m-0 cursor-pointer user-select" onClick={() => this.handleChange(routes.dashboard.invoices)}>Invoices</label>
                                    </Menu.Item>
                                    <Menu.Item className="text-uppercase" key="contactUs">CONTACT US</Menu.Item>
                                </Menu>
                            </div>
                        </div>
                    </div>
                </Header>
                <Login />
            </>
        )
    }
}

export default connect("", { toggleLoginAction })(withRouter(CustomHeader))