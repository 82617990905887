// import { apiMethods } from "./Request";
import {Config} from "./Global";
import {history} from './History';

const USER_TOKEN_KEY = 'user_token';
const USER_REFRESH_KEY = 'user_refresh_token';

const TEMP_TOKEN_KEY = 'temp_token';
const TEMP_REFRESH_KEY = 'temp_refresh_token';


export function logout() {
    clearUserToken();
    clearTempToken();
    history.push('/');
}

export function requireAuth(nextState, replace) {
    if (!isLoggedIn()) {
        replace({pathname: '/'});
    }
}

export function getUserToken() {
    return localStorage.getItem(USER_TOKEN_KEY);
}

export function getRefreshToken() {
    return localStorage.getItem(USER_REFRESH_KEY)
}

export function clearUserToken() {
    localStorage.removeItem(USER_TOKEN_KEY);
    localStorage.removeItem(USER_REFRESH_KEY);
    localStorage.removeItem('persist:root')
}

export function setUserToken(token) {
    console.log('setting token');
    localStorage.setItem(USER_TOKEN_KEY, token);
}

export function isLoggedIn() {
    const userToken = getUserToken();
    return !!userToken;
}

// temp token
export function setRefreshToken(token) {
    localStorage.setItem(USER_REFRESH_KEY, token)
}

export function getTempToken() {
    return sessionStorage.getItem(TEMP_TOKEN_KEY);
}

export function getTempRefreshToken() {
    return sessionStorage.getItem(TEMP_REFRESH_KEY)
}

export function clearTempToken() {
    sessionStorage.removeItem(TEMP_TOKEN_KEY);
    sessionStorage.removeItem(TEMP_REFRESH_KEY);
}

export function setTempToken(token) {
    sessionStorage.setItem(TEMP_TOKEN_KEY, token);
}

export function setTempRefreshToken(token) {
    sessionStorage.setItem(TEMP_REFRESH_KEY, token)
}



