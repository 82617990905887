import React, { Component } from 'react';
import './style.scss'
import { Button, Table, Pagination } from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { Image as Images } from "../Images";
import routes from '../../Controller/Routes';

class Home extends Component {
    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: <div className="d-flex align-items-center align-items-center">Date Created <img src={Images.arrow_down} alt="arrow down" className="img-fluid ml-1" /></div>,
            dataIndex: 'date_created',
        }, {
            title: 'status',
            dataIndex: 'status',
        },
    ];
    data = [
        {
            key: '1',
            name: <div className="d-flex align-items-center">
                <span className="icon-img">
                    <img alt="folder web icon" className="img-fluid" src={Images.project_folder} />
                </span>
                <div className="text-div-inn">
                    Project Name
                </div>
            </div>,
            type: 'Project',
            date_created: 'Aug 5, 2020',
            status: <Button className="status-btn border-0 shadow-none">Status</Button>,
        },
        {
            key: '2',
            name: <div className="d-flex align-items-center">
                <span className="icon-img">
                    <img alt="folder web icon" className="img-fluid" src={Images.proposal_invoice_icon} />
                </span>
                <div className="text-div-inn">
                    Proposal Name (Document’s name)
                </div>
            </div>,
            type: 'Proposal',
            date_created: 'Aug 5, 2020',
            status: <Button className="status-btn border-0 shadow-none">Status</Button>,
        }, {
            key: '3',
            name: <div className="d-flex align-items-center">
                <span className="icon-img">
                    <img alt="folder web icon" className="img-fluid" src={Images.invoice_icon} />
                </span>
                <div className="text-div-inn">
                    Invoice Name
                </div>
            </div>,
            type: 'Invoice',
            date_created: 'Aug 5, 2020',
            status: <Button className="status-btn border-0 shadow-none">Status</Button>,
        }, {
            key: '4',
            name: <div className="d-flex align-items-center">
                <span className="icon-img">
                    <img alt="folder web icon" className="img-fluid" src={Images.project_folder} />
                </span>
                <div className="text-div-inn">
                    Project Name
                </div>
            </div>,
            type: 'Project',
            date_created: 'Aug 5, 2020',
            status: <Button className="status-btn border-0 shadow-none">Status</Button>,
        }, {
            key: '5',
            name: <div className="d-flex align-items-center">
                <span className="icon-img">
                    <img alt="folder web icon" className="img-fluid" src={Images.proposal_invoice_icon} />
                </span>
                <div className="text-div-inn">
                    Proposal Name (Document’s name)
                </div>
            </div>,
            type: 'Proposal',
            date_created: 'Aug 5, 2020',
            status: <Button className="status-btn border-0 shadow-none">Status</Button>,
        }, {
            key: '6',
            name: <div className="d-flex align-items-center">
                <span className="icon-img">
                    <img alt="folder web icon" className="img-fluid" src={Images.invoice_icon} />
                </span>
                <div className="text-div-inn">
                    Invoice Name
                </div>
            </div>,
            type: 'Invoice',
            date_created: 'Aug 5, 2020',
            status: <Button className="status-btn border-0 shadow-none">Status</Button>,
        },
    ];

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid dashboard-main em-main-container-fluid">
                    <div className="container">
                        <div className="row m-0 em-main-row-all align-items-center">

                            {/* <div className="col-12 dash-services">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row em-service-details-row align-items-center">
                                            <div className="col-12 col-md-10">
                                                <div className="row mx-0 align-items-center">
                                                    <span className="em-icon-div">
                                                        <img alt="folder web icon" className="img-fluid"
                                                            src={Images.table_folder_web_icon} />
                                                    </span>
                                                    <div className="em-text-div-inner">
                                                        Microsite for FoodExpo 2020
                                                        <small>Website design & development</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-2 dc-complete">
                                                <div className="row mx-0 em-status-dropdown-row align-items-center">
                                                    <h6 className='mb-0 text-uppercase'>Completed</h6>
                                                    <Button className="bg-transparent border-0 shadow-none p-0">
                                                        <img alt="menu" className="img-fluid"
                                                            src={Images.menu_ellipsis} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row em-service-details-row align-items-center">
                                            <div className="col-12 col-md-10">
                                                <div className="row mx-0 align-items-center">
                                                    <span className="em-icon-div">
                                                        <img alt="folder web icon" className="img-fluid"
                                                            src={Images.email_icon} />
                                                    </span>
                                                    <div className="em-text-div-inner">
                                                        Email Marketing for FoodExpo 2020
                                                        <small>Email Marketing</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-2 dc-complete">
                                                <div className="row mx-0 em-status-dropdown-row align-items-center">
                                                    <h6 className='mb-0 text-uppercase'>Completed</h6>
                                                    <Button className="bg-transparent border-0 shadow-none p-0">
                                                        <img alt="menu" className="img-fluid"
                                                            src={Images.menu_ellipsis} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row em-service-details-row align-items-center">
                                            <div className="col-12 col-md-10">
                                                <div className="row mx-0 align-items-center">
                                                    <span className="em-icon-div">
                                                        <img alt="folder web icon" className="img-fluid"
                                                            src={Images.email_icon} />
                                                    </span>
                                                    <div className="em-text-div-inner">
                                                        Email Marketing for FoodExpo 2020
                                                        <small>Email Marketing</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-2 dc-complete">
                                                <div className="row mx-0 em-status-dropdown-row align-items-center">
                                                    <h6 className='mb-0 text-uppercase'>Completed</h6>
                                                    <Button className="bg-transparent border-0 shadow-none p-0">
                                                        <img alt="menu" className="img-fluid"
                                                            src={Images.menu_ellipsis} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row em-service-details-row align-items-center">
                                            <div className="col-12 col-md-10">
                                                <div className="row mx-0 align-items-center">
                                                    <span className="em-icon-div">
                                                        <img alt="folder web icon" className="img-fluid"
                                                            src={Images.email_icon} />
                                                    </span>
                                                    <div className="em-text-div-inner">
                                                        Email Marketing for FoodExpo 2020
                                                        <small>Email Marketing</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-12 col-md-2 dc-complete">
                                                <div className="row mx-0 em-status-dropdown-row align-items-center">
                                                    <h6 className='mb-0 text-uppercase'>Completed</h6>
                                                    <Button className="bg-transparent border-0 shadow-none p-0">
                                                        <img alt="menu" className="img-fluid"
                                                            src={Images.menu_ellipsis} />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row em-pagination-row">
                                    <div className="col-12 p-0 text-right">
                                        <Pagination size="small" total={50} />
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 p-0">
                                        <div className="row em-project-card-row pt-3">
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                                                <div className="row em-project-card-inner" onClick={() => this.props.history.push({ pathname: routes.dashboard.projects.self, state: { status: "Pending" } })}>
                                                    <div className="col-12">
                                                        <h3>6</h3>
                                                        <p className="mb-0">Pending Projects</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                                                <div className="row em-project-card-inner" onClick={() => this.props.history.push({ pathname: routes.dashboard.projects.self, state: { status: "In_Progress" } })}>
                                                    <div className="col-12">
                                                        <h3>2</h3>
                                                        <p className="mb-0">Projects in progress</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                                                <div className="row em-project-card-inner" onClick={() => this.props.history.push(routes.dashboard.proposals)}>
                                                    <div className="col-12">
                                                        <h3>3</h3>
                                                        <p className="mb-0">Proposals to review</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-3 col-lg-3">
                                                <div className="row em-project-card-inner" onClick={() => this.props.history.push(routes.dashboard.invoices)}>
                                                    <div className="col-12">
                                                        <h3>1</h3>
                                                        <p className="mb-0">Invoice to fufill</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 r-pad-0 p-0">
                                <div className="row m-0 em-heading-all align-items-center pb-4">
                                    <div className="col-6 col-sm-6">
                                        <h6 className="mb-0">Recent Activity</h6>
                                    </div>
                                    <div className="col-6 col-sm-6">
                                        <Button onClick={() => this.props.history.push({
                                            pathname: routes.dashboard.projectCreation,
                                            state: { addProject: true }
                                        })}
                                            className="em-add-btn float-right text-center text-uppercase border-0 d-flex align-items-center"><PlusOutlined
                                                className="mr-1" />New
                                            Project</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row em-service-table-card">
                                    <div className="col-12 p-0">
                                        <Table
                                            className="table-responsive em-service-table"
                                            columns={this.columns}
                                            dataSource={this.data}
                                            size="middle"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment >
        );
    }
}

export default Home;