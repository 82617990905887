import React, { Component } from 'react';
import { Button, Form, Input, Select, Divider, Table, DatePicker } from 'antd';
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import AddCardInfo from "../Drawers/AddCardInfo";

const { Option } = Select;

function onChange(date, dateString) {
    console.log(date, dateString);
}

function handleChange(value) {
    console.log(`selected ${value}`);
}

let index = 0;

class PayInvoice extends Component {

    state = {
        visible: false,
        items: ['Mastercard ending in 9924', 'Discover ending in 9924', 'Amex ending in 9924'],
    };
    columns = [
        {
            title: 'Service',
            dataIndex: 'service',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Open Balance',
            dataIndex: 'openBalance',
        },
        {
            title: 'Amount Paid',
            dataIndex: 'amountPaid',
            render: text => <div>
                <Input placeholder="$0.00" />
            </div>,
        },
    ];
    data = [
        {
            key: '1',
            service: 'Website Design & Development',
            description: 'Landing page creation for TheLoadedArepa.com',
            openBalance: '$400.00',
            amountPaid: '$400.00',
        },
        {
            key: '2',
            service: 'Website Design & Development',
            description: 'Landing page creation for TheLoadedArepa.com',
            openBalance: '$400.00',
            amountPaid: '$400.00',
        },
        {
            key: '3',
            service: 'Website Design & Development',
            description: 'Landing page creation for TheLoadedArepa.com',
            openBalance: '$400.00',
            amountPaid: '$400.00',
            total: '$800.00',
        },
        {
            key: '4',
            service: 'Website Design & Development',
            description: 'Landing page creation for TheLoadedArepa.com',
            openBalance: '$400.00',
            amountPaid: '$400.00',
        },
    ];
    total_columns = [
        {
            title: 'Service',
            dataIndex: 'total_text',

        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Open Balance',
            dataIndex: 'openBalance',
        },
        {
            title: 'Amount Paid',
            dataIndex: 'amountPaid',
        },
    ];
    total_data = [
        {
            key: '1',
            total_text: 'Total:',
            description: '',
            openBalance: '$1,600.00',
            amountPaid: '$1,600.00',
        },
    ];

    showAddCardInfoDrawer = (visible) => {
        this.setState({
            visible: visible,
        });
    };

    addItem = () => {
        console.log('addItem');
        const { items } = this.state;
        this.setState({
            items: [...items, `New item ${index++}`],
        });
    };

    render() {
        const { items } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid em-main-container-fluid em-container-fluid-invoice-white">
                    <div className="container">
                        <div className="row em-main-row-all align-items-center">
                            <div className="col-12">
                                <div className="row em-invoice-details-1">
                                    <div className="col-12 col-sm-12 col-md-9">
                                        <div className="row em-invoice-text-details">
                                            <div className="col-12 col-sm-4 col-md-2">
                                                <h6>Invoice #</h6>
                                                <p>1008</p>
                                            </div>
                                            <div className="col-12 col-sm-4 col-md-3">
                                                <h6>Project</h6>
                                                <p>Landing Page Redesign</p>
                                            </div>
                                            <div className="col-12 col-sm-4 col-md-3">
                                                <p>Payment Account</p>
                                                <Form>
                                                    <Select
                                                        placeholder={"Select"}
                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div className="drawer-btn-plus"
                                                                    style={{ cursor: 'pointer' }}
                                                                    onMouseDown={e => e.preventDefault()}
                                                                    onClick={() => this.showAddCardInfoDrawer(true)}
                                                                >
                                                                    <PlusOutlined className="mr-1" /> Add New Payment
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {items.map(item => (
                                                            <Option key={item}>{item}</Option>
                                                        ))}
                                                    </Select>
                                                </Form>
                                            </div>
                                            <div className="col-12 col-sm-4 col-md-3">
                                                <p>Payment Date</p>
                                                <Form>
                                                    <DatePicker onChange={onChange} />
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-3">
                                        <div className="row em-open-balance-row">
                                            <div className="col-12">
                                                <h5 className="text-right">Total Payment Amount:</h5>
                                                <h1 className="text-right text-uppercase">$0.00</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid em-main-container-fluid em-container-fluid-invoice">
                    <div className="container">
                        <div className="row em-main-row-all align-items-center">
                            <div className="col-12">
                                <div className="em-invoice-table-row row">
                                    <div className="col-12">
                                        <Table
                                            bordered
                                            className="table-responsive em-invoice-table"
                                            columns={this.columns}
                                            dataSource={this.data}
                                            size="middle"
                                        />
                                    </div>
                                    <div className="col-12">
                                        <Table
                                            className="table-responsive em-invoice-table-total pay-invoice-table"
                                            columns={this.total_columns}
                                            dataSource={this.total_data}
                                            size="middle"
                                        />
                                    </div>
                                </div>
                                <div className="row em-btn-all">
                                    <div className="col-12 text-right">
                                        <Button className="text-uppercase">Cancel</Button>
                                        <Button className="text-uppercase send-payment">send payment & print</Button>
                                        <Button type="primary" className="text-uppercase">send payment</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddCardInfo visible={this.state.visible} onClose={() => this.showAddCardInfoDrawer(false)} />
            </React.Fragment>
        );
    }
}

export default PayInvoice;