import React, { Component } from 'react';
import { Input, Button, Form, message } from 'antd';
import AddServiceModal from '../modals/AddService';
import { Image as Images } from '../Images';
import { setProjectInfo, getProjectInfobyId, updateProjectInfo } from '../../Controller/API/ProjectAPI';
import { getServicesAction } from '../../redux/Actions/projectAction';
import { connect } from 'react-redux';
import { step } from '../../Controller/Global';
import { withRouter } from "react-router-dom";

const { TextArea } = Input;


class ProjectInfo extends Component {
    state = {
        selectedService: [],
        selectedServiceErr: false,
        buttonLoading: false
    }
    projectFormRef = React.createRef();

    componentDidMount() {
        if (this.props.projectId) {
            getProjectInfobyId(this.props.projectId, "").then((response) => {
                this.projectFormRef.current.setFieldsValue({
                    name: response.data.name,
                    description: response.data.description
                });
                this.setState({ selectedService: response.data.services })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
        }
        this.props.getServicesAction()
    }

    onFinish = values => {
        if (this.state.selectedService.length) {
            this.setState({ buttonLoading: true })
            values.services = this.state.selectedService.map((_) => _.id)
            if (this.props.projectId) {
                updateProjectInfo(this.props.projectId, values).then((response) => {
                    this.props.handleProcess(step.YOUR_SERVICE)
                    this.props.handleSelectedService(response.data)
                    this.setState({ buttonLoading: false })
                }).catch((err) => {
                    if (err.response) {
                        Object.keys(err.response.data).map((i) => {
                            message.error(err.response.data[i])
                        })
                        this.setState({ buttonLoading: false })
                    } else {
                        message.error("something went wrong")
                        this.setState({ buttonLoading: false })
                    }
                })
            } else {
                setProjectInfo(values).then(response => {
                    this.props.handleProcess(step.YOUR_SERVICE)
                    this.props.handleSelectedService(response.data)
                    this.setState({ buttonLoading: false })
                }).catch((err) => {
                    if (err.response) {
                        Object.keys(err.response.data).map((i) => {
                            message.error(err.response.data[i])
                        })
                        this.setState({ buttonLoading: false })
                    } else {
                        message.error("something went wrong")
                        this.setState({ buttonLoading: false })
                    }
                })
            }
        } else {
            this.setState({ selectedServiceErr: true })
        }
    };

    selectService(service) {
        let selectedService = [...this.state.selectedService];
        if (!selectedService.some(_ => _.id === service.id)) {
            selectedService = [...selectedService, service]
        } else {
            selectedService = selectedService.filter((_) => _.id !== service.id)
        }
        if (selectedService.length) {
            this.setState({ selectedServiceErr: false })
        } else {
            this.setState({ selectedServiceErr: true })
        }
        this.setState({ selectedService })
    }

    render() {
        const { selectedService, selectedServiceErr } = this.state
        return (
            <div className="project-info-container">
                <h2 className="aic-heading">Tell us about your project</h2>
                <Form className="mt-4" ref={this.projectFormRef} onFinish={this.onFinish}>
                    <div className="aic-form">
                        <div className="f-row">
                            <h6>Name your project *</h6>
                            <Form.Item name="name" rules={[{ required: true, message: 'This field is required' }]}>
                                <Input className="fr-input" placeholder="Eg. Landing page for my food truck’s website" />
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>Describe your project</h6>
                            <Form.Item name="description" rules={[{ required: true, message: 'This field is required' }]}>
                                <TextArea rows={7} className="fr-input fr-inputTextArea"
                                    placeholder="Eg. I am looking to grow my food truck’s online presence to grow my food truck “The Loaded Arepa”. It’s a Colombian food truck that specializes in Arepas. I have attached an image of the truck below. I would like to showcase my Colombian heritage through the website and highlight the food of course. I was thinking maybe a video of us making the food for the background of the landing page. I would also love to have an image of me and the rest of my crew somewhere on the page.
                                    " />
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>What’s services are you looking for? *</h6>
                            <div className="pic-services d-block mt-4">
                                {this.props.services.results && this.props.services.results.map(_ => (
                                    <div
                                        className={selectedService.some((__) => __.id === _.id) ? "each-service active position-relative cursor-pointer" : "each-service cursor-pointer"}
                                        onClick={() => this.selectService(_)}>
                                        {selectedService.some((__) => __.id === _.id) &&
                                            <img className="check-icon" src={require('../../assets/selected.svg')} />}
                                        <div className="es-top">
                                            <img alt="design-service" className="img-fluid" src={_.icon} />
                                        </div>
                                        <label className="cursor-pointer">{_.title}</label>
                                    </div>
                                ))}
                            </div>
                            {selectedServiceErr && <span className="form-error ml-3">This field is required</span>}
                        </div>
                    </div>
                    <div className="form-actions">
                        {/* {this.props.location.pathname === "/" &&
                            <Button type="primary" size="large" onClick={() => this.props.handleProcess('accountInfo')}
                                className="d-inline text-uppercase mr-4 mt-4 back-button">BACK</Button>} */}
                        <Button loading={this.state.buttonLoading} htmlType="submit" type="primary" size="large"
                            className="d-inline text-uppercase mt-4 continue-button">CONTINUE</Button>
                    </div>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        services: state.services
    }
};
export default connect(mapStateToProps, { getServicesAction })(withRouter(ProjectInfo));