import React, { Component } from 'react';
import { Input, Checkbox, Row, Col, Radio, Form, Button, message } from 'antd';
import { postContentGeneration, updateContentGeneration } from '../../../Controller/API/ServiceAPI';
const { TextArea } = Input;


export default class ContentGeneration extends Component {
    state = {
        copywriting: null,
        copywritingShow: false,
        content_needs: null,
        content_needsShow: false,
        existing_needs: null,
        existing_needsShow: false,
        tone: null,
        toneShow: false,
        marketing_forms: null,
        marketing_formsShow: false,
        priority_reason: null,
        priority_reasonShow: false,
        userDetails: {},
        buttonLoading: false
    }
    formRef = React.createRef();

    componentDidMount() {
        const { serviceDetail } = this.props
        if (serviceDetail) {
            if (serviceDetail.copywriting.includes('other')) {
                this.setState({ copywriting: serviceDetail.copywriting[serviceDetail.copywriting.length - 1], copywritingShow: true })
                serviceDetail.copywriting.pop()
            }
            if (serviceDetail.content_needs.includes('other')) {
                this.setState({ content_needs: serviceDetail.content_needs[serviceDetail.content_needs.length - 1], content_needsShow: true })
                serviceDetail.content_needs.pop()
            }
            if (serviceDetail.existing_needs.includes('other')) {
                this.setState({ existing_needs: serviceDetail.existing_needs[serviceDetail.existing_needs.length - 1], existing_needsShow: true })
                serviceDetail.existing_needs.pop()
            }
            if (serviceDetail.priority_reason.includes('other')) {
                this.setState({ priority_reason: serviceDetail.priority_reason[serviceDetail.priority_reason.length - 1], priority_reasonShow: true })
                serviceDetail.priority_reason.pop()
            }
            if (serviceDetail.marketing_forms.includes('other')) {
                this.setState({ marketing_forms: serviceDetail.marketing_forms[serviceDetail.marketing_forms.length - 1], marketing_formsShow: true })
                serviceDetail.marketing_forms.pop()
            }
            if (serviceDetail.tone.includes('other')) {
                this.setState({ tone: serviceDetail.tone[serviceDetail.tone.length - 1], toneShow: true })
                serviceDetail.tone.pop()
            }
            this.formRef.current.setFieldsValue({
                name: serviceDetail.name,
                business: serviceDetail.business,
                content_length: serviceDetail.content_length,
                copywriting: serviceDetail.copywriting,
                content_needs: serviceDetail.content_needs,
                comments: serviceDetail.comments,
                existing_needs: serviceDetail.existing_needs,
                tone: serviceDetail.tone,
                targeting_gender: serviceDetail.targeting_gender[0],
                age_range: serviceDetail.age_range[0],
                client_income: serviceDetail.client_income[0],
                audience_education: serviceDetail.audience_education,
                priority_reason: serviceDetail.priority_reason,
                competitors: serviceDetail.competitors,
                marketing_forms: serviceDetail.marketing_forms,
            })
        }
    }

    onFinish = values => {
        this.setState({ buttonLoading: true })
        let userDetails = { ...this.state.userDetails }
        if (this.state.copywriting) {
            values.copywriting.push(this.state.copywriting)
        }
        if (this.state.content_needs) {
            values.content_needs.push(this.state.content_needs)
        }
        if (this.state.existing_needs) {
            values.existing_needs.push(this.state.existing_needs)
        }
        if (this.state.tone) {
            values.tone.push(this.state.tone)
        }
        if (this.state.marketing_forms) {
            values.marketing_forms.push(this.state.marketing_forms)
        }
        if (this.state.priority_reason) {
            values.priority_reason.push(this.state.priority_reason)
        }
        values.client_income = [values.client_income]
        values.targeting_gender = [values.targeting_gender]
        values.age_range = [values.age_range]
        values.project = this.props.projectId
        if (userDetails.id || this.props.serviceDetail) {
            let id = userDetails.id ? userDetails.id : this.props.serviceDetail.id
            updateContentGeneration(id, values).then((response) => {
                //for checking after login form 
                this.props.onSubmit()
                if (!this.props.serviceType) message.success("Details Updated Successfully")
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong")
                    this.setState({ buttonLoading: false })
                }
            })
        } else {
            postContentGeneration(values).then((response) => {
                //for checking after login form 
                this.props.onSubmit()
                this.setState({ userDetails: response.data })
                if (!this.props.serviceType) message.success("Form Submitted")
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong")
                    this.setState({ buttonLoading: false })
                }
            })
        }
    }
    handleOther = (event) => {
        if (event.target.checked === true) {
            this.setState({ [event.target.id]: "", [event.target.id + "Show"]: true })
        } else {
            this.setState({ [event.target.id + "Show"]: false, [event.target.id]: "" })
        }
    }
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    clearForm = () => {
        this.formRef.current.resetFields()
        this.setState({
            copywriting: null,
            copywritingShow: false,
            content_needs: null,
            content_needsShow: false,
            existing_needs: null,
            existing_needsShow: false,
            tone: null,
            toneShow: false,
            marketing_forms: null,
            marketing_formsShow: false,
            priority_reason: null,
            priority_reasonShow: false,
        })
    }
    render() {
        return (
            <React.Fragment>
                <Form onFinish={this.onFinish} className="otherInputBorder" ref={this.formRef}>
                    <div className="f-row">
                        <h6>Name your project<span>(optional)</span></h6>
                        <Form.Item name="name">
                            <Input className="fr-input" placeholder="Eg. Landing page for my food truck’s website" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What does your business do/sell?</h6>
                        <Form.Item name="business" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Do you have a preference for the length of your content or blog posts? *</h6>
                        <Form.Item name="content_length" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What copywriting and content needs do you have? Please check all that apply. *</h6>
                        <Form.Item name="copywriting" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="caseStudies">Case Studies</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="eBooks">E-books or Guides</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="landingPage">Landing Pages</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="instructionalCourse">Instructional Course</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="salesFunnel">Sales Funnel</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="copywriting" value="other" onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify" value={this.state.copywriting} name="copywriting" onChange={this.handleChange} disabled={!this.state.copywritingShow} />
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="emailContent">Email Content</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Please check all that may apply to your content needs *</h6>
                        <Form.Item name="content_needs" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="optimizeExisting">Optimize Existing</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="competitive">Competitive</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="content">Content</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="linkBuilding">Link Building</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="keyword">Keyword</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="content_needs" value="other" onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify" value={this.state.content_needs} name="content_needs" onChange={this.handleChange} disabled={!this.state.content_needsShow} />
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="research">Research</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Please enter additional comments that apply to your content needs here:</h6>
                        <Form.Item name="comments" rules={[{ required: true, message: 'This field is required' }]}>
                            <TextArea rows={4} className="fr-input h-auto" placeholder="E.g. I need an email to announce new servic" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What copywriting and content needs do you have? Please check all that apply. *</h6>
                        <Form.Item name="existing_needs" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="hip">Hip</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="professional">Professional</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="funYoung">Fun Young</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="creative">Creative</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="adorable">Adorable</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="existing_needs" value="other" onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify" value={this.state.existing_needs} name="existing_needs" onChange={this.handleChange} disabled={!this.state.existing_needsShow} />
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="news">News</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What tone are you expecting our team to take when posting on your behalf? Please check all that apply</h6>
                        <Form.Item name="tone" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="professional">Professional</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="inspiring">Inspiring</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="personal">Personal</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="creative">Creative</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="funny">Funny</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="tone" value="other" onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify" value={this.state.tone} name="tone" onChange={this.handleChange} disabled={!this.state.toneShow} />
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Which gender are you targeting? Please check all that apply *</h6>
                        <Form.Item name="targeting_gender" rules={[{ required: true, message: 'This field is required' }]}>
                            <Radio.Group>
                                <Row>
                                    <Col span={24} className="mt-3">
                                        <Radio value="male">Male</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="female">Female</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="other">Other</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Which age range are you targeting?</h6>
                        <Form.Item name="age_range" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What is the income of your target client? Please check all that apply? *</h6>
                        <Form.Item name="client_income" rules={[{ required: true, message: 'This field is required' }]}>
                            <Radio.Group style={{ width: '100%' }}>
                                <Row>
                                    <Col span={12} className="mt-3">
                                        <Radio value="0-50">$0 - 50K</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="100-150">$100K - 150K</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="50-100">$50K - 100K</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="150+">$150K+</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What is the education level of your target audience? Please check all that apply *</h6>
                        <Form.Item name="audience_education" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={24} className="mt-3">
                                        <Checkbox value="noCollege">No College</Checkbox>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Checkbox value="someCollege">Some College</Checkbox>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Checkbox value="graduateSchool">Graduate School</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Please tell us why potential clients/customers are more apt to choose your company & your product/service over those of your competitors? Please check all that apply *</h6>
                        <Form.Item name="priority_reason" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="betterPrice">Better Price</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="moreConvenient">More Convenient</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="betterService">Better Service</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="betterReputation">Better Reputation</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="companyMission">Company Mission</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="moreReliable">More Reliable</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="priority_reason" value="other" onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify" value={this.state.priority_reason} name="priority_reason" onChange={this.handleChange} disabled={!this.state.priority_reasonShow} />
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Who would you say are your biggest competitors? Please list at least 3 <span>(separate competitors with comma)</span></h6>
                        <Form.Item name="competitors" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What forms of marketing are you currently utilizing? Please check all that apply *</h6>
                        <Form.Item name="marketing_forms" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="none">None</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="printTv">Print TV and Radio</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="emailMarketing">Email Marketing</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="onlineMarketing">Online Marketing</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="socialMedia">Social Media</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="referral">Referral/Word of Mouth</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="directMail">Direct Mail</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="tradeShow">Trade Shows/Fairs</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="telemarketing">Telemarketing</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="marketing_forms" value="other" onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify" value={this.state.marketing_forms} name="marketing_forms" onChange={this.handleChange} disabled={!this.state.marketing_formsShow} />
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="row em-form-inner-btn em-btn-all position-sticky edit-footer">
                        {this.props.clearForm && <Button className="bg-transparent border-0 shadow-none w-auto" onClick={this.clearForm}>Clear</Button>}
                        {this.props.serviceType == "inner" && <Button className="mt-4" onClick={this.props.onClose}>Cancel</Button>}
                        <Button loading={this.state.buttonLoading} type="primary" htmlType="submit" size="large" className="d-inline mt-4 continue-button">SUBMIT</Button>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
} 