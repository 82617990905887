import React, { Component } from 'react';
import { Button, Drawer, message, Collapse, Form, Input, Upload } from 'antd';
import { Image as Images } from "../Images";
import { services, myServices } from '../../Controller/Global';
import WebsiteDesign from '../ProjectCreation/Services/WebsiteDesign';
import SearchEngineOptimization from '../ProjectCreation/Services/SearchEngineOptimize';
import EmailMarketing from '../ProjectCreation/Services/EmailMarketing';
import ContentGeneration from '../ProjectCreation/Services/ContentGeneration';
import IntegratingMarketing from '../ProjectCreation/Services/IntegratingMarketingStrategies';
import Retargeting from '../ProjectCreation/Services/Retargeting';
import SocialMediaMarketing from '../ProjectCreation/Services/SocialMediaMarketing';
import SearchEngineMarketing from '../ProjectCreation/Services/SearchEngineMarketing';
import { withRouter } from 'react-router-dom';
import { removeWebsiteDesign } from '../../Controller/API/ServiceAPI';
const { Panel } = Collapse;


class AddServiceDrawer extends Component {
    render() {
        const { projectId } = this.props.match.params.id
        const { editServiceDetail, servicesDetails } = this.props
        return (
            <React.Fragment>
                <Drawer
                    className="em-main-drawer em-address-drawer"
                    title={<div className="d-flex align-items-center justify-content-between w-100">
                        <div className="d-flex align-items-center edit-header">  View & Edit Brief  <span>{editServiceDetail.length && editServiceDetail[0].title}</span> </div>
                        <Button onClick={this.props.onClose} className="close-btn-drawer bg-transparent border-0 shadow-none p-0 d-flex align-items-center">Close <img alt="close icon" className="img-fluid ml-1" src={Images.close_icon_white} /></Button>
                    </div>}
                    placement="right"
                    closable={false}
                    maskClosable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    getContainer={false}
                    width={"50%"}
                    style={{ position: 'absolute' }}>
                    <div className="row em-drawer-main-row">
                        <div className="col-12">
                            <Form className="mt-4">
                                {editServiceDetail.map(_ => (_.model === services.WEBSITE_DESIGN_DEVELOPMENT) && <WebsiteDesign projectId={this.props.match.params.id} serviceType="inner" serviceDetail={servicesDetails[myServices[_.model]]} onClose={this.props.onClose} onSubmit={() => this.props.onSubmit(_.model)} />)}
                                {editServiceDetail.map(_ => (_.model === services.SEARCH_ENGINE_OPTIMIZATION) && <SearchEngineOptimization projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} serviceType="inner" onClose={this.props.onClose} onSubmit={() => this.props.onSubmit(_.model)} />)}
                                {editServiceDetail.map(_ => (_.model === services.EMAIL_MARKETING) && <EmailMarketing projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} serviceType="inner" onClose={this.props.onClose} onSubmit={() => this.props.onSubmit(_.model)} />)}
                                {editServiceDetail.map(_ => (_.model === services.CONTENT_GENERATION) && <ContentGeneration projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} serviceType="inner" onClose={this.props.onClose} onSubmit={() => this.props.onSubmit(_.model)} />)}
                                {editServiceDetail.map(_ => (_.model === services.INTEGRATING_ONLINE_MARKETING_STRATEGIES) && <IntegratingMarketing projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} serviceType="inner" onClose={this.props.onClose} onSubmit={() => this.props.onSubmit(_.model)} />)}
                                {editServiceDetail.map(_ => (_.model === services.RETARGETING) && <Retargeting projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} serviceType="inner" onClose={this.props.onClose} onSubmit={() => this.props.onSubmit(_.model)} />)}
                                {editServiceDetail.map(_ => (_.model === services.SOCIAL_MEDIA_MARKETING) && <SocialMediaMarketing projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} serviceType="inner" onClose={this.props.onClose} onSubmit={() => this.props.onSubmit(_.model)} />)}
                                {editServiceDetail.map(_ => (_.model === services.SEARCH_ENGINE_MARKETING) && <SearchEngineMarketing projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} serviceType="inner" onClose={this.props.onClose} onSubmit={() => this.props.onSubmit(_.model)} />)}
                            </Form>
                        </div>
                    </div>
                    {/* <div className="row em-form-inner-btn em-btn-all">
                        <div className="col-12 text-right">
                            <Button onClick={this.props.onClose}>Back</Button>
                            <Button type="primary" onClick={this.props.onClose}>Continue</Button>
                        </div>
                    </div> */}
                </Drawer>
            </React.Fragment >
        );
    }
}
export default withRouter(AddServiceDrawer)