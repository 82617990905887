import React, { Component } from 'react';
import { Drawer, Input, Button, Row, InputNumber, Form, message } from 'antd';
import './style.scss';
import { Image } from '../Images';

export default class PasswordChanged extends Component {
    formRef = React.createRef();
    onFinish = value => {
        this.props.onSuccess()
    }
    render() {
        return (
            <div className="verification-container passwordChanged">
                <Form onFinish={this.onFinish}>
                    <Drawer
                        className="em-main-drawer fp-modal lc-modal"
                        placement="right"
                        closable={false}
                        visible={this.props.visible}
                        getContainer={false}
                        width={"37%"}
                        style={{ position: 'absolute' }}
                    >
                        <div className="icon">
                            <img src={Image.success_icon} className="h-40" />
                        </div>
                        <h2 className="mt-4">Password Changed!</h2>
                        <p className="sub-heading">You can Sign In to you account</p>
                        <Row className="mt-2">
                            <Button type="primary" htmlType="submit" className="ml-3 send-button">LOG IN</Button>
                        </Row>
                    </Drawer>
                </Form>
            </div>
        )
    }
}