import Axios from 'axios';
import { history } from '../Controller/History'
import * as AuthService from '../Controller/AuthService'
import { getEnvValue } from "../Controller/Environment";



export const axios = Axios.create({
    baseURL: getEnvValue('API_URL')

});


axios.interceptors.response.use((response) => {
    return response
}, function (error) {
    console.log("error");
    const originalRequest = error.config;

    if (error.response && error.response.status === 403 && !originalRequest._retry) {

        originalRequest._retry = true;
        const refreshToken = AuthService.getRefreshToken();
        return axios.post('user/refresh',
            {
                "refresh": refreshToken
            })
            .then(async res => {
                if (res.status === 200) {
                    if (AuthService.getUserToken()) {
                        console.log("in if user token", AuthService.getUserToken())
                        await AuthService.setUserToken(res.data.access);
                        originalRequest.headers['Authorization'] = 'Bearer ' + AuthService.getUserToken();
                        console.log(originalRequest, 'Here');
                    } else {
                        await AuthService.setTempToken(res.data.access);
                        console.log("in temp else", AuthService.getTempToken())
                        originalRequest.headers['Authorization'] = 'Bearer ' + AuthService.getTempToken();
                        console.log(originalRequest, 'Here');
                    }
                    return axios(originalRequest);
                }
            }).catch(e => {
                console.log(e, 'refresh error');
                AuthService.clearUserToken();
                window.location.reload()
            })
    }
    return Promise.reject(error);
});

