import React, { Component } from 'react';
import { Button, Drawer, Form, Input, Radio, Slider, DatePicker, message } from 'antd';
import { Image as Images } from '../Images';
import moment from 'moment';
import { updateBudgetDetail, updateProjectInfo, setBudgetDetail } from '../../Controller/API/ProjectAPI';
import { withRouter } from "react-router-dom";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const timeline = ["48 Hours ( quick turnaround time fees typically apply)", "1 Month", "1 Week", "2 Weeks", "Not Sure. I'd like to chat with my designer"];

class EditProjectInfo extends Component {
    state = {
        range: [0, 0],
        pickerOpen: false,
        dateRange: "",
        buttonLoading: false
    };
    formRef = React.createRef();

    formatter(value) {
        return `$${value}`;
    }

    handleSlider = value => {
        this.setState({ range: value })
    };

    componentDidMount() {
        const { projectDetails } = this.props;
        if (projectDetails.budget) {
            if (!timeline.includes(projectDetails.budget.timeline)) {
                this.setState({ dateRange: projectDetails.budget.timeline })
            }
        }
        setTimeout(() => {
            this.formRef.current.setFieldsValue({
                name: projectDetails.name,
                description: projectDetails.description,
                timeline: projectDetails.budget && projectDetails.budget.timeline,
                range: projectDetails.budget && [JSON.parse(projectDetails.budget.range).lower, JSON.parse(projectDetails.budget.range).upper]
            });
            if (projectDetails.budget) {
                this.setState({ range: [JSON.parse(projectDetails.budget.range).lower, JSON.parse(projectDetails.budget.range).upper] })
            }
        }, 10)
    }

    onFinish = values => {
        this.setState({ buttonLoading: true });
        let projectId = this.props.match.params.id, budgetInfo = this.props.projectDetails.budget;
        let budget = {
            project: projectId,
            timeline: this.state.dateRange.length ? this.state.dateRange : values.timeline,
            range: JSON.stringify({ 'lower': this.state.range[0], 'upper': this.state.range[1] })
        };
        let profile = { name: values.name, description: values.description };
        updateProjectInfo(projectId, profile).then((response) => {
            if (budgetInfo) {
                updateBudgetDetail(budgetInfo.id, budget).then((budgetResp) => {
                    this.props.updateProjectDetail(response.data.name.charAt(0).toUpperCase() + response.data.name.slice(1));
                    this.props.onClose();
                    this.setState({ buttonLoading: false })
                }).catch((err) => {
                    if (err.response) {
                        Object.keys(err.response.data).map((i) => {
                            message.error(err.response.data[i])
                        });
                        this.setState({ buttonLoading: false })
                    } else {
                        message.error("something went wrong");
                        this.setState({ buttonLoading: false })
                    }
                })
            } else {
                setBudgetDetail(budget).then((budgetResp) => {
                    this.props.updateProjectDetail(response.data.name);
                    this.props.onClose();
                    this.setState({ buttonLoading: false })
                }).catch((err) => {
                    if (err.response) {
                        Object.keys(err.response.data).map((i) => {
                            message.error(err.response.data[i])
                        });
                        this.setState({ buttonLoading: false })
                    } else {
                        message.error("something went wrong");
                        this.setState({ buttonLoading: false })
                    }
                })
            }

        })
    };
    handleChange = (date) => {
        let dateRange = `${moment(date[0]['_d']).format('YYYY-MM-DD')} - ${moment(date[1]['_d']).format('YYYY-MM-DD')}`;
        this.formRef.current.setFieldsValue({ 'timeline': dateRange });
        this.setState({ dateRange, pickerOpen: false })
    };
    handleRadio = (e) => {
        let value = e.target.value;
        this.setState({ dateRange: "", pickerOpen: (value == "customDate" && !this.state.pickerOpen) && true })
    };

    render() {
        console.log(this.props.projectDetails.budget, 'datrange', this.state.dateRange, 'range', this.state.range);
        const { range, pickerOpen } = this.state;
        return (
            <React.Fragment>
                <Drawer
                    className="em-main-drawer em-address-drawer edit-project-main"
                    title={
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div>Edit Project Info</div>
                            <Button onClick={this.props.onClose}
                                className="close-btn-drawer bg-transparent border-0 shadow-none p-0 d-flex align-items-center">Close <img
                                    alt="close icon" className="img-fluid ml-1" src={Images.close_icon_white} /></Button>
                        </div>
                    }
                    placement="right"
                    closable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    getContainer={true}
                    width={"40%"}
                >
                    <div className="row em-drawer-main-row">
                        <div className="col-12">
                            <Form onFinish={this.onFinish} ref={this.formRef}>
                                <div className="row em-form-inner-row">
                                    <div className="col-12">
                                        <Form.Item name="name" label={"Name your project"}
                                            className={"group-floating-label"} rules={[{ required: true, message: 'This field is required' }]} >
                                            <Input placeholder="Name your project" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="description" label={"Describe your project"}
                                            className={"group-floating-label"} rules={[{ required: true, message: 'This field is required' }]}>
                                            <TextArea className="textAreaInput" placeholder="Describe your project"
                                                rows={5} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="timeline" label={"What’s your timeline? *"}
                                            className={"group-floating-label"}
                                            rules={[{ required: true, message: 'This field is required' }]}>
                                            <Radio.Group onChange={this.handleRadio} className="radio-check w-100"
                                                value={this.props.projectDetails.budget && this.props.projectDetails.budget.timeline}
                                                size="large">
                                                <Radio.Button className="d-block mt-2"
                                                    value="48 Hours ( quick turnaround time fees typically apply)">48
                                                    Hours ( quick
                                                    turnaround time fees typically apply)</Radio.Button>
                                                <Radio.Button className="d-block mt-4" value="1 Week">1
                                                    Week</Radio.Button>
                                                <Radio.Button className="d-block mt-4" value="2 Weeks">2
                                                    Weeks</Radio.Button>
                                                <Radio.Button className="d-block mt-4" value="1 Month">1
                                                    Month</Radio.Button>
                                                <Radio.Button className="d-block mt-4"
                                                    value={this.state.dateRange ? this.state.dateRange : "customDate"}
                                                    onClick={() => this.setState({ pickerOpen: !pickerOpen })}>Set
                                                    Start and end date<label
                                                        className="ml-4 mb-0">{this.state.dateRange}</label>
                                                    <RangePicker
                                                        allowClear={true} className="invisible" open={pickerOpen}
                                                        onChange={(e) => {
                                                            this.handleChange(e);
                                                        }} /></Radio.Button>
                                                <Radio.Button className="d-block mt-4"
                                                    value="Not Sure. I'd like to chat with my designer">Not
                                                    sure. I’d like
                                                    to chat with my designer</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item label={"What’s your budget?"}
                                            className={"group-floating-label"}>
                                            <Input disabled placeholder={range.map(i => '$' + i).join(' - ')}
                                                value={range.map(i => '$' + i).join(' - ')} />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <Form.Item className={"group-floating-label"} name="range"
                                            rules={[{ required: true, message: 'This field is required' }]}>
                                            <Slider range min={0} onChange={this.handleSlider} className="range-slider"
                                                tooltipVisible tipFormatter={this.formatter} max={3000} />
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="row em-form-inner-btn em-btn-all position-sticky">
                                    <div className="col-12 text-right">
                                        <Button onClick={this.props.onClose}>Cancel</Button>
                                        <Button loading={this.state.buttonLoading} type="primary"
                                            htmlType="submit">Save</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default withRouter(EditProjectInfo);