import { getAPIUrl } from '../Global';
import { Post, Get, Patch } from "../headerIntercepter";
import { setTempToken, setTempRefreshToken, clearTempToken } from '../AuthService';

export function userRegister(data) {
    const url = getAPIUrl('user.register');
    return Post(url, data, false)
        .then(response => {
            setTempToken(response.data.access_token);
            setTempRefreshToken(response.data.access_token)
        })
}

export function userSource(data) {
    const url = getAPIUrl('user.source');
    return Get(url, data, false)
}

export function userIndustry(data) {
    const url = getAPIUrl('user.industry');
    return Get(url, data, false)
}

export function userLogin(data) {
    clearTempToken()
    const url = getAPIUrl('user.login');
    return Post(url, data, false)
}

export function getUserProfile(data) {
    const url = getAPIUrl('user.profile')
    return Get(url, data)
}

export function postForgetPassword(data) {
    const url = getAPIUrl('user.forgetPassword');
    return Post(url, data, false)
}

export function postVerificationCode(data) {
    const url = getAPIUrl('user.verifyCode');
    return Post(url, data, false)
}

export function postResetPassword(data) {
    const url = getAPIUrl('user.resetPassword');
    return Post(url, data, false)
}

export function updatePassword(data) {
    const url = getAPIUrl('user.password')
    return Patch(url, data)
}

export function updateUserProfile(data) {
    const url = getAPIUrl('user.profile')
    return Patch(url, data)
}