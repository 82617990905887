import React, { useState } from 'react';
import { Layout, Input, Form, Button, message } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import './style.scss';
import { Image as Images } from '../Images';
import { Link } from "react-router-dom";
import { setSubscriber } from '../../Controller/API/ProjectAPI'

const { Footer } = Layout;
const { Search } = Input;


export const CustomFooter = () => {
    const [emailErr, setEmailErr] = useState(false)
    const [loading, setLoading] = useState(false)

    function onFinish(value) {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(value) == false) {
            setEmailErr(true)
        } else {
            let data = { email: value }
            setEmailErr(false)
            setLoading(true)
            setSubscriber(data).then((response) => {
                setLoading(false)
                document.getElementById('emailSubscribe').value = ''
                message.success('You have subscribed successfully')
            }).catch((err) => {
                setLoading(false)
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
        }
    }
    return (
        <Footer className="custom-footer responsive-footer">
            <div className="container-fluid cf-container">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 address">
                            <img src={Images.footer_logo} className="img footer-logo" />
                            {/* <h2 className="heading"><span className="font-weight-bold">Erdos</span> Marketing</h2> */}
                            <p className="cf-address pt-2">2125 Center Avenue, Suite 400 Fort Lee, NJ 07024
                            contact@erdosdigital.com
                                <a href="https://erdosdigital.com/" target="_blank">www.erdosdigital.com</a></p>
                            <p>Call us <span className="d-block">646 - 540 - 7241</span></p>
                        </div>
                        <div className="col-md-6 col-sm-12 services-right">
                            <h2 className="heading">Services Provided</h2>
                            <div className="row m-0">
                                <div className="col-md-6 p-0">
                                    <ul className="list-inline mb-0">
                                        <li>
                                            <Link to={''}>
                                                <CaretRightOutlined className="mr-2" />
                                                Web Design & Development
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={''}>
                                                <CaretRightOutlined className="mr-2" />
                                                SEO Services
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={''}>
                                                <CaretRightOutlined className="mr-2" />
                                                Search Engine Marketing
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={''}>
                                                <CaretRightOutlined className="mr-2" />
                                                Social Media Marketing
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 p-0 ">
                                    <ul className="list-inline mb-0">
                                        <li>
                                            <Link to={''}>
                                                <CaretRightOutlined className="mr-2" />
                                                Retargeting
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={''}>
                                                <CaretRightOutlined className="mr-2" />
                                                Content Generation
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={''}>
                                                <CaretRightOutlined className="mr-2" />
                                                Integrated Online Marketing Strategies
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row em-footer-row-2">
                        <div className="col-md-6">
                            <div className="cf-social-icons">
                                <ul className="list-inline mb-0">
                                    <li className="list-inline-item">
                                        <Link to={''}>
                                            <img className="img-fluid" alt="" src={Images.twitter_icon} />
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to={''}>
                                            <img className="img-fluid" alt="" src={Images.facebook_icon} />
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to={''}>
                                            <img className="img-fluid" alt="" src={Images.instagram_icon} />
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to={''}>
                                            <img className="img-fluid" alt="" src={Images.linkdin_icon} />
                                        </Link>
                                    </li>
                                </ul>
                                <label className="d-block mt-3 pb-4">Visit <a href="https://erdostechnologies.com/" target="_blank">ErdosTechnologies.com</a></label>
                                <p className="mt-5 right-reserved">© 2020 Erdos Technologies. All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="col-md-6 news-letter">
                            <h3>Email Newsletters!</h3>
                            <div className="position-relative">
                                <Search
                                    placeholder="Your Email Address"
                                    enterButton="SIGN UP"
                                    size="middle"
                                    id="emailSubscribe"
                                    onSearch={value => onFinish(value)}
                                    loading={loading}
                                />
                                {emailErr ? <span className="form-error">Please input valid email</span> : ""}
                            </div>
                            <label className="mt-4">Sign up for updates, surveys & offers.</label>
                        </div>
                    </div>
                </div>
            </div>
        </Footer>
    )
};