import React, { Component } from 'react';
import { Input, Radio, Row, Col, Form, Button, message } from 'antd';
import { postSearchEngineMarketing, updateSearchEngineMarketing } from '../../../Controller/API/ServiceAPI';


export default class SearchEngineMarketing extends Component {
    state = {
        userDetails: {},
        buttonLoading: false
    };
    formRef = React.createRef();

    componentDidMount() {
        const { serviceDetail } = this.props;
        if (serviceDetail) {
            this.formRef.current.setFieldsValue({
                monthly_budget_target: serviceDetail.monthly_budget_target,
                visitor_action: serviceDetail.visitor_action,
                action_value: serviceDetail.action_value,
                action_count: serviceDetail.action_count,
                ads_language: serviceDetail.ads_language,
                advertising_goal: serviceDetail.advertising_goal,
                budget_restriction: serviceDetail.budget_restriction,
                account_result_frequency: serviceDetail.account_result_frequency,
                adwords_advertise: serviceDetail.adwords_advertise,
                product_service_keyword: serviceDetail.product_service_keyword,
                campaign_keyword: serviceDetail.campaign_keyword,
                trademark: serviceDetail.trademark,
                competitor_websites: serviceDetail.competitor_websites,
                adword_account_maintainer: serviceDetail.adword_account_maintainer,
                approval_level: serviceDetail.approval_level,
                website_updates_maintainer: serviceDetail.website_updates_maintainer,
                leads_phone: serviceDetail.leads_phone,
            })
        }
    }

    onFinish = values => {
        this.setState({ buttonLoading: true });
        let userDetails = { ...this.state.userDetails };
        values.project = this.props.projectId;
        if (userDetails.id || this.props.serviceDetail) {
            let id = userDetails.id ? userDetails.id : this.props.serviceDetail.id;
            updateSearchEngineMarketing(id, values).then((response) => {
                this.props.onSubmit();
                if (!this.props.serviceType) message.success("Details Updated Successfully");
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    });
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong");
                    this.setState({ buttonLoading: false })
                }
            })
        } else {
            postSearchEngineMarketing(values).then((response) => {
                this.props.onSubmit();
                this.setState({ userDetails: response.data });
                if (!this.props.serviceType) message.success('Form Submitted');
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    });
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong");
                    this.setState({ buttonLoading: false })
                }
            })
        }
    };

    render() {
        return (
            <React.Fragment>
                <Form onFinish={this.onFinish} ref={this.formRef}>
                    <div className="seperator m-0">
                        <h5>Business Goals</h5>
                    </div>
                    <div className="f-row">
                        <h6>What is your target monthly budget to spend in Paid Search?</h6>
                        <Form.Item name="monthly_budget_target"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Landing page for my food truck’s website" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What actions do you want visitors to take from advertising?</h6>
                        <Form.Item name="visitor_action" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What is the value of each of these actions?</h6>
                        <Form.Item name="action_value" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How many of these actions are you looking for each month?</h6>
                        <Form.Item name="action_count" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Where and when should your ads be running? What languages?</h6>
                        <Form.Item name="ads_language" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="seperator">
                        <h5>Strategy Questions</h5>
                    </div>
                    <div className="f-row mt-4">
                        <h6> Which of these goals best describes your approach to advertising? </h6>
                        <Form.Item name="advertising_goal"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Radio.Group>
                                <Row>
                                    <Col span={24} className="mt-3">
                                        <Radio value="budget">Maximize the total number of actions taken for the
                                            budget</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="topposition">Show my brand in top positions whenever
                                            possible</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="goal">Profit is our goal – deliver the most profitable
                                            clicks/highest amount of revenue possible</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How aggressively do you want to approach budget restrictions? If profitable clicks are
                            available, should budget be increased?</h6>
                        <Form.Item name="budget_restriction"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How frequently do you want to be updated with account results?</h6>
                        <Form.Item name="account_result_frequency"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Radio.Group>
                                <Row>
                                    <Col span={24} className="mt-3">
                                        <Radio value="weekly">Weekly</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="monthly">Monthly</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="quarterly">Quarterly</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="seperator">
                        <h5>Strategy Questions</h5>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Have you ever advertised with AdWords before?</h6>
                        <Form.Item name="adwords_advertise"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Are you aware of any keywords related to your products/services that draw in customers?</h6>
                        <Form.Item name="product_service_keyword"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Are there any keywords we should avoid in our campaigns?</h6>
                        <Form.Item name="campaign_keyword"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Are there any trademarks, copyrights or other information we should know about when writing
                            ads?</h6>
                        <Form.Item name="trademark" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="seperator">
                        <h5>Competitor Analysis</h5>
                    </div>
                    <div className="f-row mt-4">
                        <h6>List your top 3-5 competitors and their websites
                            here <span>(separate websites with comma)</span></h6>
                        <Form.Item name="competitor_websites"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="seperator">
                        <h5>Account Questions</h5>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Who should we be contacting with questions/concerns about your Google AdWords Account?</h6>
                        <Form.Item name="adword_account_maintainer"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What level of approval is needed for adding keywords and ads to the account?</h6>
                        <Form.Item name="approval_level" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Who do we talk to about getting updates to your website?</h6>
                        <Form.Item name="website_updates_maintainer"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. www.yourdomainname.com" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Do you want to generate phone call leads/sales opportunities? If so, what phone number
                            should receive these leads?</h6>
                        <Form.Item name="leads_phone" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 123-456-7890" />
                        </Form.Item>
                    </div>
                    <div className="row em-form-inner-btn em-btn-all position-sticky edit-footer">
                        {this.props.clearForm && <Button className="bg-transparent border-0 shadow-none w-auto" onClick={() => this.formRef.current.resetFields()}>Clear</Button>}
                        {this.props.serviceType == "inner" &&
                            <Button className="mt-4" onClick={this.props.onClose}>Cancel</Button>}
                        <Button loading={this.state.buttonLoading} type="primary" htmlType="submit" size="large"
                            className="d-inline mt-4 continue-button">SUBMIT</Button>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
}