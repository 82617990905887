import React, { Component } from 'react';
import { Drawer, Input, Button, Row, InputNumber, Form, message } from 'antd';
import './style.scss';
import { postVerificationCode } from '../../Controller/API/UserAPI';
import ResetPassword from '../ResetPassoword';


export default class Verification extends Component {
    state = {
        resetPassword: false,
        device: "",
        verificationCodeErr: false,
        loading: false
    }
    formRef = React.createRef();
    onSuccess = () => {
        this.props.onSuccess()
        this.handleResetPassword()
    }
    handleResetPassword = () => {
        this.setState({ resetPassword: !this.state.resetPassword })
    }
    onFinish = value => {
        let arr = Object.values(value)
        arr = arr.filter(e => e)
        if (arr.length === 6) {
            this.setState({ verificationCodeErr: false, loading: true })
            let data = {
                code: Object.values(value).join(''),
                email: this.props.verificationDetail.email
            }
            postVerificationCode(data).then((response) => {
                this.formRef.current.resetFields();
                this.handleResetPassword()
                this.setState({ device: response.data.device, loading: false })
            }).catch((err) => {
                this.setState({ loading: false })
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
        }
        else {
            this.setState({ verificationCodeErr: true })
        }
    }
    handleChange = (e) => {
        var allInput = document.querySelectorAll('.inputcode')
        const form = e.target.form;
        const index = Array.prototype.indexOf.call(form, e.target);
        form.elements[index + 1].focus()
        for (let i = 0; i < allInput.length; i++) {
            if (allInput[i].value !== "") {
                this.setState({ verificationCodeErr: false })
            }
        }
    }
    render() {
        return (
            <div className="verification-container">
                <Form onFinish={this.onFinish} ref={this.formRef}>
                    <Drawer
                        className="em-main-drawer fp-modal lc-modal"
                        placement="right"
                        closable={false}
                        onClose={this.props.onClose}
                        visible={this.props.visible}
                        getContainer={false}
                        width={"37%"}
                        style={{ position: 'absolute' }}
                    >

                        <h2>Verification</h2>
                        <p className="sub-heading">Please enter the verification code we have sent to your registered email or <span className="text-primary">request a new code.</span></p>
                        <div className="vc-input text-center">
                            <Form.Item name="1">
                                <Input maxLength={1} className="inputcode" onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item name="2">
                                <Input maxLength={1} className="ml-4 r-ml-10 inputcode" onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item name="3">
                                <Input className="ml-4 r-ml-10 inputcode" maxLength={1} onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item name="4">
                                <Input className="ml-4 r-ml-10 inputcode" maxLength={1} onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item name="5">
                                <Input className="ml-4 r-ml-10 inputcode" maxLength={1} onChange={this.handleChange} />
                            </Form.Item>
                            <Form.Item name="6">
                                <Input className="ml-4 r-ml-10 inputcode" maxLength={1} onChange={this.handleChange} />
                            </Form.Item >
                        </div>
                        {this.state.verificationCodeErr && <span className="form-error">Please Input Valid OTP!</span>}
                        <Row className="mt-5">
                            <Button type="text" className="w-25 cancel-button" onClick={this.props.onClose}>BACK</Button>
                            <Button type="primary" loading={this.state.loading} htmlType="submit" className="ml-3 send-button">NEXT</Button>
                        </Row>
                    </Drawer>
                </Form>
                <ResetPassword device={this.state.device} onSuccess={this.onSuccess} onClose={this.handleResetPassword} visible={this.state.resetPassword} />
            </div>
        )
    }
}