import React, { Component } from 'react';
import './style.scss'
import { Button, Tabs } from "antd";
import { Image as Images } from "../Images";
import AccountInfoMain from "./AccountInfoMain";
import PasswordSecurity from "./PasswordSecurity";
import BillingInfoMain from "./BillingInfoMain";
import ViewAccountBreadcrumb from "./ViewAccountBreadcrumb";
import { withRouter } from "react-router-dom";
import Routes from "../../Controller/Routes";
import { logout } from "../../Controller/AuthService";
import { connect } from 'react-redux';
import { updateUser } from '../../redux/Actions/userActions';

const { TabPane } = Tabs;


class ViewAccount extends Component {
    state = {
        activeTab: "1"
    };
    changeTab = (key) => {
        this.setState({ activeTab: key })
    };

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid account-main project-contain-main em-main-container-fluid em-container-fluid-extra">
                    <div className="container">
                        <div className="row m-0 em-main-row-all align-items-center">
                            <div className="col-12 r-pad-0">
                                <div className="row m-0 em-heading-breadcrumb-main">
                                    <div className="col-12 r-pad-0 col-sm-3 account">
                                        <h6>Account</h6>
                                        <h5 className="t-capitalize">{this.props.user.user && (this.props.user.user.name || "")}</h5>
                                    </div>
                                    <div className="col-12 r-pad-0 col-sm-9 breadcrump">
                                        <ViewAccountBreadcrumb activeTab={this.state.activeTab} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row  em-tabs-details-main em-tabs-details-main-2 user-account-container">
                                    <Tabs className="w-100" onChange={this.changeTab} type="card">
                                        <TabPane tab={
                                            <div className="d-flex align-items-center">
                                                <div className="tabs-icon-div">
                                                    <img src={Images.account_info} alt="account info icon"
                                                        className="img-fluid" />
                                                </div>
                                                <div className="text-div">Account Info</div>
                                            </div>
                                        } key="1">
                                            <AccountInfoMain user={this.props.user} updateUser={this.props.updateUser} />
                                        </TabPane>

                                        <TabPane tab={
                                            <div className="d-flex align-items-center">
                                                <div className="tabs-icon-div">
                                                    <img src={Images.password_key} alt="password icon"
                                                        className="img-fluid" />
                                                </div>
                                                <div className="text-div">Password & Security</div>
                                            </div>
                                        } key="2">
                                            <PasswordSecurity />
                                        </TabPane>
                                        <TabPane tab={
                                            <div className="d-flex align-items-center">
                                                <div className="tabs-icon-div">
                                                    <img src={Images.billing_info} alt="billing icon"
                                                        className="img-fluid" />
                                                </div>
                                                <div className="text-div">Billing info</div>
                                            </div>
                                        } key="3">
                                            <BillingInfoMain />
                                        </TabPane>
                                        <TabPane tab={
                                            <div onClick={(e) => {
                                                e.preventDefault();
                                                this.props.history.push(Routes.logout)
                                            }} className="d-flex align-items-center">
                                                <div className="tabs-icon-div">
                                                    <img src={Images.logout_key_icon} alt="logout icon"
                                                        className="img-fluid" />
                                                </div>
                                                <div className="text-div">Logout</div>
                                            </div>
                                        }>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
};
export default connect(mapStateToProps, { updateUser })(withRouter(ViewAccount));