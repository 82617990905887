import React, { Component } from 'react';
import './style.scss'
import { ProjectCreationTop } from './projectCreationTop';
import { CustomFooter } from '../Layout/CustomFooter';
import { Layout, Menu, message } from 'antd';
import AccountInfo from './AccountInfo';
import ProjectInfo from './ProjectInfo';
import YourServices from './YourServices';
import TimeBudget from './TimeBudget';
import AddServiceModal from '../modals/AddService';
import { connect } from 'react-redux';
import { ProjectSuccessModal } from '../modals/projectSuccess';
import { step, myServices } from '../../Controller/Global';
import { toggleLoginAction } from "../../redux/Actions/userActions";
import { clearTempToken, clearUserToken, isLoggedIn } from '../../Controller/AuthService';
import routes from '../../Controller/Routes';
import { Image } from '../Images';
import { getProjectDetails } from '../../Controller/API/ServiceAPI';
import { isEmpty } from 'lodash';

const { SubMenu } = Menu;
const { Content, Sider } = Layout;

class ProjectCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: step.ACCOUNT_INFO,
            serviceModal: false,
            projectId: null,
            projectDetails: {},
            projectCreateSuccess: false,
            serviceDetail: {}
        }
        if (window.performance) {
            if (performance.navigation.type === 1) {
                this.props.toggleLoginAction(false)
            }
        }
        clearTempToken()
        this.child = React.createRef();
    }
    componentDidMount() {
        if (isLoggedIn() && this.props.location.pathname !== routes.dashboard.projectCreation) {
            this.props.history.push(routes.dashboard.self)
            return;
        }
        // if (this.props.location.state && this.props.location.state.login) {
        //     this.props.toggleLoginAction(true)
        // }
        if (this.props.location.state && this.props.location.state.addProject) {
            this.setState({ steps: step.PROJECT_INFO })
        }
    }
    handleProcess = (process) => {
        let element = document.getElementById("formMain");
        element.scrollIntoView();
        this.setState({ steps: process })
    };
    //get projectDetails
    projectDetails = () => {
        getProjectDetails(this.state.projectId, "").then((response) => {
            this.setState({ serviceDetail: response.data })
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    }
    addServiceModal = () => {
        this.projectDetails()
        this.setState({ serviceModal: !this.state.serviceModal })
    };
    handleSelectedService = (project) => {
        this.setState({ projectId: project.id, projectDetails: project }, () => {
            this.projectDetails()
        })
    };
    projectCreated = () => {
        this.setState({ projectCreateSuccess: !this.state.projectCreateSuccess })
    }
    onSubmit = () => {
        this.handleProcess(step.ACCOUNT_INFO)
        this.projectCreated()
        if (this.props.location.pathname !== "/") {
            this.props.history.push(routes.dashboard.projects.self)
        }
        else {
            clearTempToken()
        }
    }
    componentWillUnmount() {
        clearTempToken()
    }
    handleServices = (index) => {
        this.child.current.handleServiceClick(index)
    }
    render() {
        const { steps, projectCreateSuccess, serviceDetail } = this.state;
        const { addProject } = this.props.location.state || false
        return (
            <React.Fragment>
                <div className="container-fluid p-0">
                    {!addProject && <ProjectCreationTop />}
                    <div className="project-creation-container project-create-responsive">
                        <Content>
                            <div className="row m-0">
                                <div className="col-md-3 col-12 r-padl-0 col-lg-2">
                                    <div className="sidebarProgress">
                                        {!addProject && <div className={steps === step.PROJECT_INFO || steps === step.YOUR_SERVICE || steps === step.TIME_BUDGET ? "sp-menu-item active" : steps === step.ACCOUNT_INFO ? "sp-menu-item bgActive" : "sp-menu-item"}><img className="h-17 mr-2" src={Image.account_tie} /><h4 className="mb-0 mr-2">ACCOUNT INFO</h4><img className="success" src={Image.success_icon} /></div>}
                                        <div className={steps === step.YOUR_SERVICE || steps === step.TIME_BUDGET ? "sp-menu-item active" : steps === step.PROJECT_INFO ? "sp-menu-item bgActive" : "sp-menu-item"}><img className="h-17 mr-2" src={Image.project_info} /><h4 className="mb-0 mr-2">PROJECT INFO</h4><img className="success" src={Image.success_icon} /></div>
                                        <div className={steps === step.TIME_BUDGET ? "sp-menu-item active " : steps === step.YOUR_SERVICE ? "sp-menu-item bgActive" : "sp-menu-item"}><img className="h-17 mr-2" src={Image.services_icon} /><h4 className="mb-0 mr-2">YOUR SERVICES</h4><img className="success" src={Image.success_icon} />
                                        </div>
                                        <div className={steps === step.YOUR_SERVICE ? "spmi-drop w-100" : "d-none"}>
                                            <div className="subMenu">
                                                {console.log(serviceDetail)}
                                                {!isEmpty(serviceDetail) && serviceDetail.services.map((_, key) => (
                                                    <div className="sm-item cursor-pointer" onClick={() => this.handleServices(key)}>
                                                        <img className="h-17 mr-2" src={_.icon} /><h5 className="mb-0 mr-2">{_.title}</h5>{!isEmpty(serviceDetail[myServices[_.model]]) ? <img className="h-17 mr-1 cursor-pointer deleteServiceIcon" data-id={serviceDetail[myServices[_.model]] && serviceDetail[myServices[_.model]].id} id={_.model} onClick={(e) => this.child.current.removeService(e)} src={Image.delete_icon} /> : ""}
                                                    </div>
                                                ))}
                                                <div className="sm-item cursor-pointer" onClick={this.addServiceModal}>
                                                    <img className="h-14 mr-2" src={Image.add_icon} /><h5 className="mb-0 mr-2 ">Add services</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={steps === step.TIME_BUDGET ? "sp-menu-item bgActive" : "sp-menu-item"}><img className="h-17 mr-2" src={Image.time_budget} /><h4 className="mb-0 mr-2">TIME AND BUDGET</h4><img className="success" src={Image.success_icon} /></div>
                                    </div>
                                </div>
                                <div className="col-md-9 col-lg-10 col-12 r-padl-0">
                                    <div className="right-content-form pl-4" id="formMain">
                                        {steps === step.ACCOUNT_INFO && !addProject ? <AccountInfo handleProcess={this.handleProcess} />
                                            : steps === step.PROJECT_INFO ?
                                                <ProjectInfo handleProcess={this.handleProcess}
                                                    handleSelectedService={this.handleSelectedService} projectId={this.state.projectId} />
                                                : steps === step.YOUR_SERVICE && !isEmpty(serviceDetail) ?
                                                    <YourServices serviceDetail={this.state.serviceDetail} ref={this.child}
                                                        projectId={this.state.projectId}
                                                        handleProcess={this.handleProcess} projectDetails={this.projectDetails} />
                                                    : steps === step.TIME_BUDGET ? <TimeBudget handleProcess={this.handleProcess}
                                                        projectId={this.state.projectId} projectCreated={this.projectCreated} /> : ""}
                                    </div>
                                </div>
                            </div>
                        </Content>
                    </div>
                    <CustomFooter />
                    {this.state.serviceModal && <AddServiceModal addNewService={true} projectDetails={this.state.projectDetails} selectedService={!isEmpty(serviceDetail) && serviceDetail.services} visible={this.state.serviceModal} onClose={this.addServiceModal} />}
                    <ProjectSuccessModal visible={projectCreateSuccess} onSubmit={this.onSubmit} projectDetails={this.state.projectDetails} />
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return { user: state.user }
};
export default connect(mapStateToProps, { toggleLoginAction })(ProjectCreation)