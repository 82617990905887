import React, { Component } from 'react';
import { Button, message } from "antd";
import { Image as Images } from "../Images";
import { Link } from "react-router-dom";
import EditProjectInfo from "../Drawers/EditProjectInfo";
import { ProjectDetailHead } from './ProjectDetailHead';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { SuccessPopup } from '../modals/SuccessPopup';


class ProjectInfoMain extends Component {

    state = {
        editProjectInfoVisible: false,
        projectDetails: {},
        success: { show: false, project: "" }
    };
    showEditProjectInfoDrawer = (visible) => {
        this.setState({
            editProjectInfoVisible: visible,
        })
    };
    updateProjectDetail = (project) => {
        this.setState({ success: { ...this.state.success, show: true, project } })
        this.props.getProjectDetails()
    }
    handlePopUp = () => {
        this.setState({ success: { ...this.state.success, show: false } })
    }
    render() {
        const { projectDetails } = this.props
        return (
            <React.Fragment>
                <div className="col-12 r-pad-0">
                    <div className="row m-0 em-service-table-card bg-transparent shadow-none em-table-invoices-card">
                        <div className="col-12 p-0">
                            <div className="row billing-info-row-heading">
                                <ProjectDetailHead serviceDetail={projectDetails} content={false} />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 r-pad-0">
                                    <div className="row card em-card-custom">
                                        <div className="col-12">
                                            <div className="row mx-0 em-card-inner-details pb-0 border-0">
                                                <div className="col-12">
                                                    <h5>Project Info</h5>
                                                </div>
                                                <div className="col-12 col-sm-3 details">
                                                    <h6>Created</h6>
                                                    <p className="mb-0">{projectDetails.created && moment(projectDetails.created).format('ll')}</p>
                                                </div>
                                                <div className="col-12 col-sm-3 details">
                                                    <h6>Total Services</h6>
                                                    <p className="mb-0">{projectDetails.services && projectDetails.services.length} <Link onClick={() => this.props.changeTab("myServices")} className="text-uppercase">View</Link></p>
                                                </div>
                                                <div className="col-12 col-sm-3 details">
                                                    <h6>Due Date</h6>
                                                    <p className="mb-0">-</p>
                                                </div>
                                            </div>

                                            <div className="row mx-0 em-card-inner-details">
                                                <div className="col-12 col-sm-3 details">
                                                    <h6>Time</h6>
                                                    <p className="mb-0">{projectDetails.budget ? projectDetails.budget.timeline : "-"}</p>
                                                </div>
                                                <div className="col-12 col-sm-3 details">
                                                    <h6>Budget</h6>
                                                    <p className="mb-0">{projectDetails.budget ? `$ ${JSON.parse(projectDetails.budget.range).lower || "0"} - ${JSON.parse(projectDetails.budget.range).upper || "0"}` : "-"}</p>
                                                </div>
                                                <div className="col-12 col-sm-3 details">
                                                    <h6>Total Paid</h6>
                                                    <p className="mb-0">-</p>
                                                </div>
                                                <div className="col-12 col-sm-3 details">
                                                    <h6>Open Balance</h6>
                                                    <p className="mb-0">-</p>
                                                </div>
                                            </div>

                                            <div className="row mx-0 em-card-inner-details">
                                                <div className="col-12">
                                                    <h6 className="mb-2 pb-1">Project description</h6>
                                                    <p className="t-capitalize">{projectDetails.description}</p>
                                                </div>
                                                <div className="col-12">
                                                    <Button onClick={() => this.showEditProjectInfoDrawer(true)}
                                                        className="d-flex align-items-center justify-content-center">
                                                        <img src={Images.edit_icon} alt=""
                                                            className="img-fluid" />
                                                        Edit Project Info
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.editProjectInfoVisible && <EditProjectInfo visible={this.state.editProjectInfoVisible} projectDetails={projectDetails} onClose={() => this.showEditProjectInfoDrawer(false)} updateProjectDetail={this.updateProjectDetail} />}
                {this.state.success.show && <SuccessPopup message={`${this.state.success.project} Project Info has been updated!`} onClose={this.handlePopUp} />}
            </React.Fragment>
        );
    }
}
export default withRouter(ProjectInfoMain);
