import React from 'react';
import { Link } from "react-router-dom";
import Routes from "../../Controller/Routes";

export const ProjectCreationTop = () => {
    return (
        <div className="header-main">
            <div className="container mt-5 pc-header position-relative z-index2">
                <div className="row m-0 text-center pc-after">
                    <div className="col-md-4 col-sm-12">
                        <img alt="" src={require('../../assets/select-service.svg')} />
                        <div className="pch-content">
                            <h3>Select services and tell us about your project.</h3>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                            consequat dui</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <img alt="" src={require('../../assets/our-team.svg')} />
                        <div className="pch-content">
                            <h3>Fast and efficiently communicate to our team.</h3>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                            consequat duis</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                        <img alt="" src={require('../../assets/track-project.svg')} />
                        <div className="pch-content">
                            <h3>Easy to track and view your projects.</h3>
                            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                            consequat duis</p>
                        </div>
                    </div>
                    <div className="col-md-12 pch-contact mt-5">
                        <label>You can contact us at any time in the process <Link
                            to={Routes.dashboard.self}>here</Link>.</label>
                        <img alt="" src={require('../../assets/contact.svg')} />
                    </div>
                </div>
            </div>
        </div>
    )
};