import React, { Component } from 'react';
import './style.scss'
import { Button, Table, Tabs, message, Pagination } from "antd";
import { Image as Images } from "../Images";
import { withRouter } from "react-router-dom";
import { getInvoice } from '../../Controller/API/ProjectAPI';
import { filterData } from '../../Controller/helper';
import { status } from '../../Controller/Global';
import moment from 'moment';

const { TabPane } = Tabs;


const pagination = Pagination;
pagination.pageSize = 10;

class Invoices extends Component {
    state = {
        allInvoioce: [],
        pagination: pagination,
        loading: true,
        activeTab: "all"
    };
    columns = [
        {
            title: <div>Invoice</div>,
            dataIndex: 'id',
            render: (id) => <div className="d-flex align-items-center">
                <span className="icon-img">
                    <img alt="folder web icon" className="img-fluid" src={Images.invoice_icon} />
                </span>
                <div className="text-div-inn">
                    {id}
                </div>
            </div>

        },
        {
            title: <div className="d-flex align-items-center align-items-center">Date Created <img
                src={Images.arrow_down} alt="arrow down" className="img-fluid ml-1" /></div>,
            dataIndex: 'created',
            render: (date) => moment(date).format('ll')
        },
        {
            title: 'Due Date',
            dataIndex: 'due_date',
            render: (date) => moment(date).format('ll')
        }, {

            title: 'Open Balance',
            dataIndex: 'open_balance',
            render: (balance) => <div>-</div>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: text => <div>
                <Button className="text-uppercase danger-btn-error">{text}</Button>
                {/*<Button className="text-uppercase progress-btn-yellow">25.4% Paid</Button>*/}
                {/*<Button className="text-uppercase success-btn-green">Paid in full</Button>*/}
            </div>,
        },
    ];

    componentDidMount() {
        this.callback("all")
    }

    fetch = (params = {}) => {
        getInvoice(params).then((response) => {
            let { pagination } = this.state;
            pagination.total = response.data.count;
            this.setState({ allInvoioce: response.data.results, pagination, loading: false })
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    };
    callback = (key) => {
        this.setState({ loading: true });
        let params = { status: key };
        if (key == "all") params = {};
        getInvoice(params).then((response) => {
            let { pagination } = this.state;
            pagination.total = response.data.count;
            this.setState({ allInvoioce: response.data.results, pagination, loading: false, activeTab: key })
        }).catch((err) => {
            this.setState({ loading: false });
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    };
    handleChange = (pagination, filters, sorter) => {
        let { params } = this.state;
        let symbol = sorter.order === 'descend' ? '-' : '';
        params = { ...params, page: pagination.current, ordering: `${symbol}${sorter.columnKey}` };
        this.setState({ params });
        this.fetch(params)
    };

    render() {
        const { allInvoioce, pagination, loading, activeTab } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid project-contain-main em-main-container-fluid em-container-fluid-extra">
                    <div className="container">
                        <div className="row m-0 em-main-row-all align-items-center">
                            <div className="col-12">
                                <div className="row m-0 em-tabs-details-main">
                                    <Tabs className="w-100" onChange={this.callback} type="card">
                                        <TabPane tab={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-div">All</div>
                                                <span
                                                    className="num-div">{activeTab === "all" && pagination.total}</span>
                                            </div>
                                        } key="all">
                                            <div className="col-12">
                                                <div className="row em-service-table-card em-table-invoices-card">
                                                    <div className="col-12 p-0">
                                                        <Table
                                                            className="table-responsive em-service-table"
                                                            columns={this.columns}
                                                            dataSource={allInvoioce}
                                                            loading={loading}
                                                            onChange={this.handleChange}
                                                            pagination={{ ...pagination }}
                                                            size="middle"
                                                            onRow={(record, rowIndex) => {
                                                                return {
                                                                    onClick: event => {
                                                                        this.props.history.push('/dashboard/view-invoice/')
                                                                    },
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tab={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-div">Unpaid</div>
                                                <span
                                                    className="num-div">{activeTab === "Unpaid" && pagination.total}</span>
                                            </div>
                                        } key="Unpaid">
                                            <div className="col-12">
                                                <div className="row em-service-table-card em-table-invoices-card">
                                                    <div className="col-12 p-0">
                                                        <Table
                                                            className="table-responsive em-service-table"
                                                            columns={this.columns}
                                                            dataSource={allInvoioce.length && filterData(allInvoioce, status.UNPAID)}
                                                            size="middle"
                                                            loading={loading}
                                                            onChange={this.handleChange}
                                                            pagination={{ ...pagination }}
                                                            onRow={(record, rowIndex) => {
                                                                return {
                                                                    onClick: event => {
                                                                        this.props.history.push('/dashboard/view-invoice/')
                                                                    },
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tab={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-div">Partially Paid</div>
                                                <span
                                                    className="num-div">{activeTab === "Partial" && pagination.total}</span>
                                            </div>
                                        } key="Partial">
                                            <div className="col-12">
                                                <div className="row em-service-table-card em-table-invoices-card">
                                                    <div className="col-12 p-0">
                                                        <Table
                                                            className="table-responsive em-service-table"
                                                            columns={this.columns}
                                                            dataSource={allInvoioce.length && filterData(allInvoioce, status.PARTIAL)}
                                                            size="middle"
                                                            loading={loading}
                                                            onChange={this.handleChange}
                                                            pagination={{ ...pagination }}
                                                            onRow={(record, rowIndex) => {
                                                                return {
                                                                    onClick: event => {
                                                                        this.props.history.push('/dashboard/view-invoice/')
                                                                    },
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tab={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-div">Paid in Full</div>
                                                <span
                                                    className="num-div">{activeTab === "Paid" && pagination.total}</span>
                                            </div>
                                        } key="Paid">
                                            <div className="col-12">
                                                <div className="row em-service-table-card em-table-invoices-card">
                                                    <div className="col-12 p-0">
                                                        <Table
                                                            className="table-responsive em-service-table"
                                                            columns={this.columns}
                                                            dataSource={allInvoioce.length && filterData(allInvoioce, status.PAID)}
                                                            size="middle"
                                                            loading={loading}
                                                            onChange={this.handleChange}
                                                            pagination={{ ...pagination }}
                                                            onRow={(record, rowIndex) => {
                                                                return {
                                                                    onClick: event => {
                                                                        this.props.history.push('/dashboard/view-invoice/')
                                                                    },
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>

                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default withRouter(Invoices);