import React, { Component } from 'react';
import { Button, Drawer, Radio } from 'antd';
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import AddNewAddress from "./AddNewAddress";
import { Image as Images } from "../Images";

class BillingAddress extends Component {
    state = {
        value: 1,
        addAddressVisible: false,
    };
    onChange = e => {
        this.setState({
            value: e.target.value,
        });
    };
    showAddressFormDrawer = (visible) => {
        this.setState({
            addAddressVisible: visible,
        });
    };

    render() {
        return (
            <React.Fragment>
                <Drawer
                    className="em-main-drawer em-billing-drawer"
                    title={
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div>Billing Address</div>
                            <Button onClick={this.props.onClose}
                                className="close-btn-drawer bg-transparent border-0 shadow-none p-0 d-flex align-items-center">Close <img
                                    alt="close icon" className="img-fluid ml-1" src={Images.close_icon_white} /></Button>
                        </div>
                    }
                    placement="right"
                    closable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    getContainer={false}
                    width={"40%"}
                    style={{ position: 'absolute' }}
                >
                    <div className="row em-drawer-main-row position-relative">
                        <div className="col-12">
                            <Radio.Group onChange={this.onChange} value={this.state.value}>
                                <Radio value={1}>
                                    <div className="row">
                                        <div className="col-12 col-sm-5">
                                            <h6>Ronald Richards</h6>
                                            <p>540 Amsterdam Ave<br />
                                                4th Floor,<br />
                                                New York, NY 10024</p>
                                        </div>
                                    </div>
                                </Radio>
                                <Radio value={2}>
                                    <div className="row">
                                        <div className="col-12 col-sm-5">
                                            <h6>Ronald Richards</h6>
                                            <p>540 Amsterdam Ave<br />
                                                4th Floor,<br />
                                                New York, NY 10024</p>
                                        </div>
                                    </div>
                                </Radio>
                            </Radio.Group>
                        </div>
                        <div className="col-12 text-right">
                            <Button onClick={() => this.showAddressFormDrawer(true)}
                                className="add-address-new text-uppercase"><PlusOutlined className="mr-1" />Add
                                New Address</Button>
                        </div>
                    </div>
                    <div className="row em-form-inner-btn em-btn-all em-billing-btn-all">
                        <div className="col-12 text-right">
                            <Button onClick={this.props.onClose}>Cancel</Button>
                            <Button type="primary">Save</Button>
                        </div>
                    </div>
                </Drawer>
                <AddNewAddress visible={this.state.addAddressVisible}
                    onClose={() => this.showAddressFormDrawer(false)} />
            </React.Fragment>
        );
    }
}

export default BillingAddress;