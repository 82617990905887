import React, { Component } from 'react';
import { Image as Images } from "../Images";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import routes from "../../Controller/Routes";

class ViewAccountBreadcrumb extends Component {
    render() {
        let { activeTab } = this.props;
        let active = activeTab === "1" ? "Account Info" : activeTab === "2" ? "Password & Security" : "Billing Info";

        return (
            <React.Fragment>
                <Breadcrumb separator={
                    <img src={Images.arrow_left_breadcrumb} alt="" className="img-fluid" />
                }>
                    {/* <Breadcrumb.Item>Home</Breadcrumb.Item> */}
                    <Breadcrumb.Item>
                        <Link to={routes.dashboard.self}>Main</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={routes.dashboard.viewAccount}>My Account</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{active}</Breadcrumb.Item>
                </Breadcrumb>
            </React.Fragment>
        );
    }
}

export default ViewAccountBreadcrumb;