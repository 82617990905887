import React, { Component } from 'react';
import { Drawer, Input, Button, Row, Form, message } from 'antd';
import './style.scss';
import { postForgetPassword } from '../../Controller/API/UserAPI';
import Verification from '../Verification';

export default class ForgetPassword extends Component {
    state = {
        verificationDrawer: false,
        verificationDetail: {},
        loading: false
    }
    formRef = React.createRef();

    onSuccess = () => {
        this.formRef.current.resetFields();
        this.props.onClose()
        this.handleVerification()
    }
    handleVerification = () => {
        this.setState({ verificationDrawer: !this.state.verificationDrawer })
    }
    onFinish = values => {
        this.setState({ loading: true })
        postForgetPassword(values).then((response) => {
            this.handleVerification()
            response.data.email = values.email
            this.setState({ verificationDetail: response.data, loading: false })
        }).catch((err) => {
            this.setState({ loading: false })
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    }
    render() {
        return (
            <div className="forget-password-container">
                <Form onFinish={this.onFinish} ref={this.formRef}>
                    <Drawer
                        className="em-main-drawer lc-modal fp-modal"
                        placement="right"
                        closable={false}
                        onClose={this.props.onClose}
                        visible={this.props.visible}
                        getContainer={false}
                        width={"37%"}
                        style={{ position: 'absolute' }}>
                        <h2>Forget Password</h2>
                        <p>Please enter your registered email. We will send a a password reset code.</p>
                        <label className="mt-3 mb-1">Email</label>
                        <Form.Item name="email" rules={[{ required: true, message: 'This field is required' }, {
                            type: 'email',
                            message: 'Please input valid email'
                        }]}>
                            <Input placeholder="Example@email.com" />
                        </Form.Item>
                        <Row className="mt-5">
                            <Button type="text" className="w-25 cancel-button" onClick={this.props.onClose}>Cancel</Button>
                            <Button type="primary" htmlType="submit" loading={this.state.loading} className="ml-3 send-button">SEND</Button>
                        </Row>
                    </Drawer>
                </Form>
                <Verification onSuccess={this.onSuccess} verificationDetail={this.state.verificationDetail} onClose={this.handleVerification} visible={this.state.verificationDrawer} />
            </div>
        )
    }
}