import React, { Component } from 'react';
import { Image as Images } from "../Images";
import { Button, message } from "antd";
import ChangePassword from "../Drawers/ChangePassword";
import { updatePassword } from '../../Controller/API/UserAPI';
import { SuccessPopup } from '../modals/SuccessPopup';

class PasswordSecurity extends Component {
    state = {
        passwordChangeVisible: false,
        success: false
    };
    showPasswordChangeDrawer = (visible) => {
        this.setState({
            passwordChangeVisible: visible,
        })
    };
    handleSuccess = () => {
        this.setState({ success: true })
    }
    handlePopUp = () => {
        this.setState({ success: false })
    }
    render() {
        const { success } = this.state
        return (
            <React.Fragment>
                <div className="col-12">
                    <div className="row password-security em-service-table-card bg-transparent shadow-none em-table-invoices-card">
                        <div className="col-12 p-0">
                            <h5 className="heading-info-div">Password & Security</h5>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row card em-card-custom">
                                        <div className="col-12">
                                            <div className="row mx-0 em-card-inner-details">
                                                <div className="col-12">
                                                    <h5 className="d-flex align-items-center">
                                                        <img src={Images.success_icon} alt="success icon"
                                                            className="img-fluid mr-2 border-rad-50" />
                                                        Password has been set
                                                    </h5>
                                                    <h6>Choose a strong, unique password that’s at least 8 characters
                                                        long.</h6>
                                                </div>
                                            </div>

                                            <div className="row mx-0 em-card-inner-details">
                                                <div className="col-12">
                                                    <Button onClick={() => this.showPasswordChangeDrawer(true)}
                                                        className="d-flex align-items-center justify-content-center">
                                                        <img src={Images.edit_icon} alt=""
                                                            className="img-fluid" />
                                                        Change Password
                                                    </Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ChangePassword visible={this.state.passwordChangeVisible}
                    onClose={() => this.showPasswordChangeDrawer(false)} onSuccess={this.handleSuccess} />
                {success && <SuccessPopup message={"Your Password has been Updated"} onClose={this.handlePopUp} />}
            </React.Fragment>
        );
    }
}

export default PasswordSecurity;