import React, { Component } from 'react';
import { Button, Modal, message } from 'antd';
import './style.scss';
import AddServiceDrawer from "../Drawers/AddServiceDrawer";
import { connect } from 'react-redux';
import { Image } from '../Images';
import { setProjectInfo, updateProjectInfo } from '../../Controller/API/ProjectAPI';
import { getServicesAction } from '../../redux/Actions/projectAction';

class AddServiceModal extends Component {
    state = {
        addServiceDrawerVisible: false,
        newService: []
    };
    componentDidMount() {
        this.props.getServicesAction()
    }
    showMainAddServiceDrawer = (visible) => {
        this.setState({
            addServiceDrawerVisible: visible,
        })
    };
    addNewService(service) {
        let newService = [...this.state.newService]
        if (!newService.some(_ => _.id === service.id)) {
            newService = [...newService, service]
        } else {
            newService = newService.filter((_) => _.id !== service.id)
        }
        this.setState({ newService })
    }
    onConfirm = () => {
        let serviceDetail = this.props.serviceDetail ? this.props.serviceDetail : this.props.projectDetails
        let values = {
            name: serviceDetail.name,
            description: serviceDetail.description,
            services: this.state.newService.map((_) => _.id)
        }
        // if (window.location.pathname !== "/") {
        let id = this.props.selectedService.map((_) => _.id)
        values.services = [...values.services, ...id]
        updateProjectInfo(serviceDetail.id, values).then(response => {
            if (this.props.projectDetails) {
                this.props.onClose()
            } else {
                this.props.addExistProjectService(this.state.newService)
            }
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    }
    render() {
        const { newService } = this.state
        return (
            <div className="add-service-container">
                <Modal width="70%" className="Service-modal"
                    visible={this.props.visible}
                    onCancel={() => this.props.onClose(false)}
                    footer={[
                        <Button onClick={!newService.length ? "" : this.onConfirm} disabled={!newService.length} key="submit" className="continue-button d-inline" type="primary">
                            Continue
                           </Button>,
                    ]}>
                    <div className="f-row mt-3">
                        <h6>What’s services are you looking for? *</h6>
                        <div className="pic-services d-blocklo mt-4">
                            {this.props.services.results && this.props.services.results.map(_ => (
                                <React.Fragment> <div onClick={() => this.addNewService(_)} className={newService && this.props.selectedService.some(__ => __.id === _.id) ? "each-service d-none" : newService.some(service => service.id === _.id) ? "each-service active position-relative cursor-pointer" : "each-service position-relative cursor-pointer"}>
                                    {newService.length > 0 && newService.some((service) => service.id === _.id) && <img className="check-icon" src={Image.selected_icon} />}
                                    <div className="es-top">
                                        <img className="img-fluid" alt="" src={_.icon} />
                                    </div>
                                    <label className="cursor-pointer">{_.title}</label>
                                </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                    {/* <AddServiceDrawer visible={this.state.addServiceDrawerVisible} onClose={() => this.showMainAddServiceDrawer(false)} /> */}
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        services: state.services
    }
};
export default connect(mapStateToProps, { getServicesAction })(AddServiceModal);