import React, { Component } from 'react';
import { Image as Images } from "../Images";
import { Button, Switch } from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import AddCardInfo from "../Drawers/AddCardInfo";
import BillingAddress from "../Drawers/BillingAddress";

function onChange(checked) {
    console.log(`switch to ${checked}`);
}

class BillingInfoMain extends Component {
    state = {
        editCardInfoVisible: false,
        billingInfoVisible: false,
    };
    showCardInfoDrawer = (visible) => {
        this.setState({
            editCardInfoVisible: visible,
        })
    };
    showBillingInfoDrawer = (visible) => {
        this.setState({
            billingInfoVisible: visible,
        })
    };
    render() {
        return (
            <React.Fragment>
                <div className="col-12">
                    <div className="row em-service-table-card bg-transparent shadow-none em-table-invoices-card billing-info-main">
                        <div className="col-12 p-0">
                            <div
                                className="row mx-0 billing-info-row-heading align-items-center justify-content-between">
                                <h5 className="heading-info-div mb-0">Billing Info</h5>
                                <Button onClick={() => this.showBillingInfoDrawer(true)}
                                    className="add-payment-btn border-0 text-uppercase d-flex align-items-center justify-content-center"><PlusOutlined
                                        className="mr-1" />Add New Payment</Button>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row card em-card-custom position-relative">
                                        <div className="switch-btn-div position-absolute d-flex align-items-center">
                                            <span className="text-uppercase d-inline-block mr-2">Default</span>
                                            <Switch defaultChecked onChange={onChange} />
                                        </div>
                                        <div className="col-12">
                                            <div className="row em-billing-info-inn">
                                                <div className="col-12 col-sm-6">
                                                    <div className="row mx-0 em-card-inner-details border-0">
                                                        <div className="col-12">
                                                            <h5>Payment Method</h5>
                                                            <p className="d-flex align-items-center">
                                                                <div
                                                                    className="color-card-img-div position-relative d-flex align-items-center">
                                                                    <span className="color-red card-rounded-tag" />
                                                                    <span className="color-orange card-rounded-tag" />
                                                                </div>
                                                                ending in 0064
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 em-card-inner-details">
                                                        <div className="col-12">
                                                            <Button onClick={() => this.showCardInfoDrawer(true)}
                                                                className="d-flex align-items-center justify-content-center">
                                                                <img src={Images.edit_icon} alt=""
                                                                    className="img-fluid" />
                                                                Edit Card Info
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-sm-6">
                                                    <div className="row mx-0 em-card-inner-details border-0">
                                                        <div className="col-12">
                                                            <h5>Billing Address</h5>
                                                            <p>
                                                                540 Amsterdam Ave,<br />
                                                                New York, NY 10024
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="row mx-0 em-card-inner-details">
                                                        <div className="col-12">
                                                            <Button onClick={() => this.showBillingInfoDrawer(true)}
                                                                className="d-flex align-items-center justify-content-center">
                                                                <img src={Images.edit_icon} alt=""
                                                                    className="img-fluid" />
                                                                Edit Billing Address
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <AddCardInfo visible={this.state.editCardInfoVisible} onClose={() => this.showCardInfoDrawer(false)} />
                <BillingAddress visible={this.state.billingInfoVisible} onClose={() => this.showBillingInfoDrawer(false)} />
            </React.Fragment>
        );
    }
}

export default BillingInfoMain;