import { TOGGLE_LOGIN, USER_LOGIN, USER_UPDATE } from "./actionType";
import { userLogin } from "../../Controller/API/UserAPI";
import { setRefreshToken, setUserToken } from "../../Controller/AuthService";

export const toggleLoginAction = (visible) => {
    console.log(visible);
    return async dispatch => {
        dispatch({
            type: TOGGLE_LOGIN,
            data: visible
        })
    }
};

export const userLoginAction = (data) => {
    return async dispatch => {
        return userLogin(data).then(res => {
            console.log(res, 'dddd');
            setUserToken(res.data.access);
            setRefreshToken(res.data.refresh);
            dispatch({
                type: USER_LOGIN,
                data: res.data.user
            })
        })
    }
};
export const updateUser = (data) => {
    return async dispatch => {
        dispatch({
            type: USER_UPDATE,
            data: data
        })
    }
};