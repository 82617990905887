import { GET_PROJECT_SERVICES } from "../Actions/actionType";

const initialState = [];

export default function serviceReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PROJECT_SERVICES:
            state = action.payload;
            return state;

        default:
            return state;
    }
}