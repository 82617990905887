import { getServices } from '../../Controller/API/ProjectAPI';
import { GET_PROJECT_SERVICES } from "./actionType";

export const getServicesAction = () => {
    return async dispatch => {
        getServices().then(response => {
            dispatch({
                type: GET_PROJECT_SERVICES,
                payload: response.data
            })
        }).catch(err => {
            dispatch({
                type: GET_PROJECT_SERVICES,
                payload: []
            })
        })
    }
};


