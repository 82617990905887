import { axios } from '../Axios';
import { getUserToken, getTempToken } from "./AuthService";

function header(requireAuth = true) {
    let headers = {
        'Content-Type': 'application/json'
    };
    if (requireAuth) {
        if (getUserToken()) {
            headers['Authorization'] = `Bearer ${getUserToken()}`;
        } else {
            headers['Authorization'] = `Bearer ${getTempToken()}`;
        }
    }
    return headers
}

export function Get(url, params, auth = true) {
    console.log(auth, header(auth))
    return axios.get(url, { headers: header(auth), params: params })
}

export function Post(url, data, auth = true) {
    return axios.post(url, data, { headers: header(auth) })
}

export function Patch(url, data) {
    return axios.patch(url, data, { headers: header() })
}

export function Remove(url) {
    return axios.delete(url, { headers: header() })
}
