import React, { Component } from 'react';
import { Button, Drawer, Form, Input, Select, message } from 'antd';
import { Image as Images } from "../Images";
import { updateUserProfile, userIndustry } from '../../Controller/API/UserAPI';
// import countryJson from '../../state.json';
const Option = Select;

// const form = Form.useForm();

class EditAccountInfo extends Component {
    state = {
        industry: [],
        loading: false
    }
    formRef = React.createRef();
    componentDidMount() {
        const { userProfile } = this.props
        this.formRef.current.setFieldsValue({
            name: userProfile.name,
            phone: userProfile.phone,
            company_name: userProfile.company_name,
            industry: userProfile.industry,
            url: userProfile.url,
            address: userProfile.address,
            house_detail: userProfile.house_detail,
            city: userProfile.city,
            state: userProfile.state,
            zip: userProfile.zip,
            country: userProfile.country,
            contact_person: userProfile.contact_person,
            company_phone: userProfile.company_phone,
            company_email: userProfile.company_email
        })
        userIndustry(null).then(response => {
            this.setState({ industry: response.data.results })
        }).catch(err => {
            message.error('Something went wrong')
        })
    }
    onFinish = values => {
        this.setState({ loading: true })
        updateUserProfile(values).then((response) => {
            this.props.profileUpdateSuccess()
            this.setState({ loading: false })
            this.props.onClose()
        }).catch((err) => {
            this.setState({ loading: false })
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    }
    render() {
        const { userProfile } = this.props
        return (
            <React.Fragment>
                <Drawer
                    className="em-main-drawer em-address-drawer password-security"
                    title={
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div>Edit Account Info</div>
                            <Button onClick={this.props.onClose} className="close-btn-drawer bg-transparent border-0 shadow-none p-0 d-flex align-items-center">Close <img alt="close icon" className="img-fluid ml-1" src={Images.close_icon_white} /></Button>
                        </div>
                    }
                    placement="right"
                    closable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    getContainer={false}
                    width={"40%"}
                >
                    <div className="row em-drawer-main-row">
                        <div className="col-12">
                            <Form onFinish={this.onFinish} ref={this.formRef} >
                                <div className="row em-form-inner-row">
                                    <div className="col-12">
                                        <Form.Item name="name" label={"Your full name"}
                                            className={"group-floating-label"}>
                                            <Input placeholder="Your Full Name" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item label={"Email"}
                                            className={"group-floating-label"}>
                                            <Input placeholder="Email" defaultValue={userProfile.email} disabled />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="phone" label={"Phone number"}
                                            className={"group-floating-label"}>
                                            <Input placeholder="Phone number" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="company_name" label={"Company Name"}
                                            className={"group-floating-label"}>
                                            <Input placeholder="Company Name" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="industry" label={"Industry"}
                                            className={"group-floating-label select-text-center"}>
                                            <Select placeholder="Select Industry" defaultValue={userProfile.industry}>
                                                {this.state.industry.length && this.state.industry.map((_) =>
                                                    <Option value={_.id}>{_.title}</Option>
                                                )}
                                                {/* <Option key={2}>Food & Beverage</Option> */}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="url" label={"Website and/or social media"}
                                            className={"group-floating-label"} rules={[{ type: 'url', message: 'Please input valid url' }]}>
                                            <Input placeholder="Website and/or social media" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="contact_person" label={"Company Contact"}
                                            className={"group-floating-label"}>
                                            <Input placeholder="Contact Name" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="company_phone" label={"Phone number"}
                                            className={"group-floating-label"}>
                                            <Input placeholder="Phone Number" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="company_email" label={"Email"}
                                            className={"group-floating-label"} rules={[{
                                                type: 'email',
                                                message: 'Please input valid email'
                                            }]}>
                                            <Input placeholder="Email" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="address" label={"Address"}
                                            className={"group-floating-label"}>
                                            <Input placeholder="Street Address" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="house_detail" label={"Floor, Suite, etc"}
                                            className={"group-floating-label"}>
                                            <Input placeholder="Street Address" />
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <Form.Item name="city" label={"City"}
                                                    className={"group-floating-label"}>
                                                    <Input placeholder="City" defaultValue={userProfile.city} />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <Form.Item name="state" label={"State"}
                                                    className={"group-floating-label"}>
                                                    <Input placeholder="State" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <Form.Item name="zip" label={"ZIP/Postal Code"}
                                                    className={"group-floating-label"}>
                                                    <Input placeholder="Zip" />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <Form.Item name="country" label={"Country"}
                                                    className={"group-floating-label"}>
                                                    <Input placeholder="Country" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row em-form-inner-btn em-btn-all">
                                    <div className="col-12 text-right">
                                        <Button onClick={this.props.onClose}>Cancel</Button>
                                        <Button type="primary" loading={this.state.loading} htmlType="submit">Save</Button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default EditAccountInfo;