import React from 'react';
import { Button } from 'antd';
import { Image as Images } from "../Images";
import { useEffect } from 'react';


export const SuccessPopup = ({ message, onClose }) => {
    useEffect(() => {
        setTimeout(() => {
            onClose()
        }, 4000)
    }, [])
    return (
        <div className="card-update-box-div position-fixed">
            <Button className="position-absolute bg-transparent border-0 shadow-none p-0 close-btn">
                <img src={Images.carbon_close_btn} className="img-fluid" alt="" onClick={() => onClose()} />
            </Button>
            <div className="row">
                <div className="col-12">
                    <p className="mb-0">{message}</p>
                </div>
            </div>
            {/* <Button
                className="text-capitalize position-absolute bg-transparent border-0 p-0 shadow-none undo-btn">Undo</Button> */}
        </div>
    )
}