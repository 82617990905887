import React from 'react';
import { Modal, Button, Space } from 'antd';
import { isLoggedIn } from '../../Controller/AuthService';

export const ProjectSuccessModal = (props) => {
    return (
        <React.Fragment>
            {console.log(isLoggedIn())}
            <Modal
                centered
                visible={props.visible}
                onOk={() => this.setModal2Visible(false)}
                className="lc-modal text-center project-create-success" footer={[]} height="235px" width="28%">
                <h6 className="mb-2"><img src={require('../../assets/success.svg')} className="mr-3 success-icon" />Project created successfully{!isLoggedIn() && ", You need to login to review detail about project"}</h6>
                <Button className="mt-5 view-project-btn" onClick={props.onSubmit}>VIEW PROJECT</Button>
            </Modal>
        </React.Fragment>
    )
}