import React, { Component } from 'react';
import { Button, message, Pagination, Select, Table, Upload } from "antd";
import { Image as Images } from "../Images";
import UploadOutlined from "@ant-design/icons/lib/icons/UploadOutlined";
import { ProjectDetailHead } from './ProjectDetailHead';
import { getDocument, removeProjectDocument, uploadProjectDocument } from "../../Controller/API/ProjectAPI";
import moment from "moment";
import { withRouter } from "react-router-dom";

const { Option } = Select;

function handleChange(value) {
    console.log(`selected ${value}`);
}

const pagination = Pagination;
pagination.pageSize = 10;

class ProjectDocuments extends Component {
    state = {
        documents: [],
        loading: true,
        pagination: pagination,
        count: 0
    };
    columns = [
        {
            title: <div>Name</div>,
            dataIndex: 'document',
            render: (data) => {
                let arr = data.split('/');
                let name = arr[arr.length - 1];
                return name
            }
        },
        {
            title: <div className="d-flex align-items-center align-items-center">Size</div>,
            dataIndex: 'size',
        },
        {
            title: 'Date',
            dataIndex: 'created',
            render: (data) => moment(data).format("ll")
        },
        {
            title: '',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => <div className="d-flex align-items-center justify-content-end">
                <Button className="bg-transparent border-0 shadow-none p-0">
                    <a href={record.document} target="_blank"><img src={Images.download_icon} alt="download icon"
                        className="img-fluid" /></a>
                </Button>
                <Button className="bg-transparent border-0 shadow-none p-0" onClick={() => this.removeDocument(record)}>
                    <img src={Images.delete_icon} alt="delete icon" className="img-fluid" />
                </Button>
            </div>,
        },
    ];
    removeDocument = (record) => {
        console.log(record.id)
        removeProjectDocument(this.props.match.params.id, record.id, "").then(response => {
            message.success("Document deleted successfully")
            this.fetch()
        }).catch(err => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    }
    componentDidMount() {
        this.fetch()
    }

    fetch = (params) => {
        getDocument(this.props.match.params.id, params).then(response => {
            let { pagination } = this.state;
            pagination.total = response.data.count;
            this.props.count(response.data.count)
            this.setState({ count: response.data.count, documents: response.data.results, pagination, loading: false })
        }).catch(err => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    };
    UploadFile = info => {
        console.log(info)
        const formData = new FormData();
        formData.append('document', info.file);
        uploadProjectDocument(this.props.match.params.id, formData).then(response => {
            message.success("File uploaded successfully")
            this.fetch()
        }).catch(err => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    }
    render() {
        const { pagination, loading, documents, count } = this.state;
        const { serviceDetail } = this.props;
        return (
            <React.Fragment>
                <div className="col-12">
                    <div className="row em-service-table-card bg-transparent shadow-none em-table-invoices-card documents-main">
                        <div className="col-12 p-0">
                            <div className="row billing-info-row-heading justify-content-between">
                                <ProjectDetailHead serviceDetail={serviceDetail} content={count > 0 && `${count} files`} />
                                <div className="d-flex align-items-center">
                                    <div className="all-invoice-select mr-3">
                                        <Select placeholder="My files only" onChange={handleChange}>
                                            <Option value="jack">My files only</Option>
                                            <Option value="lucy">My files only</Option>
                                        </Select>
                                    </div>
                                    <Upload showUploadList={false} onChange={this.UploadFile} beforeUpload={() => false}>
                                        <Button style={{ width: '100%' }}
                                            className="add-payment-btn border-0 text-uppercase d-flex align-items-center justify-content-center">
                                            <UploadOutlined className="mr-1" />Upload file
                                    </Button>
                                    </Upload>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row em-service-table-card em-table-invoices-card">
                                    <div className="col-12 p-0">
                                        <Table
                                            className="table-responsive em-service-table em-documents-table"
                                            columns={this.columns}
                                            dataSource={documents}
                                            loading={loading}
                                            pagination={{ ...pagination }}
                                            size="middle"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ProjectDocuments);