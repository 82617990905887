import React, {Component} from 'react';
import {Button, DatePicker, Drawer, Form, Input} from 'antd';
import BillingAddress from "./BillingAddress";
import {Image as Images} from "../Images";

class AddCardInfo extends Component {
    state = {
        billingDrawer: false,
    };
    showBillingAddDrawer = (visible) => {
        this.setState({
            billingDrawer: visible,
        });
    };

    render() {
        return (
            <React.Fragment>
                <Drawer
                    className="em-main-drawer"
                    title={
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div>Add Card Info</div>
                            <Button onClick={this.props.onClose}
                                    className="close-btn-drawer bg-transparent border-0 shadow-none p-0 d-flex align-items-center">Close <img
                                alt="close icon" className="img-fluid ml-1" src={Images.close_icon_white}/></Button>
                        </div>
                    }
                    placement="right"
                    closable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    getContainer={false}
                    width={"40%"}
                    style={{position: 'absolute'}}

                >
                    <div className="row em-drawer-main-row">
                        <div className="col-12">
                            <Form>
                                <div className="row em-form-inner-row">
                                    <div className="col-12">
                                        <Form.Item name="card_name" label={"Name on Card"}
                                                   className={"group-floating-label"}>
                                            <Input placeholder="Full Name"/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="card_number" label={"Card Number"}
                                                   className={"group-floating-label"}>
                                            <Input placeholder="Card #"/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="expiration" label={"Expiration"}
                                                   className={"group-floating-label"}>
                                            <DatePicker placeholder="MM/YY"/>
                                        </Form.Item>
                                    </div>
                                    <div className="col-12">
                                        <Form.Item name="cvc" label={"CVC"}
                                                   className={"group-floating-label"}>
                                            <Input placeholder="---"/>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="row em-form-inner-btn em-btn-all">
                        <div className="col-12 text-right">
                            <Button onClick={this.props.onClose}>Cancel</Button>
                            <Button type="primary" onClick={() => this.showBillingAddDrawer(true)}>Save &
                                Next</Button>
                        </div>
                    </div>
                </Drawer>
                <BillingAddress visible={this.state.billingDrawer}
                                onClose={() => this.showBillingAddDrawer(false)}/>
            </React.Fragment>
        );
    }
}

export default AddCardInfo;