import React, { Component } from 'react';
import { Image as Images } from "../Images";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import routes from "../../Controller/Routes";

class ViewProjectBreadcrumb extends Component {
    render() {
        let { activeTab, projectName } = this.props;
        let active = activeTab === "projectInfo" ? "Project Info" : activeTab === "myServices" ? "My Services" : activeTab === "invoices" ? "Invoices" : activeTab === "proposals" ? "Proposal" : activeTab === "documents" ? "Document" : "Activity";
        return (
            <React.Fragment>
                <Breadcrumb separator={
                    <img src={Images.arrow_left_breadcrumb} alt="" className="img-fluid" />
                }>
                    <Breadcrumb.Item>
                        <Link to={routes.dashboard.self}>Main</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={routes.dashboard.projects.self}>{projectName}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{active}</Breadcrumb.Item>
                </Breadcrumb>
            </React.Fragment>
        );
    }
}

export default ViewProjectBreadcrumb;