import React, { Component } from 'react';
import { Input, Checkbox, Row, Col, Radio, Form, Button, message } from 'antd';
import { setRetargeting, updateRetargeting } from '../../../Controller/API/ServiceAPI';

const { TextArea } = Input;


export default class Retargeting extends Component {
    state = {
        userDetails: {},
        buttonLoading: false
    };
    formRef = React.createRef();

    componentDidMount() {
        const { serviceDetail } = this.props;
        if (serviceDetail) {
            this.formRef.current.setFieldsValue({
                name: serviceDetail.name,
                business: serviceDetail.business,
                visitor_action: serviceDetail.visitor_action,
                action_value: serviceDetail.action_value,
                per_month_action: serviceDetail.per_month_action,
                ads_language: serviceDetail.ads_language,
                important_products: serviceDetail.important_products,
                approx_margin: serviceDetail.approx_margin,
                landing_page_url: serviceDetail.landing_page_url,
                benefits: serviceDetail.benefits,
                persona: serviceDetail.persona,
                goals: serviceDetail.goals,
                budget_restriction: serviceDetail.budget_restriction,
                updated_time: serviceDetail.updated_time,
                campaign: serviceDetail.campaign,
                adwords: serviceDetail.adwords,
                keywords: serviceDetail.keywords,
                avoid_keywords: serviceDetail.avoid_keywords,
                trademark: serviceDetail.trademark,
                competitors: serviceDetail.competitors,
                paying_person: serviceDetail.paying_person,
                contacting_person: serviceDetail.contacting_person,
                approval_level: serviceDetail.approval_level,
                updating_person: serviceDetail.updating_person,
                leads_phone: serviceDetail.leads_phone,
            })
        }
    }

    onFinish = values => {
        this.setState({ buttonLoading: true });
        let userDetails = { ...this.state.userDetails };
        values.project = this.props.projectId;
        if (userDetails.id || this.props.serviceDetail) {
            let id = userDetails.id ? userDetails.id : this.props.serviceDetail.id;
            updateRetargeting(id, values).then((response) => {
                this.props.onSubmit();
                if (!this.props.serviceType) message.success("Details Updated Successfully");
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    });
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong");
                    this.setState({ buttonLoading: false })
                }
            })
        } else {
            setRetargeting(values).then((response) => {
                this.props.onSubmit();
                this.setState({ userDetails: response.data });
                if (!this.props.serviceType) message.success("Form Submitted");
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    });
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong");
                    this.setState({ buttonLoading: false })
                }
            })
        }
    };

    render() {
        return (
            <React.Fragment>
                <Form onFinish={this.onFinish} ref={this.formRef}>
                    <div className="f-row">
                        <h6>Name your project<span>(optional)</span></h6>
                        <Form.Item name="name">
                            <Input className="fr-input" placeholder="Eg. Landing page for my food truck’s website" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What does your business do/sell?</h6>
                        <Form.Item name="business" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What actions do you want visitors to take from advertising?</h6>
                        <Form.Item name="visitor_action" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What is the value of each of these actions?</h6>
                        <Form.Item name="action_value" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How many of these actions are you looking for each month?</h6>
                        <Form.Item name="per_month_action"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Where and when should your retargeting ads be running? What languages?</h6>
                        <Form.Item name="ads_language" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="seperator f-row mt-4">
                        <h5>Product/Services Specific Questions</h5>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What are your most important products/services that you will be advertising?</h6>
                        <Form.Item name="important_products"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What is your approximate margin on these products/services?</h6>
                        <Form.Item name="approx_margin" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Do you have dedicated landing pages discussing these products/services? If so, what are the
                            URLs?</h6>
                        <Form.Item name="landing_page_url"
                            rules={[{ required: true, message: 'This field is required' }, {
                                type: "url",
                                message: "This field must be a valid url."
                            }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What are the features/benefits of buying these products?</h6>
                        <Form.Item name="benefits" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Have you developed a persona/avatar of your ideal customer?</h6>
                        <Form.Item name="persona" rules={[{ required: true, message: 'This field is required' }]}>
                            <TextArea rows={4} className="fr-input fr-inputTextArea"
                                placeholder="E.g. I need an email to announce new servic" />
                        </Form.Item>
                    </div>
                    <div className="seperator">
                        <h5>Strategy Questions</h5>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Which of these goals best describes your approach to advertising?</h6>
                        <Form.Item name="goals" rules={[{ required: true, message: 'This field is required' }]}>
                            <Radio.Group>
                                <Row>
                                    <Col span={24} className="mt-3">
                                        <Radio value="maximizeTheTotalNumberOfActionsTakenForTheBudget">Maximize the
                                            total number of actions taken for the budget</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="showMyBrandInTopPositionsWheneverPossible">Show my brand in top
                                            positions whenever possible</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="profitIsOurGoalBringBackPotentialCustomersToOurpages">Profit is
                                            our goal – bring back potential customers to our pages</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How aggressively do you want to approach budget restrictions? If profitable clicks are
                            available, should budget be increased?</h6>
                        <Form.Item name="budget_restriction"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How frequently do you want to be updated with account results?</h6>
                        <Form.Item name="updated_time" rules={[{ required: true, message: 'This field is required' }]}>
                            <Radio.Group>
                                <Row>
                                    <Col span={24} className="mt-3">
                                        <Radio value="weekly">Weekly</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="monthly">Monthly</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="quarterly">Quarterly</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="seperator">
                        <h5>Strategy Questions</h5>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Have you ever ran a Retargeting Campaign before?</h6>
                        <Form.Item name="campaign" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Have you ever advertised with AdWords before?</h6>
                        <Form.Item name="adwords" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Are you aware of any keywords related to your products/services that draw in customers?</h6>
                        <Form.Item name="keywords" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Are there any keywords we should avoid in our campaigns?</h6>
                        <Form.Item name="avoid_keywords" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Are there any trademarks, copyrights or other information we should know about when writing
                            ads?</h6>
                        <Form.Item name="trademark" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="seperator">
                        <h5>Competitor Analysis</h5>
                    </div>
                    <div className="f-row mt-4">
                        <h6>List your top 3-5 competitors and their websites here <span>(separate competitors with comma)</span>
                        </h6>
                        <Form.Item name="competitors" rules={[{ required: true, message: 'This field is required' }]}>
                            <TextArea rows={4} className="fr-input fr-inputTextArea"
                                placeholder="E.g. Facebook www.facebook.com, Google www.google.com, Amazon www.amazon.com" />
                        </Form.Item>
                    </div>
                    <div className="seperator">
                        <h5>Account Questions</h5>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Who will be paying for your ads?</h6>
                        <Form.Item name="paying_person" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Who should we be contacting with questions/concerns about your Google AdWords Account?</h6>
                        <Form.Item name="contacting_person"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What level of approval is needed for adding keywords and ads to the account?</h6>
                        <Form.Item name="approval_level" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Who do we talk to about getting updates to your website?</h6>
                        <Form.Item name="updating_person" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Do you want to generate phone call leads/sales opportunities? If so, what phone number
                            should receive these leads?</h6>
                        <Form.Item name="leads_phone" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 123-456-7890" />
                        </Form.Item>
                    </div>
                    <div className="row em-form-inner-btn em-btn-all position-sticky edit-footer">
                        {this.props.clearForm && <Button className="bg-transparent border-0 shadow-none w-auto" onClick={() => this.formRef.current.resetFields()}>Clear</Button>}
                        {this.props.serviceType == "inner" &&
                            <Button className="mt-4" onClick={this.props.onClose}>Cancel</Button>}
                        <Button loading={this.state.buttonLoading} type="primary" htmlType="submit" size="large"
                            className="d-inline mt-4 continue-button">SUBMIT</Button>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
} 