import React, { Component } from 'react';
import './style.scss'
import { Button, Tabs, message } from "antd";
import { Image as Images } from "../Images";
import ViewProjectBreadcrumb from "./ViewProjectBreadcrumb";
import ProjectInfoMain from "./ProjectInfoMain";
import { Link } from "react-router-dom";
import MyServices from "./MyServices";
import ProjectInvoiceInner from "./ProjectInvoiceInner";
import ProjectPropsal from "./ProjectPropsal";
import ProjectDocuments from "./ProjectDocuments";
import ProjectActivity from "./ProjectActivity";
import routes from '../../Controller/Routes';
import { connect } from 'react-redux';
import { getProjectInfobyId } from '../../Controller/API/ProjectAPI';

const { TabPane } = Tabs;

function callback(key) {
    console.log(key);
}

class ProjectDetailsInnerAll extends Component {
    state = {
        serviceDetail: {},
        projectDetails: {},
        activeTab: "projectInfo",
        count: 0
    };

    componentDidMount() {
        this.getProjectDetails()
    }

    getProjectDetails = () => {
        getProjectInfobyId(this.props.match.params.id, "").then((response) => {
            this.setState({ projectDetails: response.data })
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    };
    changeTab = (key) => {
        this.setState({ activeTab: key })
    };
    handleCount = (count) => {
        this.setState({ count })
    }

    render() {
        const { serviceDetail, projectDetails, activeTab, count } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid project-details-main em-main-container-fluid em-container-fluid-extra">
                    <div className="container">
                        <div className="row em-main-row-all align-items-center">
                            <div className="col-12">
                                <div className="row em-heading-breadcrumb-main">
                                    <div className="col-12 col-sm-3 project-info-left">
                                        <h6>Account</h6>
                                        <h5 className="t-capitalize">{this.props.user.user && (this.props.user.user.name || "")}<Link
                                            to={routes.dashboard.viewAccount}
                                            className="text-uppercase ml-2">View</Link></h5>
                                        <Button onClick={() => this.props.history.push(routes.dashboard.projects.self)}
                                            className="z-index2 all-project-view-btn bg-transparent shadow-none border-0 p-0 d-flex align-items-center">
                                            <img src={Images.left_arrow_small_black} alt="arrow left"
                                                className="img-fluid" />
                                            View all projects
                                        </Button>
                                    </div>
                                    <div className="col-12 col-sm-9 breadcrump">
                                        <ViewProjectBreadcrumb projectName={projectDetails.name} activeTab={activeTab} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row m-0 em-tabs-details-main em-tabs-project-main em-tabs-details-main-2">
                                    <Tabs className="w-100" activeKey={activeTab} onChange={this.changeTab} type="card">
                                        <TabPane tab={
                                            <div className="d-flex align-items-center">
                                                <div className="tabs-icon-div">
                                                    <img src={Images.project_info_black} alt="Project info icon"
                                                        className="img-fluid" />
                                                </div>
                                                <div className="text-div">Project Info</div>
                                            </div>
                                        } key="projectInfo">
                                            {activeTab === "projectInfo" && <ProjectInfoMain projectDetails={projectDetails} changeTab={this.changeTab}
                                                getProjectDetails={this.getProjectDetails} />}
                                        </TabPane>

                                        <TabPane tab={
                                            <div className="d-flex align-items-center">
                                                <div className="tabs-icon-div">
                                                    <img src={Images.service_icon} alt="Services icon"
                                                        className="img-fluid" />
                                                </div>
                                                <div
                                                    className="text-div d-flex align-items-center justify-content-between">My
                                                    Services <span
                                                        className="count-tag">{activeTab === "myServices" ? count : ""}</span>
                                                </div>
                                            </div>
                                        } key="myServices">
                                            {activeTab === "myServices" && <MyServices projectDetails={projectDetails} count={this.handleCount} />}
                                        </TabPane>
                                        <TabPane tab={
                                            <div className="d-flex align-items-center">
                                                <div className="tabs-icon-div">
                                                    <img src={Images.invoices_black} alt="Invoices icon"
                                                        className="img-fluid" />
                                                </div>
                                                <div className="text-div d-flex align-items-center justify-content-between">Invoices<span
                                                    className="count-tag">{activeTab === "invoices" ? count : ""}</span></div>
                                            </div>
                                        } key="invoices">
                                            {activeTab === "invoices" && <ProjectInvoiceInner count={this.handleCount} serviceDetail={projectDetails} />}
                                        </TabPane>
                                        <TabPane tab={
                                            <div className="d-flex align-items-center">
                                                <div className="tabs-icon-div">
                                                    <img src={Images.proposal_icon} alt="Proposal icon"
                                                        className="img-fluid" />
                                                </div>
                                                <div
                                                    className="text-div d-flex align-items-center justify-content-between">Proposal <span
                                                        className="count-tag">{activeTab === "proposals" ? count : ""}</span></div>
                                            </div>
                                        } key="proposals">
                                            {activeTab === "proposals" && <ProjectPropsal serviceDetail={projectDetails} count={this.handleCount} />}
                                        </TabPane>
                                        <TabPane tab={
                                            <div className="d-flex align-items-center">
                                                <div className="tabs-icon-div">
                                                    <img src={Images.document_icon} alt="docs icon"
                                                        className="img-fluid" />
                                                </div>
                                                <div className="text-div d-flex align-items-center justify-content-between">Document <span
                                                    className="count-tag">{activeTab === "documents" ? count : ""}</span></div>
                                            </div>
                                        } key="documents">
                                            {activeTab === "documents" && <ProjectDocuments serviceDetail={projectDetails} count={this.handleCount} />}
                                        </TabPane>
                                        <TabPane tab={
                                            <div className="d-flex align-items-center">
                                                <div className="tabs-icon-div">
                                                    <img src={Images.activity_icon} alt="activity icon"
                                                        className="img-fluid" />
                                                </div>
                                                <div className="text-div">Activity</div>
                                            </div>
                                        } key="activity">
                                            {activeTab === "activity" && <ProjectActivity serviceDetail={projectDetails} />}
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="card-update-box-div position-fixed">
                    <Button className="position-absolute bg-transparent border-0 shadow-none p-0 close-btn">
                        <img src={Images.carbon_close_btn} className="img-fluid" alt="" />
                    </Button>
                    <div className="row">
                        <div className="col-12">
                            <p className="mb-0">Credit Card Ending 0064 has been added!</p>
                        </div>
                    </div>
                    <Button className="text-capitalize position-absolute bg-transparent border-0 p-0 shadow-none undo-btn">Undo</Button>
                </div> */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
};
export default connect(mapStateToProps)(ProjectDetailsInnerAll);