import React, { Component } from 'react';
import { Input, Select, Button, Form, message } from 'antd';
import Login from '../Login';
import { userRegister, userSource, userIndustry, getUserProfile, updateUserProfile } from '../../Controller/API/UserAPI';
import { step } from '../../Controller/Global';
import { connect } from "react-redux";
import { toggleLoginAction } from "../../redux/Actions/userActions";
import { isEmpty } from 'lodash';
import { getTempToken } from "../../Controller/AuthService";

const { Option } = Select;

class AccountInfo extends Component {
    state = {
        industry: [],
        source: [],
        userDetails: {},
        buttonLoading: false
    };
    accountFormRef = React.createRef();

    componentDidMount() {
        if (getTempToken()) {
            getUserProfile().then((response) => {
                this.accountFormRef.current.setFieldsValue({
                    name: response.data.name,
                    phone: response.data.phone,
                    industry: response.data.industry,
                    company_name: response.data.company_name,
                    url: response.data.url,
                    email: response.data.email
                });
                this.setState({ userDetails: response.data })
            })
        }
    }

    fetchIndustry = () => {
        userIndustry(null).then(response => {
            this.setState({ industry: response.data.results })
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else { message.error("something went wrong") }
        })
    };
    fetchSource = () => {
        userSource(null).then(response => {
            this.setState({ source: response.data.results })
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else { message.error("something went wrong") }
        })
    };
    onFinish = values => {
        this.setState({ buttonLoading: true });
        if (values.password == values.confirmPassword || getTempToken()) {
            delete values.confirmPassword;
            if (!isEmpty(this.state.userDetails)) {
                updateUserProfile(values).then((response) => {
                    this.props.handleProcess(step.PROJECT_INFO);
                    message.success("User Updated Successfully");
                    this.setState({ buttonLoading: false })
                }).catch((err) => {
                    this.setState({ loading: false });
                    if (err.response) {
                        Object.keys(err.response.data).map((i) => {
                            message.error(err.response.data[i]);
                            this.setState({ buttonLoading: false })
                        })
                    } else {
                        message.error("something went wrong");
                        this.setState({ buttonLoading: false })
                    }
                })
            } else {
                userRegister(values).then(response => {
                    this.props.handleProcess(step.PROJECT_INFO);
                    message.success("User Created Successfully");
                    this.setState({ buttonLoading: false })
                }).catch((err) => {
                    if (err.response) {
                        Object.keys(err.response.data).map((i) => {
                            message.error(err.response.data[i])
                        });
                        this.setState({ buttonLoading: false })
                    } else {
                        message.error("something went wrong");
                        this.setState({ buttonLoading: false })
                    }
                })
            }
        } else {
            message.error("Password Does Not match");
            this.setState({ buttonLoading: false })
        }

    };
    render() {
        const { source, industry } = this.state;
        return (
            <div className="account-info-container">
                <h2 className="aic-heading">Tell us about yourself/your company</h2>
                <h4 className="aic-subHeading">Already have an account?<span className="cursor-pointer"
                    onClick={() => this.props.toggleLoginAction(true)}>Log in</span>
                </h4>
                <Form className="mt-4" ref={this.accountFormRef} onFinish={this.onFinish}>
                    <div className="aic-form">
                        <div className="f-row">
                            <h6>Whats your full name?</h6>
                            <Form.Item name="name" rules={[{ required: true, message: 'This field is required' }]}>
                                <Input className="fr-input" placeholder="Eg. John Appleseed" />
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>Company Name</h6>
                            <Form.Item name="company_name"
                                rules={[{ required: true, message: 'This field is required' }]}>
                                <Input className="fr-input" className="fr-input"
                                    placeholder="Eg. Landing page for my food truck’s website" />
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>What industry are you in?</h6>
                            <Form.Item name="industry">
                                <Select placeholder="Select" onFocus={this.fetchIndustry} style={{ width: "100%" }}>
                                    {industry.length && industry.map((_) =>
                                        <Option value={_.id}>{_.title}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>Add your website and/or social media</h6>
                            <Form.Item name="url" rules={[{
                                type: "url", message: "This field must be a valid url."
                            }]}>
                                <Input className="fr-input" placeholder="E.g. my_awesome_website.com" />
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>How did you hear about us?</h6>
                            <Form.Item name="source">
                                <Select placeholder="Select" onFocus={this.fetchSource} style={{ width: "100%" }}>
                                    {source.length && source.map((_) =>
                                        <Option value={_.id}>{_.title}</Option>
                                    )}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>Whats your phone number?</h6>
                            <Form.Item name="phone">
                                <Input className="fr-input" placeholder="Eg. 123-456-7890" />
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>Whats your email?</h6>
                            <Form.Item name="email"
                                rules={[{ required: true, message: 'This field is required', type: 'email' }]}>
                                <Input className="fr-input" disabled={getTempToken()}
                                    autoComplete="off" placeholder="Eg. john.appleseed@email.com" />
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>Create a password</h6>
                            <Form.Item name="password" rules={[{
                                required: getTempToken() ? false : true,
                                message: 'This field is required'
                            },
                            { min: 8, message: 'Password must be 8 characters long.' }]}>
                                <Input.Password placeholder="8 characters min." autoComplete="off"
                                    disabled={getTempToken()} />
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>Confirm password</h6>
                            <Form.Item name="confirmPassword"
                                rules={[{
                                    required: !getTempToken(),
                                    message: 'This field is required'
                                },
                                { min: 8, message: 'Password must be 8 characters long.' }]}>
                                <Input.Password disabled={getTempToken()}
                                    placeholder="Passwords must match" />
                            </Form.Item>
                        </div>
                    </div>
                    <Button loading={this.state.buttonLoading} type="primary" size="large" htmlType="submit"
                        className="float-right mt-4 continue-button">CONTINUE</Button>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { user: state.user }
};
export default connect(mapStateToProps, { toggleLoginAction })(AccountInfo)