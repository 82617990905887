import React, { Component } from 'react';
import { Button, Collapse, message, Popover } from "antd";
import { Image as Images } from "../Images";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import AddService from "../modals/AddService";
import AddServiceDrawer from "../Drawers/AddServiceDrawer";
import { ProjectDetailHead } from './ProjectDetailHead';
import { myServices } from '../../Controller/Global';
import moment from "moment";
import { isEmpty } from 'lodash';
import { updateProjectInfo } from '../../Controller/API/ProjectAPI';
import { withRouter } from 'react-router-dom';
import EditServiceDrawer from '../Drawers/EditServiceDrawer';
import { getProjectDetails, removeWebsiteDesign, removeSearchEngineOpt, removeEmailMarketing, removeContentGeneration, removeIntegratedMarketing, removeRetargeting, removeSocialMediaMarketing, removeSearchEngineMarketing } from '../../Controller/API/ServiceAPI';
import { SuccessPopup } from '../modals/SuccessPopup';



const { Panel } = Collapse;

class MyServices extends Component {
    state = {
        addServiceVisible: false,
        addServiceDrawerVisible: false,
        addExistService: [],
        serviceDetail: {},
        selectedService: [],
        editServiceDrawer: false,
        editServiceDetail: [],
        visible: false,
        success: { show: false, name: "" }
    };
    componentDidMount() {
        this.projectDetails()
    }
    projectDetails = () => {
        getProjectDetails(this.props.match.params.id, "").then((response) => {
            this.props.count(response.data.services.length)
            this.setState({ serviceDetail: response.data })
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    }
    editSubmit = (model) => {
        let serviceName = this.state.serviceDetail[myServices[model]]
        this.projectDetails()
        this.setState({ editServiceDrawer: false, success: { ...this.state.success, show: true, name: `Service ${serviceName ? serviceName.name : ""} has been updated!` } })
    }
    editServicePopOver = (service, visible, name) => {
        this.setState({ editServiceDetail: [service], editServiceDrawer: visible, [name]: false })
    }
    // edit service handle  
    editService = (service, visible) => {
        if (visible === false) this.projectDetails()
        this.setState({ editServiceDetail: [service], editServiceDrawer: visible })
    }
    //add new service handle 
    showMainAddServiceDrawer = (service, visible) => {
        if (visible === false) this.projectDetails()
        this.setState({
            addServiceDrawerVisible: visible,
            selectedService: !isEmpty(service) ? [...this.state.selectedService, service] : [...this.state.selectedService]
        })
    };
    //Select Service to Add Drawer handle
    showAddServiceDrawer = (visible) => {
        this.setState({ addServiceVisible: visible })
    }
    addExistProjectService = (services) => {
        this.setState({ success: { ...this.state.success, show: true, name: `Service has been added!` } })
        this.showAddServiceDrawer(false)
        this.showMainAddServiceDrawer({}, true)
        this.setState({ selectedService: [...services] })
    }
    removeService = (e, serviceId, name) => {
        e.stopPropagation()
        this.setState({ [name]: false })
        let data = "", id = e.target.dataset.id
        switch (e.target.id) {
            case 'WebsiteDesign': removeWebsiteDesign(id, data).then((response) => {
                message.success("Service Deleted Successfully")
                this.projectDetails()
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'SearchEngineOptimization': removeSearchEngineOpt(id, data).then((response) => {
                message.success("Service Deleted Successfully")
                this.projectDetails()
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'EmailMarketing': removeEmailMarketing(id, data).then((response) => {
                message.success("Service Deleted Successfully")
                this.projectDetails()
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'ContentGeneration': removeContentGeneration(id, data).then((response) => {
                message.success("Service Deleted Successfully")
                this.projectDetails()
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'IntegratedMarketing': removeIntegratedMarketing(id, data).then((response) => {
                message.success("Service Deleted Successfully")
                this.projectDetails()
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'Retargeting': removeRetargeting(id, data).then((response) => {
                message.success("Service Deleted Successfully")
                this.projectDetails()
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'SocialMediaMarketing': removeSocialMediaMarketing(id, data).then((response) => {
                message.success("Service Deleted Successfully")
                this.projectDetails()
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            case 'SearchEngineMarketing': removeSearchEngineMarketing(id, data).then((response) => {
                message.success("Service Deleted Successfully")
                this.projectDetails()
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
                break;
            default: return null
        }
    }
    handleVisibleChange = (e, name) => {
        this.setState({ [name]: e });
    };
    handlePopUp = () => {
        this.setState({ success: false })
    }
    render() {
        const { serviceDetail, success } = this.state
        return (
            <React.Fragment>
                <div className="col-12">
                    <div className="row services-edit em-service-table-card bg-transparent shadow-none em-table-invoices-card">
                        <div className="col-12 p-0">
                            <div className="row billing-info-row-heading justify-content-between">
                                <ProjectDetailHead serviceDetail={serviceDetail} content={`${serviceDetail.services ? serviceDetail.services.length : ""} Services`} />
                                <Button onClick={() => this.showAddServiceDrawer(true)} style={{ width: '20%' }}
                                    className="add-payment-btn border-0 text-uppercase d-flex align-items-center justify-content-center">
                                    <PlusOutlined className="mr-1" />ADD Service
                                </Button>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row em-accordion-row">
                                <div className="col-12 p-0">
                                    <Collapse accordion>
                                        {serviceDetail.services && serviceDetail.services.map((_, i) => (
                                            < Panel header={
                                                < div className="em-custom-header-panel" >
                                                    <div className="service-name d-flex align-items-center">
                                                        <div className="em-icon-div">
                                                            <img src={_.icon} alt=""
                                                                className="img-fluid h-20" />
                                                        </div>
                                                        <div className="em-heading-inn-panel">
                                                            {serviceDetail[myServices[_.model]] && (_.model !== "SearchEngineMarketing" ? serviceDetail[myServices[_.model]].name || "-" : serviceDetail[myServices[_.model]].action_count.split(' ').slice(0, 2).join(' '))}
                                                            <span>{_.title}</span>
                                                            {/* <button type="button" data-id={serviceDetail[myServices[_.model]] && serviceDetail[myServices[_.model]].id} id={_.model} onClick={(e) => this.removeService(e, _.id)}>Delete</button> */}
                                                        </div>
                                                    </div>
                                                    <div className="em-status-panel">
                                                        <Button
                                                            className="em-status-btn em-pending-btn text-uppercase bg-transparent shadow-none border-0 p-0 status-btn">{serviceDetail[myServices[_.model]] && serviceDetail[myServices[_.model]].status}</Button>
                                                        <div className="d-inline" onClick={(e) => e.stopPropagation()}>
                                                            <Popover onVisibleChange={(e) => this.handleVisibleChange(e, `visible` + i)} visible={this.state[`visible` + i]} placement="bottomRight" content={<div onClick={(e) => e.stopPropagation()}>
                                                                <p onClick={() => this.editServicePopOver(_, true, `visible` + i)}><img src={Images.file_icon} /><span>View / Edit Brief</span></p>
                                                                {!isEmpty(serviceDetail[myServices[_.model]]) && <p className="mt-3" ><img src={Images.remove_icon} /><span data-id={serviceDetail[myServices[_.model]] && serviceDetail[myServices[_.model]].id} id={_.model} onClick={(e) => this.removeService(e, _.id, `visible` + i)}>Delete Service</span></p>}
                                                            </div>} trigger="click">
                                                                <Button onClick={(e) => e.stopPropagation()}
                                                                    className="dropdown-ellipsis bg-transparent border-0 shadow-none p-0">
                                                                    <img src={Images.menu_ellipsis} alt="ellipsis"
                                                                        className="img-fluid" />
                                                                </Button>
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                </div>
                                            } key={_.id}>
                                                <div className="row card em-card-custom">
                                                    <div className="col-12">
                                                        <div className="row mx-0 em-card-inner-details pb-0 border-0">
                                                            <div className="col-12 col-sm-3">
                                                                <h6>Created</h6>
                                                                <p className="mb-0">{serviceDetail[myServices[_.model]] && moment(serviceDetail[myServices[_.model]].created).format("ll") || "-"}</p>
                                                            </div>
                                                            <div className="col-12 col-sm-3">
                                                                <h6>Total</h6>
                                                                <p className="mb-0">-</p>
                                                            </div>
                                                        </div>

                                                        <div className="row mx-0 em-card-inner-details border-0 r-pad-0">
                                                            <div className="col-12 col-sm-3">
                                                                <h6>Due</h6>
                                                                <p className="mb-0">-</p>
                                                            </div>
                                                            <div className="col-12 col-sm-3">
                                                                <h6>Delivered</h6>
                                                                <p className="mb-0">-</p>
                                                            </div>
                                                        </div>

                                                        <div className="row mx-0 em-card-inner-details r-padt-0">
                                                            <div className="col-12">
                                                                <h6 className="mb-2 pb-1">Description</h6>
                                                                <p>{serviceDetail[myServices[_.model]] && serviceDetail[myServices[_.model]].description} </p>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="row mx-0">
                                                                    <Button onClick={() => this.editService(_, true)}
                                                                        className="d-flex align-items-center view-edit-brief justify-content-center">
                                                                        <img src={Images.edit_brief_icon} alt=""
                                                                            className="img-fluid" />
                                                                        View / Edit Brief
                                                                </Button>
                                                                    {/* <Button
                                                                        className="d-flex align-items-center view-edit-brief justify-content-center em-view-invoice">
                                                                        <img src={Images.edit_brief_icon} alt=""
                                                                            className="img-fluid" />
                                                                        View Invoice
                                                                </Button> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        ))}

                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                { this.state.addServiceVisible && <AddService serviceDetail={serviceDetail} selectedService={serviceDetail.services} visible={this.state.addServiceVisible} onClose={() => this.showAddServiceDrawer(false)} addExistProjectService={this.addExistProjectService} />}
                { this.state.addServiceDrawerVisible && <AddServiceDrawer servicesDetails={serviceDetail} addExistService={this.state.selectedService} visible={this.state.addServiceDrawerVisible} onClose={() => this.showMainAddServiceDrawer({}, false)} />}
                { this.state.editServiceDrawer && <EditServiceDrawer servicesDetails={serviceDetail} editServiceDetail={this.state.editServiceDetail} visible={this.state.editServiceDrawer} onClose={() => this.editService({}, false)} onSubmit={this.editSubmit} />}
                { success.show && <SuccessPopup message={success.name} onClose={this.handlePopUp} />}
            </React.Fragment >
        );
    }
}
export default withRouter(MyServices);