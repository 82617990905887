import { include } from 'named-urls'

export default {
    login: '/',
    logout: '/logout',
    dashboard: include('/dashboard', {
        self: '',
        projects: include('projects/', {
            self: "",
            details: 'details/:id/'
        }),
        proposals: 'proposals/',
        invoices: 'invoices/',
        contactUs: 'contactUs/',
        viewAccount: 'view-account/',
        viewInvoice: 'view-invoice/',
        payInvoice: 'pay-invoice/',
        projectCreation: 'project-creation'
    })
}