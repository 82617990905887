import { getAPIUrl } from '../Global';
import { Post, Get, Remove, Patch } from "../headerIntercepter";


//Websute Design
export function getServiceWebsite(data) {
    const url = getAPIUrl('service.websiteDesign.self')
    return Get(url, data)
}
export function updateServiceWebsiteDesign(id, data) {
    const url = getAPIUrl('service.websiteDesign.id', { id })
    return Patch(url, data)
}
export function removeWebsiteDesign(id, data) {
    const url = getAPIUrl('service.websiteDesign.id', { id })
    return Remove(url, data)
}
export function postServiceWebsite(data) {
    const url = getAPIUrl('service.websiteDesign.self')
    return Post(url, data)
}

//SEO
export function postSearchEngineOpt(data) {
    const url = getAPIUrl('service.searchEngineOpt.self')
    return Post(url, data)
}
export function updateSearchEngineOpt(id, data) {
    const url = getAPIUrl('service.searchEngineOpt.id', { id })
    return Patch(url, data)
}
export function removeSearchEngineOpt(id, data) {
    const url = getAPIUrl('service.searchEngineOpt.id', { id })
    return Remove(url, data)
}

//Contetn Generation
export function postContentGeneration(data) {
    const url = getAPIUrl('service.contentGeneration.self')
    return Post(url, data)
}
export function updateContentGeneration(id, data) {
    const url = getAPIUrl('service.contentGeneration.id', { id })
    return Patch(url, data)
}
export function removeContentGeneration(id, data) {
    const url = getAPIUrl('service.contentGeneration.id', { id })
    return Remove(url, data)
}


//SEM
export function postSearchEngineMarketing(data) {
    const url = getAPIUrl('service.searchEngineMarketing.self')
    return Post(url, data)
}
export function updateSearchEngineMarketing(id, data) {
    const url = getAPIUrl('service.searchEngineMarketing.id', { id })
    return Patch(url, data)
}
export function removeSearchEngineMarketing(id, data) {
    const url = getAPIUrl('service.searchEngineMarketing.id', { id })
    return Remove(url, data)
}

//Email marketing
export function postEmailMarketing(data) {
    const url = getAPIUrl('service.emailMarketing.self')
    return Post(url, data)
}
export function updateEmailMarketing(id, data) {
    const url = getAPIUrl('service.emailMarketing.id', { id })
    return Patch(url, data)
}
export function removeEmailMarketing(id, data) {
    const url = getAPIUrl('service.emailMarketing.id', { id })
    return Remove(url, data)
}

//Retargeting

export function setRetargeting(data) {
    const url = getAPIUrl('service.retargeting.self')
    return Post(url, data)
}
export function updateRetargeting(id, data) {
    const url = getAPIUrl('service.retargeting.id', { id })
    return Patch(url, data)
}
export function removeRetargeting(id, data) {
    const url = getAPIUrl('service.retargeting.id', { id })
    return Remove(url, data)
}

//IM
export function setIntegratedMarketing(data) {
    const url = getAPIUrl('service.integratedMarketing.self')
    return Post(url, data)
}
export function updateIntegratedMarketing(id, data) {
    const url = getAPIUrl('service.integratedMarketing.id', { id })
    return Patch(url, data)
}
export function removeIntegratedMarketing(id, data) {
    const url = getAPIUrl('service.integratedMarketing.id', { id })
    return Remove(url, data)
}

//SMM
export function setSocialMediaMarketing(data) {
    const url = getAPIUrl('service.socialMediaMarketing.self')
    return Post(url, data)
}
export function updateSocialMediaMarketing(id, data) {
    const url = getAPIUrl('service.socialMediaMarketing.id', { id })
    return Patch(url, data)
}
export function removeSocialMediaMarketing(id, data) {
    const url = getAPIUrl('service.socialMediaMarketing.id', { id })
    return Remove(url, data)
}



export function getProjectDetails(id, data) {
    const url = getAPIUrl('service.project.id', { id })
    return Get(url, data)
}