import React, { Component } from 'react';
import { Input, Checkbox, Row, Col, Form, Button, message } from 'antd';
import { setIntegratedMarketing, updateIntegratedMarketing } from '../../../Controller/API/ServiceAPI';

const { TextArea } = Input;


export default class IntegratingMarketing extends Component {
    state = {
        marketing_forms: null,
        marketing_formsShow: false,
        userDetails: {},
        buttonLoading: false
    };
    formRef = React.createRef();

    componentDidMount() {
        const { serviceDetail } = this.props;
        if (serviceDetail) {
            if (serviceDetail.marketing_forms.includes('other')) {
                this.setState({
                    marketing_forms: serviceDetail.marketing_forms[serviceDetail.marketing_forms.length - 1],
                    marketing_formsShow: true
                });
                serviceDetail.marketing_forms.pop()
            }
            this.formRef.current.setFieldsValue({
                name: serviceDetail.name,
                business: serviceDetail.business,
                web_url: serviceDetail.web_url,
                marketing_forms: serviceDetail.marketing_forms,
                current_tools: serviceDetail.current_tools,
                goals: serviceDetail.goals,
                ltv_goal: serviceDetail.ltv_goal,
                customer_personas: serviceDetail.customer_personas,
                cost_to_customer: serviceDetail.cost_to_customer,
                roi: serviceDetail.roi,
                customer_percentage: serviceDetail.customer_percentage,
                sales_steps: serviceDetail.sales_steps,
                leads: serviceDetail.leads,
                customer_need_info: serviceDetail.customer_need_info,
                problem_solve: serviceDetail.problem_solve,
                past_approaches: serviceDetail.past_approaches,
                improving_metrics: serviceDetail.improving_metrics,
            })

        }
    }

    onFinish = values => {
        this.setState({ buttonLoading: true });
        let userDetails = { ...this.state.userDetails };
        if (this.state.marketing_forms) {
            values.marketing_forms.push(this.state.marketing_forms)
        }
        values.project = this.props.projectId;
        if (userDetails.id || this.props.serviceDetail) {
            let id = userDetails.id ? userDetails.id : this.props.serviceDetail.id;
            updateIntegratedMarketing(id, values).then((response) => {
                this.props.onSubmit();
                if (!this.props.serviceType) message.success("Details Updated Successfully");
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    });
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong");
                    this.setState({ buttonLoading: false })
                }
            })
        } else {
            setIntegratedMarketing(values).then((response) => {
                this.props.onSubmit();
                this.setState({ userDetails: response.data });
                if (!this.props.serviceType) message.success("Form Submitted");
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    });
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong");
                    this.setState({ buttonLoading: false })
                }
            })
        }
    };
    handleOther = (event) => {
        if (event.target.checked === true) {
            this.setState({ [event.target.id]: "", [event.target.id + "Show"]: true })
        } else {
            this.setState({ [event.target.id + "Show"]: false, [event.target.id]: "" })
        }
    };
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    clearForm = () => {
        this.formRef.current.resetFields()
        this.setState({
            marketing_forms: null,
            marketing_formsShow: false,
        })
    }
    render() {
        return (
            <React.Fragment>
                <Form onFinish={this.onFinish} className="otherInputBorder" ref={this.formRef}>
                    <div className="f-row">
                        <h6>Name your project<span>(optional)</span></h6>
                        <Form.Item name="name">
                            <Input className="fr-input" placeholder="Eg. Landing page for my food truck’s website" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What does your business do/sell?</h6>
                        <Form.Item name="business" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What is your existing website?</h6>
                        <Form.Item name="web_url" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What forms of marketing are you currently utilizing? Please check all that apply *</h6>
                        <Form.Item name="marketing_forms" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="none">None</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="printTv">Print TV and Radio</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="emailMarketing">Email Marketing</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="onlineMarketing">Online Marketing</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="socialMedia">Social Media</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="referralWordOfMonth">Referral/Word of Mouth</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="directMail">Direct Mail</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="tradeShow">Trade Shows/Fairs</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="telemarketing">Telemarketing</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="marketing_forms" value="other"
                                            onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify"
                                            value={this.state.marketing_forms} name="marketing_forms"
                                            onChange={this.handleChange} disabled={!this.state.marketing_formsShow} />
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What tools and platforms are you currently using in your marketing efforts?</h6>
                        <Form.Item name="current_tools" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What are your goals? Is this leads, customers, or revenue?</h6>
                        <Form.Item name="goals" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What is the lifetime value (LTV) of each customer? What is your LTV goal?</h6>
                        <Form.Item name="ltv_goal" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Do you have customer personas? Who is your ideal client?</h6>
                        <Form.Item name="customer_personas"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <TextArea rows={4} className="fr-input fr-inputTextArea"
                                placeholder="E.g. I need an email to announce new servic" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How much does it cost you to acquire a customer?</h6>
                        <Form.Item name="cost_to_customer"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What ROI have you seen on your current marketing efforts? What are you currently investing
                            in?</h6>
                        <Form.Item name="roi" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What percentage of your customers is generated through online methods?</h6>
                        <Form.Item name="customer_percentage"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What are the steps in your sales process? What are the common hesitations clients have?</h6>
                        <Form.Item name="sales_steps" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How do you currently find leads? Do you know what your cost per lead is? Do you know what
                            your lead-to-conversion rate is?</h6>
                        <Form.Item name="leads" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Customers" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What information do your customers need in order to make an informed buying decision?</h6>
                        <Form.Item name="customer_need_info"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What problems does your product or service solve?</h6>
                        <Form.Item name="problem_solve" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What sales approaches have worked in the past?</h6>
                        <Form.Item name="past_approaches" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Of the following metrics, which three are you most interested in improving?</h6>
                        <Form.Item name="improving_metrics"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="visits">Visits</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="conversionRate">Conversion rate</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="conversationsLeads">Conversions (i.e. sales or
                                            leads)</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="followers">Followers</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="revenue">Revenue</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="subscribers">Subscribers</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="returnOnAdSpend">Return on ad spend</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="engagementRate">Engagement rate</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="costPerConversion">Cost per conversion</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="row em-form-inner-btn em-btn-all position-sticky edit-footer">
                        {this.props.clearForm && <Button className="bg-transparent border-0 shadow-none w-auto" onClick={this.clearForm}>Clear</Button>}
                        {this.props.serviceType == "inner" &&
                            <Button className="mt-4" onClick={this.props.onClose}>Cancel</Button>}
                        <Button loading={this.state.buttonLoading} type="primary" htmlType="submit" size="large"
                            className="d-inline mt-4 continue-button">SUBMIT</Button>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
} 