import React, { Component } from 'react';
import { Modal, Input, Button, Row, Drawer, Form, message } from 'antd';
import { postResetPassword } from '../../Controller/API/UserAPI';
import { toggleLoginAction } from "../../redux/Actions/userActions";
import './style.scss';
import PasswordChanged from '../PasswordChanged';


export default class ResetPassword extends Component {
    state = {
        passwordSuccess: false,
        loading: false
    }
    formRef = React.createRef();
    onSuccess = () => {
        this.props.onSuccess()
        this.setState({ passwordSuccess: false })
    }
    onFinish = values => {
        if (values.newPass === values.confirmPass) {
            this.setState({ loading: true })
            let data = {
                device: this.props.device,
                password: values.newPass
            }
            postResetPassword(data).then((response) => {
                this.formRef.current.resetFields();
                this.setState({ passwordSuccess: true, loading: false })
            }).catch((err) => {
                this.setState({ loading: false })
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    })
                } else {
                    message.error("something went wrong")
                }
            })
        }
        else {
            message.error("Password not matched!")
        }
    }
    render() {
        return (
            <div className="reset-password-container">
                <Form onFinish={this.onFinish} ref={this.formRef}>
                    <Drawer
                        className="em-main-drawer lc-modal"
                        placement="right"
                        closable={false}
                        onClose={this.props.onClose}
                        visible={this.props.visible}
                        getContainer={false}
                        width={"37%"}
                        style={{ position: 'absolute' }}
                    >
                        <h2>Reset Password</h2>
                        <label className="mt-3 mb-1">New Password</label>
                        <Form.Item name="newPass" rules={[{ required: true, message: 'This field is required' },
                        { min: 8, message: 'Password must be 8 characters long.' }]}>
                            <Input.Password placeholder="••••••••••" />
                        </Form.Item>
                        <label className="mt-2 mb-1">Re-enter Password</label>
                        <Form.Item name="confirmPass" rules={[{ required: true, message: 'This field is required' },
                        { min: 8, message: 'Password must be 8 characters long.' }]}>
                            <Input.Password placeholder="••••••••••" />
                        </Form.Item>
                        <Row className="mt-5">
                            <Button type="text" onClick={this.props.onClose} className="w-auto cancel-button">BACK</Button>
                            <Button type="primary" loading={this.state.loading} htmlType="submit" className="ml-4 send-button">RESET</Button>
                        </Row>
                    </Drawer>
                </Form>
                <PasswordChanged visible={this.state.passwordSuccess} onSuccess={this.onSuccess} />
            </div>
        )
    }
}
