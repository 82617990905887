import React, { Component } from 'react';
import './style.scss'
import { Button, Table, Tabs, message, Pagination } from "antd";
import { Image as Images } from "../Images";
import { withRouter } from "react-router-dom";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import { getProjectInfo } from '../../Controller/API/ProjectAPI';
import { filterData } from '../../Controller/helper';
import { status } from '../../Controller/Global';
import routes from '../../Controller/Routes';
import { reverse } from 'named-urls';
import moment from "moment";

const { TabPane } = Tabs;

const pagination = Pagination;
pagination.pageSize = 10;

class ProjectAll extends Component {
    state = {
        projects: [],
        pagination: pagination,
        loading: true,
        activeTab: 'all'
    };
    columns = [
        {
            title: <div>Project</div>,
            dataIndex: 'name',
            render: (name) => <div className="d-flex align-items-center">
                <span className="icon-img">
                    <img alt="folder web icon" src={Images.project_folder} />
                </span>
                <div className="text-div-inn">
                    {name}
                </div>
            </div>,
        },
        {
            title: 'Services',
            dataIndex: 'services',
            render: (services) => {
                return <div><label>{services[0] ? services[0].title : ""} {services[1] ? ", " + services[1].title : ""}{services.length > 2 && ` and ${services.length - 2} more...`}</label></div>
            }
        },
        {
            title: <div className="d-flex align-items-center align-items-center">Date Created <img
                src={Images.arrow_down} alt="arrow down" className="img-fluid ml-1" /></div>,
            dataIndex: 'created',
            render: (data) => moment(data).format("ll")
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: text => <div>
                {/* <Button className="text-uppercase danger-btn-error">{text}</Button> */}
                <Button className="text-uppercase progress-btn-yellow status-btn">{text}</Button>
                {/*<Button className="text-uppercase success-btn-green">In Progress</Button>*/}
                {/*<Button className="text-uppercase success-btn-complete">Completed</Button>*/}
            </div>,
        },
    ];

    componentDidMount() {
        if (this.props.location.state) {
            this.callback(this.props.location.state.status)
        } else this.callback("all")
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.location.state) {
            this.setState({ activeTab: this.props.location.state.status })
            this.props.history.replace(this.props.location.state, null)
        }
    }
    callback = (key) => {
        this.setState({ loading: true });
        let params = { status: key };
        if (key == "all") params = {};
        getProjectInfo(params).then((response) => {
            let { pagination } = this.state;
            pagination.total = response.data.count;
            this.setState({ projects: response.data.results, pagination, loading: false, activeTab: key })
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                });
                this.setState({ loading: false })
            } else {
                message.error("something went wrong")
            }
        })
    };
    fetch = (params = {}) => {
        getProjectInfo(params).then((response) => {
            let { pagination } = this.state;
            pagination.total = response.data.count;
            this.setState({ projects: response.data.results, pagination, loading: false })
        }).catch((err) => {
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    };
    handleChange = (pagination, filters, sorter) => {
        let { params } = this.state;
        let symbol = sorter.order === 'descend' ? '-' : '';
        params = { ...params, page: pagination.current, ordering: `${symbol}${sorter.columnKey}` };
        this.setState({ params });
        this.fetch(params)
    };

    render() {
        const { projects, pagination, loading, activeTab } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid project-contain-main em-main-container-fluid em-container-fluid-extra">
                    <div className="container">
                        <div className="row em-main-row-all align-items-center m-0">
                            <div className="col-12 r-pad-0">
                                <div className="row em-tabs-details-main position-relative m-0 ">
                                    <Button onClick={() => this.props.history.push({
                                        pathname: routes.dashboard.projectCreation,
                                        state: { addProject: true }
                                    })}
                                        className="em-new-project-btn border-0 text-uppercase d-flex align-items-center justify-content-center"><PlusOutlined
                                            className="mr-1" /> New Project</Button>
                                    <Tabs className="w-100" activeKey={activeTab} onChange={this.callback} type="card">
                                        <TabPane tab={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-div">All</div>
                                                <span
                                                    className="num-div">{activeTab === "all" && pagination.total}</span>

                                            </div>
                                        } key="all">
                                            <div className="col-12">
                                                <div className="row em-left-column em-service-table-card">
                                                    <div className="col-12 p-0">
                                                        <Table
                                                            className="table-responsive em-service-table"
                                                            columns={this.columns}
                                                            dataSource={this.state.projects || []}
                                                            size="middle"
                                                            pagination={{ ...pagination }}
                                                            loading={loading}
                                                            onChange={this.handleChange}
                                                            onRow={(record, rowIndex) => {
                                                                return {
                                                                    onClick: event => {
                                                                        this.props.history.push(reverse(routes.dashboard.projects.details, { id: record.id }))
                                                                    },
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tab={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-div">Pending</div>
                                                <span
                                                    className="num-div">{activeTab === "Pending" && pagination.total}</span>
                                            </div>
                                        } key="Pending">
                                            <div className="col-12">
                                                <div className="row em-left-column em-service-table-card">
                                                    <div className="col-12 p-0">
                                                        <Table
                                                            className="table-responsive em-service-table"
                                                            columns={this.columns}
                                                            dataSource={this.state.projects || []}
                                                            size="middle"
                                                            loading={loading}
                                                            onRow={(record, rowIndex) => {
                                                                return {
                                                                    onClick: event => {
                                                                        this.props.history.push(reverse(routes.dashboard.projects.details, { id: record.id }))
                                                                    },
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tab={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-div">In Progress</div>
                                                <span
                                                    className="num-div">{activeTab === "In_Progress" && pagination.total}</span>
                                            </div>
                                        } key="In_Progress">
                                            <div className="col-12">
                                                <div className="row em-left-column em-service-table-card">
                                                    <div className="col-12 p-0">
                                                        <Table
                                                            className="table-responsive em-service-table"
                                                            columns={this.columns}
                                                            dataSource={this.state.projects || []}
                                                            size="middle"
                                                            loading={loading}
                                                            onRow={(record, rowIndex) => {
                                                                return {
                                                                    onClick: event => {
                                                                        this.props.history.push(reverse(routes.dashboard.projects.details, { id: record.id }))
                                                                    },
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tab={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-div">Completed</div>
                                                <span
                                                    className="num-div">{activeTab === "Completed" && pagination.total}</span>
                                            </div>
                                        } key="Completed">
                                            <div className="col-12">
                                                <div className="row em-left-column em-service-table-card">
                                                    <div className="col-12 p-0">
                                                        <Table
                                                            className="table-responsive em-service-table"
                                                            columns={this.columns}
                                                            dataSource={this.state.projects || []}
                                                            size="middle"
                                                            loading={loading}
                                                            onRow={(record, rowIndex) => {
                                                                return {
                                                                    onClick: event => {
                                                                        this.props.history.push(reverse(routes.dashboard.projects.details, { id: record.id }))
                                                                    },
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tab={
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="text-div">Canceled</div>
                                                <span
                                                    className="num-div">{activeTab === "Cancelled" && pagination.total}</span>
                                            </div>
                                        } key="Cancelled">
                                            <div className="col-12">
                                                <div className="row em-left-column em-service-table-card">
                                                    <div className="col-12 p-0">
                                                        <Table
                                                            className="table-responsive em-service-table"
                                                            columns={this.columns}
                                                            dataSource={this.state.projects || []}
                                                            size="middle"
                                                            loading={loading}
                                                            onRow={(record, rowIndex) => {
                                                                return {
                                                                    onClick: event => {
                                                                        this.props.history.push(reverse(routes.dashboard.projects.details, { id: record.id }))
                                                                    },
                                                                };
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default withRouter(ProjectAll);