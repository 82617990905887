import React, { Component } from 'react';
import { Button, Table } from "antd";
import { Image as Images } from "../Images";
import { Link } from "react-router-dom";
import { ProjectDetailHead } from './ProjectDetailHead';

class ProjectActivity extends Component {

    columns = [
        {
            title: <div>Date</div>,
            dataIndex: 'date',
        },
        {
            title: <div className="d-flex align-items-center align-items-center">Activity</div>,
            dataIndex: 'activity',
        },
        {
            title: 'By',
            dataIndex: 'by',
        },
    ];
    data = [
        {
            key: '1',
            date: <div>Aug 5, 2020  <span className="ml-1">8.30AM</span></div>,
            activity: <div className="d-flex align-items-center">New Restaurant Website’s brief has been updated <Link to={''}>View</Link></div>,
            by: <div>You</div>,
        }, {
            key: '2',
            date: <div>Aug 5, 2020  <span className="ml-1">8.30AM</span></div>,
            activity: <div className="d-flex align-items-center">Web Layout has been added <Link to={''}>View</Link></div>,
            by: <div>Designer</div>,
        }, {
            key: '3',
            date: <div>Aug 5, 2020  <span className="ml-1">8.30AM</span></div>,
            activity: <div className="d-flex align-items-center">New Restaurant Website’s brief has been updated <Link to={''}>View</Link></div>,
            by: <div>You</div>,
        }, {
            key: '4',
            date: <div>Aug 5, 2020  <span className="ml-1">8.30AM</span></div>,
            activity: <div className="d-flex align-items-center">New Restaurant Website’s brief has been updated <Link to={''}>View</Link></div>,
            by: <div>You</div>,
        }, {
            key: '5',
            date: <div>Aug 5, 2020  <span className="ml-1">8.30AM</span></div>,
            activity: <div className="d-flex align-items-center">New Restaurant Website’s brief has been updated <Link to={''}>View</Link></div>,
            by: <div>You</div>,
        }, {
            key: '6',
            date: <div>Aug 5, 2020  <span className="ml-1">8.30AM</span></div>,
            activity: <div className="d-flex align-items-center">New Restaurant Website’s brief has been updated <Link to={''}>View</Link></div>,
            by: <div>You</div>,
        }, {
            key: '7',
            date: <div>Aug 5, 2020  <span className="ml-1">8.30AM</span></div>,
            activity: <div className="d-flex align-items-center">New Restaurant Website’s brief has been updated <Link to={''}>View</Link></div>,
            by: <div>You</div>,
        }, {
            key: '8',
            date: <div>Aug 5, 2020  <span className="ml-1">8.30AM</span></div>,
            activity: <div className="d-flex align-items-center">New Restaurant Website’s brief has been updated <Link to={''}>View</Link></div>,
            by: <div>You</div>,
        },
    ];

    render() {
        const { serviceDetail } = this.props
        return (
            <React.Fragment>
                <div className="col-12">
                    <div className="row em-service-table-card bg-transparent shadow-none em-table-invoices-card">
                        <div className="col-12 p-0">
                            <div className="row billing-info-row-heading justify-content-between">
                                <ProjectDetailHead serviceDetail={serviceDetail} content="9 Activity" />
                            </div>
                            <div className="col-12">
                                <div className="row em-service-table-card em-table-invoices-card">
                                    <div className="col-12 p-0">
                                        <Table
                                            className="table-responsive em-service-table em-project-activity-table"
                                            columns={this.columns}
                                            dataSource={this.data}
                                            size="middle"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ProjectActivity;