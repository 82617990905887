import React, { Component } from 'react';
import { Button, Drawer, message, Collapse, Form, Input, Upload } from 'antd';
import { Image as Images } from "../Images";
import { services, myServices } from '../../Controller/Global';
import WebsiteDesign from '../ProjectCreation/Services/WebsiteDesign';
import SearchEngineOptimization from '../ProjectCreation/Services/SearchEngineOptimize';
import EmailMarketing from '../ProjectCreation/Services/EmailMarketing';
import ContentGeneration from '../ProjectCreation/Services/ContentGeneration';
import IntegratingMarketing from '../ProjectCreation/Services/IntegratingMarketingStrategies';
import Retargeting from '../ProjectCreation/Services/Retargeting';
import SocialMediaMarketing from '../ProjectCreation/Services/SocialMediaMarketing';
import SearchEngineMarketing from '../ProjectCreation/Services/SearchEngineMarketing';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
const { TextArea } = Input;
const { Panel } = Collapse;


class AddServiceDrawer extends Component {
    onSubmit = () => {
        this.closeAllCollapse()
    }
    closeAllCollapse = () => {
        var x = document.querySelectorAll(".ant-collapse-item-active");
        if (x.length) {
            for (var i = 0; i < x.length; i++) {
                let element = document.getElementById("services-main");
                element.scrollIntoView();
                setTimeout(function () {
                    var el = document.querySelector(".ant-collapse-item-active");
                    let allPanel = document.querySelectorAll(".ant-collapse-item")
                    let index = Array.prototype.indexOf.call(allPanel, el)
                    console.log("el", el)
                    if (el) el.children[0].click();
                    if (allPanel[index + 1]) allPanel[index + 1].children[0].click()
                }, 100);
            }
        }
    }


    render() {
        const { projectId } = this.props.match.params.id
        const { addExistService, servicesDetails } = this.props
        return (
            <React.Fragment>
                <Drawer
                    className="em-main-drawer em-address-drawer addServiceDrawer"
                    title={
                        <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex align-items-center">
                                Add Service
                            {/* <span>Website Design & Development</span> */}
                            </div>
                            <Button onClick={this.props.onClose} className="close-btn-drawer bg-transparent border-0 shadow-none p-0 d-flex align-items-center">Close <img alt="close icon" className="img-fluid ml-1" src={Images.close_icon_white} /></Button>
                        </div>
                    }
                    placement="right"
                    closable={false}
                    onClose={this.props.onClose}
                    visible={this.props.visible}
                    getContainer={false}
                    width={"50%"}
                    style={{ position: 'absolute' }}>
                    <div className="row em-drawer-main-row" id="services-main">
                        <div className="col-12">
                            <Form className="mt-4" onFinish={this.onFinish}>
                                <Collapse accordion defaultActiveKey={[addExistService.length && addExistService[0].id]} className="form-collapse border-0 bg-transparent">
                                    {addExistService.map(_ => (_.model === services.WEBSITE_DESIGN_DEVELOPMENT) && <Panel header="Website Design & Development" key={_.id}>
                                        <WebsiteDesign onSubmit={this.onSubmit} clearForm={true} projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} /></Panel>
                                    )}
                                    {addExistService.map(_ => (_.model === services.SEARCH_ENGINE_OPTIMIZATION) && <Panel className="mt-4" header="Search Engine Optimization" key={_.id}>
                                        <SearchEngineOptimization onSubmit={this.onSubmit} clearForm={true} projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} /></Panel>
                                    )}
                                    {addExistService.map(_ => (_.model === services.EMAIL_MARKETING) && <Panel className="mt-4" header="Email Marketing" key={_.id}>
                                        <EmailMarketing onSubmit={this.onSubmit} clearForm={true} projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} /></Panel>
                                    )}
                                    {addExistService.map(_ => _.model === services.CONTENT_GENERATION && <Panel className="mt-4" header="Content Generation" key={_.id}>
                                        <ContentGeneration onSubmit={this.onSubmit} clearForm={true} projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} /></Panel>
                                    )}
                                    {addExistService.map(_ => _.model === services.INTEGRATING_ONLINE_MARKETING_STRATEGIES && <Panel className="mt-4" header="Integrated Marketing Strategies" key={_.id}>
                                        <IntegratingMarketing onSubmit={this.onSubmit} clearForm={true} projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} /></Panel>
                                    )}
                                    {addExistService.map(_ => _.model === services.RETARGETING && <Panel className="mt-4" header="Retargeting" key={_.id}>
                                        <Retargeting onSubmit={this.onSubmit} clearForm={true} projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} /></Panel>
                                    )}
                                    {addExistService.map(_ => (_.model === services.SOCIAL_MEDIA_MARKETING) && <Panel className="mt-4" header="Social Media Marketing" key={_.id}>
                                        <SocialMediaMarketing onSubmit={this.onSubmit} clearForm={true} projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} /></Panel>
                                    )}
                                    {addExistService.map(_ => (_.model === services.SEARCH_ENGINE_MARKETING) && <Panel className="mt-4" header="SEMM - PPC BRIEF" key={_.id}>
                                        <SearchEngineMarketing onSubmit={this.onSubmit} clearForm={true} projectId={this.props.match.params.id} serviceDetail={servicesDetails[myServices[_.model]]} /></Panel>
                                    )}
                                </Collapse>
                            </Form>
                        </div>
                    </div>
                    <div className="row em-form-inner-btn em-btn-all">
                        <div className="col-12 text-right">
                            {/* <Button className="bg-transparent border-0 shadow-none w-auto">Clear</Button> */}
                            {/* <Button onClick={this.props.onClose}>Back</Button> */}
                            <Button type="primary" onClick={this.props.onClose}>Continue</Button>
                        </div>
                    </div>
                </Drawer>
            </React.Fragment>
        );
    }
}
export default withRouter(AddServiceDrawer)