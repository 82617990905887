import logo_main from '../assets/logo.png'
import footer_logo from '../assets/footer-logo.png'
import user_icon from '../assets/user-icon.svg'
import twitter_icon from '../assets/twitter.svg'
import facebook_icon from '../assets/facebook.svg'
import instagram_icon from '../assets/instagram.svg'
import linkdin_icon from '../assets/linkdin.svg'
import design_service from '../assets/design-service.svg'
import sco_service from '../assets/SEO.svg'
import sem_service from '../assets/SEM.svg'
import smm_service from '../assets/SMM.svg'
import email_marketing_service from '../assets/email-market.svg'
import reta_rg_et_ing_service from '../assets/retargeting.svg'
import content_generation_service from '../assets/content-generation.svg'
import io_ms_service from '../assets/IOMS.svg'
import remove_icon_main from '../assets/remove.svg'


import table_folder_web_icon from '../assets/images/icons/web-design-dev.svg'
import menu_ellipsis from '../assets/images/icons/menu-elepsis.svg'
import email_icon from '../assets/images/icons/email-marketing.svg'
import project_folder from '../assets/images/icons/project-folder.svg'
import invoice_icon from '../assets/images/icons/invoice-icon.svg'
import proposal_invoice_icon from '../assets/images/icons/proposal-invoice-icon.svg'
import arrow_down from '../assets/images/icons/arrow_down.svg'

import account_info from '../assets/images/icons/account-tie-outline.svg'
import password_key from '../assets/images/icons/key.svg'
import billing_info from '../assets/images/icons/billing-info.svg'
import logout_key_icon from '../assets/images/icons/logout.svg'
import arrow_left_breadcrumb from '../assets/images/icons/chevron-right.svg'
import edit_icon from '../assets/images/icons/edit.svg'
import success_icon from '../assets/images/icons/success-icon.svg'
import carbon_close_btn from '../assets/images/icons/carbon_close.svg'
import activity_icon from '../assets/images/icons/activity.svg'
import document_icon from '../assets/images/icons/project.svg'
import proposal_icon from '../assets/images/icons/proposal.svg'
import invoices_black from '../assets/images/icons/invoice-black.svg'
import service_icon from '../assets/images/icons/services.svg'
import project_info_black from '../assets/images/icons/project-info-black.svg'
import left_arrow_small_black from '../assets/images/icons/left-arrow-small-black.svg'
import web_design_acc_black from '../assets/images/icons/web-design-dev-black.svg'
import social_media_marketing from '../assets/images/icons/social-media-marketing.svg'
import edit_brief_icon from '../assets/images/icons/edit-brief-icon.svg'


import upload_icon from '../assets/upload-icon.svg'
import download_icon from '../assets/images/icons/download.svg'
import delete_icon from '../assets/images/icons/trash.svg'
import images_icon from '../assets/images/icons/image-icon.svg'
import close_icon_white from '../assets/images/icons/close-icon-white.svg'
import add_icon from '../assets/images/icons/add.svg'
import file_icon from '../assets/images/icons/file-icon.svg'
import remove_icon from '../assets/images/icons/remove-icon.svg'
import selected_icon from '../assets/selected.svg'

import account_tie from '../assets/account-tie-outline.svg'
import project_info from '../assets/project-info.svg'
import services_icon from '../assets/services.svg'
import time_budget from '../assets/time-budget.svg'

export let Image = {
    footer_logo, remove_icon, file_icon, add_icon, account_tie, project_info, services_icon, time_budget, selected_icon, logo_main, user_icon, twitter_icon, facebook_icon, instagram_icon, linkdin_icon, design_service, sco_service, sem_service,
    smm_service, email_marketing_service, reta_rg_et_ing_service, content_generation_service, io_ms_service, remove_icon_main,
    table_folder_web_icon, menu_ellipsis, email_icon, project_folder, invoice_icon, proposal_invoice_icon, arrow_down,
    account_info, password_key, billing_info, logout_key_icon, arrow_left_breadcrumb, edit_icon, success_icon, carbon_close_btn,
    activity_icon, document_icon, proposal_icon, invoices_black, service_icon, project_info_black, left_arrow_small_black, web_design_acc_black,
    social_media_marketing, edit_brief_icon, upload_icon, download_icon, delete_icon, images_icon, close_icon_white
};