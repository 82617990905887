import React, { Component } from 'react';
import './style.scss'
import { Button, message, Table } from "antd";
import { Image as Images } from "../Images";
import { getProposals } from "../../Controller/API/ProjectAPI";
import moment from 'moment'

class Proposals extends Component {
    state = {
        proposals: [],
        loading: true
    };
    columns = [
        {
            title: <div>Name</div>,
            dataIndex: 'name',
            render: (name) => <div className="d-flex align-items-center">
                <span className="icon-img">
                    <img alt="folder web icon" className="img-fluid" src={Images.proposal_invoice_icon} />
                </span>
                <div className="text-div-inn">
                    {name}
                </div>
            </div>

        },
        {
            title: <div className="d-flex align-items-center align-items-center">Date Created <img
                src={Images.arrow_down} alt="arrow down" className="img-fluid ml-1" /></div>,
            dataIndex: 'created',
            render: (data) => moment(data).format("ll")
        },
    ];

    componentDidMount() {
        this.fetch()
    }

    fetch = (params = {}) => {
        getProposals(params).then(res => {
            this.setState({ proposals: res.data.results, loading: false })
        }).catch(e => {
            message.error('Error while fetching Proposals')
        })
    };
    handleChange = (pagination, filters, sorter) => {
        let { params } = this.state;
        let symbol = sorter.order === 'descend' ? '-' : '';
        params = { ...params, page: pagination.current, ordering: `${symbol}${sorter.columnKey}` };
        this.setState({ params });
        this.fetch(params)
    };

    render() {
        let { pagination, loading, proposals } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid em-main-container-fluid">
                    <div className="container">
                        <div className="row m-0 em-main-row-all align-items-center">
                            <div className="col-12">
                                <div className="row em-service-table-card em-proposal-table-card">
                                    <div className="col-12 p-0">
                                        <Table
                                            className="full-width table-responsive em-service-table"
                                            columns={this.columns}
                                            dataSource={proposals}
                                            onChange={this.handleChange}
                                            pagination={{ ...pagination }}
                                            loading={loading}
                                            size="middle"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

export default Proposals;