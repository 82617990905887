import { getAPIUrl } from '../Global';
import { Post, Get, Patch, Remove } from "../headerIntercepter";

export function getProjectInfo(data) {
    const url = getAPIUrl('project.info.self');
    return Get(url, data)
}

export function getProjectInfobyId(id, data) {
    const url = getAPIUrl('project.info.id', { id });
    return Get(url, data)
}

export function setProjectInfo(data) {
    const url = getAPIUrl('project.info.self');
    return Post(url, data)
}

export function updateProjectInfo(id, data) {
    const url = getAPIUrl('project.info.id', { id });
    return Patch(url, data)
}

export function getServices(data) {
    const url = getAPIUrl('project.service');
    return Get(url, data)
}

export function getProposals(data) {
    const url = getAPIUrl('project.proposal');
    return Get(url, data)
}

// Upload files
export function uploadFiles(data) {
    const url = getAPIUrl('project.uploadDocument');
    return Post(url, data)
}

// get budget
export function getBudgetDetail(data) {
    const url = getAPIUrl('project.budget.self');
    return Get(url, data)
}

export function setBudgetDetail(data) {
    const url = getAPIUrl('project.budget.self');
    return Post(url, data)
}
export function updateBudgetDetail(id, data) {
    const url = getAPIUrl('project.budget.id', { id });
    return Patch(url, data)
}
export function getInvoice(data) {
    const url = getAPIUrl('project.invoice');
    return Get(url, data)
}
export function getDocument(project, data) {
    const url = getAPIUrl('project.document.project', { project });
    return Get(url, data)
}
export function uploadProjectDocument(project, data) {
    const url = getAPIUrl('project.document.project', { project });
    return Post(url, data)
}
export function removeProjectDocument(project, id, data) {
    const url = getAPIUrl('project.document.delete', { project, id });
    return Remove(url, data)
}

export function removeDocument(id, data) {
    const url = getAPIUrl('project.deleteDocument.id', { id });
    return Remove(url, data)
}

export function setSubscriber(data) {
    const url = getAPIUrl('project.subscriber');
    return Post(url, data, false)
}