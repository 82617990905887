import React, { Component } from 'react';
import { Button, message } from "antd";
import { Image as Images } from "../Images";
import EditAccountInfo from "../Drawers/EditAccountInfo";
import { getUserProfile, userIndustry } from '../../Controller/API/UserAPI';
import { SuccessPopup } from '../modals/SuccessPopup';

class AccountInfoMain extends Component {

    state = {
        accountInfoVisible: false,
        userProfile: {},
        industry: [],
        success: false
    };
    componentDidMount() {
        getUserProfile().then((response) => {
            if (response.data.industry) this.fetchIndustry()
            this.setState({ userProfile: response.data })
        }).catch((err) => {
            this.setState({ loading: false });
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else { message.error("something went wrong") }
        })
    }
    fetchIndustry = () => {
        userIndustry(null).then(response => {
            this.setState({ industry: response.data.results })
        }).catch((err) => {
            this.setState({ loading: false });
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    };
    showAccountInfoDrawer = (visible) => {
        this.setState({
            accountInfoVisible: visible,
        })
    };
    profileUpdateSuccess = () => {
        getUserProfile().then((response) => {
            if (response.data.industry) this.fetchIndustry()
            this.setState({ userProfile: response.data, success: true })
            let data = {
                email: this.props.user.user.email,
                name: response.data.name,
                phone: response.data.phone,
                id: this.props.user.user.id
            }
            this.props.updateUser(data)
        }).catch((err) => {
            this.setState({ loading: false });
            if (err.response) {
                Object.keys(err.response.data).map((i) => {
                    message.error(err.response.data[i])
                })
            } else {
                message.error("something went wrong")
            }
        })
    }
    handlePopUp = () => {
        this.setState({ success: false })
    }

    render() {
        const { userProfile, industry, success } = this.state
        return (
            <React.Fragment>
                <div className="col-12">
                    <div className="row em-service-table-card bg-transparent shadow-none em-table-invoices-card">
                        <div className="col-12 p-0">
                            <h5 className="heading-info-div">Account Info</h5>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className="row card em-card-custom">
                                        <div className="col-12">
                                            <div className="row mx-0 em-card-inner-details">
                                                <div className="col-12">
                                                    <h5 className="t-capitalize">{userProfile.name}</h5>
                                                </div>
                                                <div className="col-12 col-sm-3 pr-2">
                                                    <h6>Email</h6>
                                                    <p className="mb-0">{userProfile.email}</p>
                                                </div>
                                                <div className="col-12 col-sm-3">
                                                    <h6>Phone</h6>
                                                    <p className="mb-0">{userProfile.phone || "-"}</p>
                                                </div>
                                            </div>

                                            <div className="row mx-0 em-card-inner-details">
                                                <div className="col-12">
                                                    <h5>Company Detail</h5>
                                                </div>
                                                <div className="col-12 col-sm-3 pr-2">
                                                    <h6>Company</h6>
                                                    <p className="mb-0 t-capitalize">{userProfile.company_name || "-"}</p>
                                                </div>
                                                <div className="col-12 col-sm-3">
                                                    <h6>Industry</h6>
                                                    <p className="mb-0">{userProfile.industry && industry.length ? industry.find(x => x.id === userProfile.industry).title : "-"}</p>
                                                </div>
                                                <div className="col-12 col-sm-4">
                                                    <h6>Website & Social media</h6>
                                                    <p className="mb-0">{userProfile.url || "-"}</p>
                                                    {/* <p className="mb-0">www.facebook.com/panyname</p> */}
                                                </div>
                                            </div>

                                            <div className="row mx-0 em-card-inner-details">
                                                <div className="col-12">
                                                    <h5>Company Contact</h5>
                                                </div>
                                                <div className="col-12 col-sm-3 pr-2">
                                                    <h6>Contact</h6>
                                                    <p className="mb-0 t-capitalize">{userProfile.contact_person || "-"}</p>
                                                </div>
                                                <div className="col-12 col-sm-3">
                                                    <h6>Phone</h6>
                                                    <p className="mb-0">{userProfile.company_phone || "-"}</p>
                                                </div>
                                                <div className="col-12 col-sm-3">
                                                    <h6>Email</h6>
                                                    <p className="mb-0">{userProfile.company_email || "-"}</p>
                                                </div>
                                                <div className="col-12 col-sm-3">
                                                    <h6>Address</h6>
                                                    <p className="mb-0 t-capitalize">{userProfile.address || "-"}</p>
                                                </div>
                                            </div>

                                            <div className="row mx-0 em-card-inner-details">
                                                <div className="col-12">
                                                    <Button onClick={() => this.showAccountInfoDrawer(true)}
                                                        className="d-flex align-items-center justify-content-center">
                                                        <img src={Images.edit_icon} alt=""
                                                            className="img-fluid" />
                                                        Edit Account Info
                                                    </Button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.accountInfoVisible && <EditAccountInfo userProfile={this.state.userProfile} visible={this.state.accountInfoVisible} profileUpdateSuccess={this.profileUpdateSuccess} onClose={() => this.showAccountInfoDrawer(false)} />}
                {success && <SuccessPopup message={"Your Account Info has been Updated!"} onClose={this.handlePopUp} />}
            </React.Fragment>
        );
    }
}

export default AccountInfoMain;