import React, { Component } from 'react';
import { Button, Table } from 'antd';
import { withRouter } from "react-router-dom";

class ViewInvoice extends Component {
    columns = [
        {
            title: 'Service',
            dataIndex: 'service',
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Open Balance',
            dataIndex: 'openBalance',
        },
        {
            title: 'Amount Paid',
            dataIndex: 'amountPaid',
        },
        {
            title: 'Total',
            dataIndex: 'total',
        },
    ];
    data = [
        {
            key: '1',
            service: 'Website Design & Development',
            description: 'Landing page creation for TheLoadedArepa.com',
            openBalance: '$400.00',
            amountPaid: '$400.00',
            total: '$800.00',
        },
        {
            key: '2',
            service: 'Website Design & Development',
            description: 'Landing page creation for TheLoadedArepa.com',
            openBalance: '$400.00',
            amountPaid: '$400.00',
            total: '$800.00',
        },
        {
            key: '3',
            service: 'Website Design & Development',
            description: 'Landing page creation for TheLoadedArepa.com',
            openBalance: '$400.00',
            amountPaid: '$400.00',
            total: '$800.00',
        },
        {
            key: '4',
            service: 'Website Design & Development',
            description: 'Landing page creation for TheLoadedArepa.com',
            openBalance: '$400.00',
            amountPaid: '$400.00',
            total: '$800.00',
        },
    ];

    total_columns = [
        {
            title: 'Service',
            dataIndex: 'total_text',

        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Open Balance',
            dataIndex: 'openBalance',
        },
        {
            title: 'Amount Paid',
            dataIndex: 'amountPaid',
        },
        {
            title: 'Total',
            dataIndex: 'total',
        },
    ];
    total_data = [
        {
            key: '1',
            total_text: 'Total:',
            description: '',
            openBalance: '$1,600.00',
            amountPaid: '$1,600.00',
            total: '$3,200.00',
        },
    ];

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid em-main-container-fluid em-container-fluid-invoice-white">
                    <div className="container">
                        <div className="row em-main-row-all align-items-center">
                            <div className="col-12">
                                <div className="row em-invoice-details-1">
                                    <div className="col-12 col-sm-12 col-md-10">
                                        <div className="row em-invoice-text-details">
                                            <div className="col-12 col-sm-4 col-md-2">
                                                <h6>Invoice #</h6>
                                                <p>1008</p>
                                            </div>
                                            <div className="col-12 col-sm-4 col-md-2">
                                                <h6>Project</h6>
                                                <p>Landing Page Redesign</p>
                                            </div>
                                            <div className="col-12 col-sm-4 col-md-2">
                                                <h6>Date Created</h6>
                                                <p>08/11/2020</p>
                                            </div>
                                            <div className="col-12 col-sm-4 col-md-2">
                                                <h6>Due Date</h6>
                                                <p>10/11/2020</p>
                                            </div>
                                            <div className="col-12 col-sm-4 col-md-2">
                                                <h6>Status</h6>
                                                <p>50% Paid</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-2">
                                        <div className="row em-open-balance-row">
                                            <div className="col-12">
                                                <h5 className="text-right">Open Balance</h5>
                                                <h1 className="text-right text-uppercase">$1,600.00</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid em-main-container-fluid em-container-fluid-invoice">
                    <div className="container">
                        <div className="row em-main-row-all align-items-center">
                            <div className="col-12">
                                <div className="em-invoice-table-row row">
                                    <div className="col-12">
                                        <Table
                                            bordered
                                            className="table-responsive em-invoice-table"
                                            columns={this.columns}
                                            dataSource={this.data}
                                            size="middle"
                                        />
                                    </div>
                                    <div className="col-12">
                                        <Table
                                            className="table-responsive em-invoice-table-total"
                                            columns={this.total_columns}
                                            dataSource={this.total_data}
                                            size="middle"
                                        />
                                    </div>
                                </div>
                                <div className="row em-btn-all">
                                    <div className="col-12 text-right">
                                        <Button onClick={() => this.props.history.push('/dashboard/pay-invoice/')} type="primary" className="text-uppercase">pay now</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(ViewInvoice);