import React from "react";
import { clearUserToken, clearTempToken } from "./AuthService";
import { history } from "./History";
import Routes from "./Routes";
import { withRouter } from "react-router-dom";


class Logout extends React.Component {

    componentDidMount() {
        clearUserToken();
        clearTempToken();
        this.props.history.push(Routes.login)
    }

    render() {
        return (
            <div />
        );
    }
}

export default withRouter(Logout)