import React, { Component } from 'react';
import { Input, Slider, Radio, Button, Form, DatePicker } from 'antd';
import Routes from '../../Controller/Routes';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { setBudgetDetail } from '../../Controller/API/ProjectAPI';

const { RangePicker } = DatePicker;

class TimeBudget extends Component {
    state = {
        range: [0, 0],
        pickerOpen: false,
        dateRange: ""
    }
    formatter(value) {
        return `$${value}`;
    }
    handleSlider = value => {
        this.setState({ range: value })
    }
    onFinish = values => {
        values.range = JSON.stringify({ 'lower': this.state.range[0], 'upper': this.state.range[1] })
        values.project = this.props.projectId
        this.state.dateRange.length && (values.timeline = this.state.dateRange);
        setBudgetDetail(values).then((response) => {
            this.props.projectCreated()
        })
    }
    handleChange = (date) => {
        let dateRange = `${moment(date[0]['_d']).format('YYYY-MM-DD')} - ${moment(date[1]['_d']).format('YYYY-MM-DD')}`
        this.setState({ dateRange, pickerOpen: false })
    }
    handleRadio = (e) => {
        let value = e.target.value
        this.setState({ dateRange: "", pickerOpen: (value == "customDate" && !this.state.pickerOpen) && true })
    }
    render() {
        const { range, pickerOpen } = this.state
        return (
            <div className="time-budget-container">
                <h2 className="aic-heading">Time & Budget</h2>
                <Form className="mt-4" onFinish={this.onFinish}>
                    <div className="aic-form">
                        <div className="f-row">
                            <h6>What’s your timeline? *</h6>
                            <Form.Item name="timeline" rules={[{ required: true, message: 'This field is required' }]}>
                                <Radio.Group onChange={this.handleRadio} className="radio-check w-100" size="large">
                                    <Radio.Button className="d-block mt-2" value="48 Hours ( quick turnaround time fees typically apply)">48 Hours ( quick turnaround time fees typically apply)</Radio.Button>
                                    <Radio.Button className="d-block mt-4" value="1 Week">1 Week</Radio.Button>
                                    <Radio.Button className="d-block mt-4" value="2 Weeks">2 Weeks</Radio.Button>
                                    <Radio.Button className="d-block mt-4" value="1 Month">1 Month</Radio.Button>
                                    <Radio.Button className="d-block mt-4 select-timeline" value="customDate" onClick={() => this.setState({ pickerOpen: !pickerOpen })}>Set Start and end date<label className="ml-4 mb-0">{this.state.dateRange}</label><RangePicker allowClear={true} className="invisible" open={pickerOpen} onChange={this.handleChange} /></Radio.Button>
                                    <Radio.Button className="d-block mt-4" value="Not Sure. I'd like to chat with my designer">Not sure. I’d like to chat with my designer</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <div className="f-row mt-3">
                            <h6>What’s your budget? *</h6>
                            <Input className="fr-input" disabled placeholder={range.map(i => '$' + i).join('-')} value={range.map(i => '$' + i).join('-')} />
                        </div>
                        <div className="f-row mt-5">
                            <Form.Item name="range" rules={[{ required: true, message: 'This field is required' }]}>
                                <Slider range min={0} onChange={this.handleSlider} className="range-slider" tooltipVisible tipFormatter={this.formatter} max={3000} defaultValue={[0, 0]} />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="form-actions">
                        {/* <Button type="primary" size="large" onClick={() => this.props.handleProcess('yourServices')} className="d-inline mr-4 mt-4 back-button">BACK</Button> */}
                        <Button type="primary" size="large" htmlType="submit" className="d-inline mt-4 continue-button">CONTINUE</Button>
                    </div>
                </Form>
            </div>
        )
    }
}
export default withRouter(TimeBudget);