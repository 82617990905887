import React, { Component } from 'react';
import { Input, Checkbox, Row, Col, Radio, Form, message, Button } from 'antd';
import { setSocialMediaMarketing, updateSocialMediaMarketing } from '../../../Controller/API/ServiceAPI';

const respondinWay = ["politely", "letusrespond", "contact"];
export default class SocialMediaMarketing extends Component {
    state = {
        required_platform: null,
        required_platformShow: false,
        characteristics: null,
        characteristicsShow: false,
        tone: null,
        toneShow: false,
        priority_reason: null,
        priority_reasonShow: false,
        marketing_forms: null,
        marketing_formsShow: false,
        userDetails: {},
        responding_way: null,
        responding_wayShow: false,
        buttonLoading: false
    };
    formRef = React.createRef();

    componentDidMount() {
        const { serviceDetail } = this.props;
        if (serviceDetail) {
            if (serviceDetail.required_platform.includes('other')) {
                this.setState({
                    required_platform: serviceDetail.required_platform[serviceDetail.required_platform.length - 1],
                    required_platformShow: true
                });
                serviceDetail.required_platform.pop()
            }
            if (serviceDetail.characteristics.includes('other')) {
                this.setState({
                    characteristics: serviceDetail.characteristics[serviceDetail.characteristics.length - 1],
                    characteristicsShow: true
                });
                serviceDetail.characteristics.pop()
            }
            if (serviceDetail.tone.includes('other')) {
                this.setState({ tone: serviceDetail.tone[serviceDetail.tone.length - 1], toneShow: true });
                serviceDetail.tone.pop()
            }
            if (serviceDetail.priority_reason.includes('other')) {
                this.setState({
                    priority_reason: serviceDetail.priority_reason[serviceDetail.priority_reason.length - 1],
                    priority_reasonShow: true
                });
                serviceDetail.priority_reason.pop()
            }
            if (serviceDetail.marketing_forms.includes('other')) {
                this.setState({
                    marketing_forms: serviceDetail.marketing_forms[serviceDetail.marketing_forms.length - 1],
                    marketing_formsShow: true
                });
                serviceDetail.marketing_forms.pop()
            }
            if (!respondinWay.includes(serviceDetail.responding_way)) {
                this.setState({ responding_wayShow: true, responding_way: serviceDetail.responding_way })
            }
            // this.setState({})
            this.formRef.current.setFieldsValue({
                name: serviceDetail.name,
                business: serviceDetail.business,
                required_platform: serviceDetail.required_platform,
                characteristics: serviceDetail.characteristics,
                tone: serviceDetail.tone,
                targeting_gender: serviceDetail.targeting_gender[0],
                age_range: serviceDetail.age_range[0],
                client_income: serviceDetail.client_income[0],
                audience_education: serviceDetail.audience_education,
                priority_reason: serviceDetail.priority_reason,
                marketing_forms: serviceDetail.marketing_forms,
                posting_interval: serviceDetail.posting_interval,
                responding_way: serviceDetail.responding_way,
                starting_from: serviceDetail.starting_from,
                competitors: serviceDetail.competitors,
            })
        }
    }

    onFinish = values => {
        let userDetails = { ...this.state.userDetails }
        this.setState({ buttonLoading: true })
        if (this.state.required_platform) {
            values.required_platform.push(this.state.required_platform)
        }
        if (this.state.characteristics) {
            values.characteristics.push(this.state.characteristics)
        }
        if (this.state.tone) {
            values.tone.push(this.state.tone)
        }
        if (this.state.priority_reason) {
            values.priority_reason.push(this.state.priority_reason)
        }
        if (this.state.marketing_forms) {
            values.marketing_forms.push(this.state.marketing_forms)
        }
        if (this.state.responding_way) {
            values.responding_way = this.state.responding_way
        }
        values.project = this.props.projectId;
        values.client_income = [values.client_income];
        values.targeting_gender = [values.targeting_gender];
        values.age_range = [values.age_range];

        if (userDetails.id || this.props.serviceDetail) {
            let id = userDetails.id ? userDetails.id : this.props.serviceDetail.id;
            updateSocialMediaMarketing(id, values).then((response) => {
                this.props.onSubmit();
                message.success("Details Updated Successfully");
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    });
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong");
                    this.setState({ buttonLoading: false })
                }
            })
        } else {
            setSocialMediaMarketing(values).then((response) => {
                this.props.onSubmit();
                this.setState({ userDetails: response.data });
                message.success("Form Submitted");
                this.setState({ buttonLoading: false })
            }).catch((err) => {
                if (err.response) {
                    Object.keys(err.response.data).map((i) => {
                        message.error(err.response.data[i])
                    });
                    this.setState({ buttonLoading: false })
                } else {
                    message.error("something went wrong");
                    this.setState({ buttonLoading: false })
                }
            })
        }
    };
    handleOther = (event) => {
        if (event.target.checked === true) {
            this.setState({ [event.target.id]: "", [event.target.id + "Show"]: true })
        } else {
            this.setState({ [event.target.id + "Show"]: false, [event.target.id]: "" })
        }
    };
    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    };
    handleRadioChange = (event) => {
        this.setState({ responding_way: null, responding_wayShow: event.target.value === "other" ? true : false })
    }
    clearForm = () => {
        this.formRef.current.resetFields()
        this.setState({
            required_platform: null,
            required_platformShow: false,
            characteristics: null,
            characteristicsShow: false,
            tone: null,
            toneShow: false,
            priority_reason: null,
            priority_reasonShow: false,
            marketing_forms: null,
            marketing_formsShow: false,
            responding_way: null,
            responding_wayShow: false,
        })
    }
    render() {
        return (
            <React.Fragment>
                <Form onFinish={this.onFinish} className="otherInputBorder" ref={this.formRef}>
                    <div className="f-row">
                        <h6>Name your project<span>(optional)</span></h6>
                        <Form.Item name="name">
                            <Input className="fr-input" placeholder="Eg. Landing page for my food truck’s website" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What does your business do/sell?</h6>
                        <Form.Item name="business" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Which social media platforms will you require management for? Please check all that apply
                            *</h6>
                        <Form.Item name="required_platform"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="facebook">Facebook</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="linkedIn">LinkedIn</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="twitter">Twitter</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="snapchat">Snapchat</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="instagram">Instagram</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="required_platform" value="other"
                                            onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify"
                                            value={this.state.required_platform} name="required_platform"
                                            onChange={this.handleChange}
                                            disabled={!this.state.required_platformShow} />
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Please tell us what characteristics your company possesses? Please check all that
                            apply.</h6>
                        <Form.Item name="characteristics" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="hip">Hip</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="professional">Professional</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="funyoung">Fun Young</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="creative">Creative</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="adorable">Adorable</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="characteristics" value="other"
                                            onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify"
                                            value={this.state.characteristics} name="characteristics"
                                            onChange={this.handleChange} disabled={!this.state.characteristicsShow} />
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="news">News</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What tone are you expecting our team to take when posting on your behalf? Please check all
                            that apply *</h6>
                        <Form.Item name="tone" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="professional">Professional</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="inspiring">Inspiring</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="personal">Personal</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="creative">Creative</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="funny">Funny</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="tone" value="other" onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify" value={this.state.tone}
                                            name="tone" onChange={this.handleChange}
                                            disabled={!this.state.toneShow} />
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Which gender are you targeting? Please check all that apply *</h6>
                        <Form.Item name="targeting_gender"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Radio.Group>
                                <Row>
                                    <Col span={24} className="mt-3">
                                        <Radio value="male">Male</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="female">Female</Radio>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Radio value="other">Other</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Which age range are you targeting?</h6>
                        <Form.Item name="age_range" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What is the income of your target client? Please check all that apply? *</h6>
                        <Form.Item name="client_income" rules={[{ required: true, message: 'This field is required' }]}>
                            <Radio.Group style={{ width: '100%' }}>
                                <Row>
                                    <Col span={12} className="mt-3">
                                        <Radio value="0-50">$0 - 50K</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="100-150">$100K - 150K</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="50-100">$50K - 100K</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="150+">$150K+</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What is the education level of your target audience? Please check all that apply *</h6>
                        <Form.Item name="audience_education"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={24} className="mt-3">
                                        <Checkbox value="noCollege">No College</Checkbox>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Checkbox value="someCollege">Some College</Checkbox>
                                    </Col>
                                    <Col span={24} className="mt-3">
                                        <Checkbox value="graduateSchool">Graduate School</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Please tell us why potential clients/customers are more apt to choose your company & your
                            product/service over those of your competitors? Please check all that apply *</h6>
                        <Form.Item name="priority_reason" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="betterPrice">Better Price</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="moreConvenient">More Convenient</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="betterService">Better Service</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="betterReputation">Better Reputation</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="companyMission">Company Mission</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="MoreReliable">More Reliable</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="priority_reason" value="other"
                                            onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify"
                                            value={this.state.priority_reason} name="priority_reason"
                                            onChange={this.handleChange} disabled={!this.state.priority_reasonShow} />
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>What forms of marketing are you currently utilizing? Please check all that apply *</h6>
                        <Form.Item name="marketing_forms" rules={[{ required: true, message: 'This field is required' }]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row className="checkbox-row">
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="none">None</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="printtv">Print TV and Radio</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="emailmarketing">Email Marketing</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="onlinemarketing">Online Marketing</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="socialmedia">Social Media</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="referral">Referral/Word of Mouth</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="directmail">Direct Mail</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="tradeshow">Trade Shows/Fairs</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox value="telemarketing">Telemarketing</Checkbox>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Checkbox id="marketing_forms" value="other"
                                            onChange={this.handleOther}>Other</Checkbox>
                                        <Input className="w-50" placeholder="Please specify"
                                            value={this.state.marketing_forms} name="marketing_forms"
                                            onChange={this.handleChange} disabled={!this.state.marketing_formsShow} />
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How frequently are you currently posting on your social media platforms?</h6>
                        <Form.Item name="posting_interval"
                            rules={[{ required: true, message: 'This field is required' }]}>
                            <Radio.Group style={{ width: '100%' }}>
                                <Row>
                                    <Col span={12} className="mt-3">
                                        <Radio value="frequently">Frequently (1-3 per day)</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="rarely">Rarely (1-3 per week)</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="sometimes">Sometimes (5 per week)</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="nopost">Not posting</Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>How would you like us to respond in the face of spam, malicious postings or comments? *</h6>
                        <Form.Item name="responding_way" rules={[{ required: true, message: 'This field is required' }]}>
                            <Radio.Group style={{ width: '100%' }} onChange={this.handleRadioChange}>
                                <Row>
                                    <Col span={12} className="mt-3">
                                        <Radio value="politely">Politely respond for us</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="letusrespond">Let us respond</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio value="contact">Contact us before responding</Radio>
                                    </Col>
                                    <Col span={12} className="mt-3">
                                        <Radio id="responding_way"
                                            value={this.state.responding_way ? this.state.responding_way : "other"}
                                            onChange={this.handleOther}>Other</Radio>
                                        <Input className="w-50" placeholder="Please specify"
                                            value={this.state.responding_way} name="responding_way"
                                            onChange={(e) => {
                                                this.handleChange(e);
                                                this.formRef.current.setFieldsValue({ 'responding_way': e.target.value })
                                            }} disabled={!this.state.responding_wayShow} />
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>When are you looking to get started? *</h6>
                        <Form.Item name="starting_from" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. Clothes, Shoes" />
                        </Form.Item>
                    </div>
                    <div className="f-row mt-4">
                        <h6>Who would you say are your biggest competitors? Please list at least 3 <span>(separate competitors with comma)</span>
                        </h6>
                        <Form.Item name="competitors" rules={[{ required: true, message: 'This field is required' }]}>
                            <Input className="fr-input" placeholder="Eg. 18-26" />
                        </Form.Item>
                    </div>
                    <div className="row em-form-inner-btn em-btn-all position-sticky edit-footer">
                        {this.props.clearForm && <Button className="bg-transparent border-0 shadow-none w-auto" onClick={this.clearForm}>Clear</Button>}
                        {this.props.serviceType == "inner" &&
                            <Button className="mt-4" onClick={this.props.onClose}>Cancel</Button>}
                        <Button loading={this.state.buttonLoading} type="primary" htmlType="submit" size="large"
                            className="d-inline mt-4 continue-button">SUBMIT</Button>
                    </div>
                </Form>
            </React.Fragment>
        )
    }
} 